/** @jsxImportSource @emotion/react */
import SideBar from "../Sidebar/Sidebar";
import { css } from '@emotion/react';
import DashboardHeader from "../DashbaordHeader/DashboardHeader";


const MainWrapper = css`
 height: auto;
  width: 100%;
`
const RightContentWrapper = css`
 margin-left: 17%;
 height: 100vh;
 @media (max-width: 768px) {
   margin-left:0px;
   height:auto;
  }
 `

const ChildrenContent = css`
padding: 25px;
margin-left:15px;
 @media (max-width: 768px) {
   padding: 0px 15px;
margin-left:0px;
width:auto;
  }
`

const DashboardLoyout = (props: any) => {
  const { children } = props;
  return (
    <div css={MainWrapper}>
      <div>
        <SideBar />
      </div>
      <div css={RightContentWrapper}>
      <DashboardHeader />
        <div css={ChildrenContent}>{children}</div>
      </div>
    </div>
  );
};

export default DashboardLoyout;
