/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { css } from '@emotion/react';
import { colors, typography } from '../../scss/colors';
import { icon } from '../../assets/Images';
import { TextField } from '@mui/material';
import {  forwardRef, useState } from 'react';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import EastIcon from '@mui/icons-material/East';
import { regBackButton, regNextButton } from '../../pages/Registration/Registration.style';
import CustomButton from '../Button/Button';
import { parse, format } from 'date-fns';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
export const inputLabelCss = css`
  text-align: left;
  margin-bottom: 5px;
  color: ${colors.charcoal};
  ${typography('bold', '14px')};
`;

const labelMandatory = css`
  color: ${colors.crimson};
`;

const imgCss = css`
  padding-left: 4px;
  margin-bottom: -1px;
`;

const StyledDropdownContainer = css`
  position: relative;
  width: 240px;
`;

const DropdownToggle = css`
  display: flex;
  align-items: center;
  background-color: ${colors.white};
  height: 40px;
  width: 100%;
  cursor: pointer;
  border: 1px solid ${colors.inputBorder};
  border-radius: 4px;
  justify-content:flex-start;
    ${typography('medium', '16px')};
`;

const Placeholder = css`
  width:85%;
  text-align:left;
  padding-left:10px;
  color:${colors.placeholder};
`
const iconsSection = css`
 width:15%;
`

const DateRangeSection = css`
position: absolute;
top: 65px;
width: 290px;
background-color: ${colors.white};
color: rgba(0, 0, 0, 0.87);
border-radius: 4px;
padding:10px;
box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); 
`
 const datePicker = css`
display:flex;
align-items:center;
gap:5px;
`
 const CustomButtonContainer = css`
 margin-top:20px;
    display: flex;
    justify-content: center;
    gap: 20px;`



      
const CustomDateSelect: React.FC<any> = ({
    inputLabel,
    mandatory = false,
    setFilterByReg,
    filterByReg
}) => {
    const [showDatePicker, setShowDatePicker] = useState(false);
    const [startDate, setStartDate] = useState(filterByReg.startDate ?? null);
    const [endDate, setEndDate] = useState(filterByReg.endDate ?? null);   
    const handleToggleDatePicker = () => {
        setShowDatePicker(!showDatePicker);
    };

    const handleApply = () => {
        setFilterByReg({
       ...filterByReg,
         startDate:startDate,
         endDate:endDate
        })
        setShowDatePicker(false);
    };

    const handleCancel = () => {
        setFilterByReg({
            ...filterByReg,
              startDate:"",
              endDate:""
             })
        setStartDate(null)
        setEndDate(null)
        setShowDatePicker(false);
    };
    const parseDate = (dateString:any) => {
        return dateString ? parse(dateString, 'dd.MM.yyyy', new Date()) : null;
    };
    const handleDateChange = (setter:any) => (date:any) => {
        setter(date ? format(date, 'dd.MM.yyyy') : '');
    };
    const CustomDateInput = forwardRef(({ value, onClick, placeholder }:any, ref) => (
        <TextField
            value={value}
            onClick={onClick}
            placeholder={value ? '' :placeholder}
            inputRef={ref}
          
        />
    ));
    return (
        <div css={StyledDropdownContainer}>
            {inputLabel && (
                <div css={inputLabelCss}>
                    {inputLabel}
                    {mandatory && <span css={labelMandatory}>*</span>}
                    <img src={icon.validation_Error} alt="validation_error" css={imgCss} />
                </div>
            )}
            <div css={DropdownToggle} onClick={handleToggleDatePicker}>
            <div css={Placeholder}>
            {(startDate || endDate) ? `${startDate} -> ${endDate}`:"Please Select"}
                </div>
                <div css={iconsSection}>
                    {showDatePicker ?
                        <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
                </div>

            </div>
            {showDatePicker && (
                <div css={DateRangeSection}>
                    <div css={datePicker}>
                    <DatePicker
                    selected={parseDate(startDate)}
                    onChange={handleDateChange(setStartDate)}
                    dateFormat="dd.MM.yyyy"
                     placeholderText='DD.MM.YYYY'
                    customInput={<CustomDateInput placeholder="DD.MM.YYYY" />}
                />
                <EastIcon />
                <DatePicker
                    selected={parseDate(endDate)}
                    onChange={handleDateChange(setEndDate)}
                    dateFormat="dd.MM.yyyy"
                    placeholderText='DD.MM.YYYY'
                    customInput={<CustomDateInput placeholder="DD.MM.YYYY" />}
                />
                    </div>
                    <div css={CustomButtonContainer}>
                <CustomButton
                    label="Cancel"
                    onClick={handleCancel}
                    variant="outlined"
                    css={regBackButton}
                    
                />
                <CustomButton
                    label="Apply"
                    onClick={handleApply}
                     css={regNextButton}
                />
            </div>
                </div>
            )}
        </div>
    );
};

export default CustomDateSelect;
