import { css } from '@emotion/react';
import { colors, typography, typographyInter } from '../../../scss/colors';

export const VeirfyMainHeadding = css`
text-align:left;
color: ${colors.lightBlack};
${typographyInter('700', '36px')};
@media (max-width: 768px) {
display:none;
}
`;

export const InitiateKycDetailsSection = css`
margin-top:20px
`;

export const selectPayoutButton = css`
        background-color:${colors.poppypower};
        color:${colors.white};
        width:184px;
        height:32px;
        border-radius:6px;
        ${typography("700", "14px")};
       padding:0px 0px;
        &:hover {
        background-color:${colors.buttonOrange}; 
       color:${colors.white};
      
    }
`
export const selectOutlinePayoutButton = css`
        background-color:${colors.white};
        color:${colors.poppypower};
        width:184px;
        height:32px;
        border-radius:6px;
        ${typography("700", "14px")};
        border:solid 1px ${colors.poppypower};
       padding:0px 0px;
        &:hover {
        background-color:${colors.white}; 
       color:${colors.poppypower};
        border:solid 1px ${colors.poppypower};
      
    }
`
export const payoutMainSection = css`
display:flex;
justify-content: space-between;
align-items: center;
`

export const EsignButtonSection = css`
display:flex;
gap:10px;
`

