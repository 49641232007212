/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import CustomButton from '../../../components/Button/Button';
import { colors, typography } from '../../../scss/colors';
import { css } from '@emotion/react';
import { utilsConstants } from '../../../utils/utilsConstants';
import { useLocation } from 'react-router-dom';
import { regBackButton, regNextButton, regNextButtonDisabled, registrationButtonContainer } from '../../Registration/Registration.style';
import { StringConstants } from '../../../constants/Strings';
import InputFile from '../../../components/InputFile/InputFile';
import CustomSelect from '../../../components/Select/Select';
import { getLocalStorage, setLocalStorage } from '../../../utils/browserHandlers/browserStorageHandler';
import { getDocumentApiUrl, getUploadFilePostApiUrl, getUploadPayoutDocsGETApiUrl, getUploadPayoutDocsPostApiUrl, uploadFileApiUrl } from '../../../apiManger/apiConstants';
import { get, post } from '../../../apiManger/apiMethods';
import { useToastHook } from '../../../components/Toast/useToastHook';
import { ToastContainer } from 'react-toastify';
import PreviewComponent from '../../KycDocumentes/Preview';
import Loder from '../../../components/Loader/Loder';

const registrationOtpContainer = css`
margin-top:15px;
display: flex;
align-items: flex-start;
gap:10px;

@media (max-width:768px){
    flex-wrap:wrap;
 }
            `


const regPanInput = css`
 width:240px ;
 height: 40px;
 margin-bottom:35px;
 
   @media (max-width:768px){
   width:345px;
   margin-bottom:10px;
}
            `
const regPanInputFile = css`
 width:240px ;
 height: 40px;
margin-bottom:20px;

@media (max-width:768px){
    width:345px;
     margin-bottom:10px;
 }
  `

const previewSection = css`
  color: ${colors.buttonOrange};
  ${typography('700', '16px')}
  text-decoration: underline;
  display: flex;
  margin-bottom: 20px;
  cursor: pointer;
`;

const statusInput = css`
  width: 240px;
  height: 40px;
  margin-bottom: 20px;
`;

const actionButtons = css`
  display: flex;
  gap: 15px;
`;

const UploadCpv = (props: any) => {
    const {
        showLoadingIndicator,
        hideLoadingIndicator,
        loadingComponent,
        visible,
    } = Loder();
    const {showToast} = useToastHook()
    const [errors, setErrors] = useState<any>({});
    const { setValue ,handleUploadNext} = props;
    const { STEPPER, STEPPER_INDIVIDUAL, STEPPER_INDIVIDUAL_DIRECTOR, RegisterTypeOptions, EntityTypeOptions, PartnerShipTypeOptions } = utilsConstants;
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
    const { BACK_LABEL, NEXT_LABEL } = StringConstants;
    const [documents, setDocuments] = useState<any>({
        fcu: "",
        cpv: "",
        fcuStatus: "",
        cpvStatus: ""
    });
    const { state } = useLocation();
    const [stepper, setStepper] = useState(STEPPER);

    const handleClear = (fieldName: any) => {
        setDocuments({
            ...documents,
            [fieldName]: ""
        });
        setErrors({
            ...errors,
            [fieldName]: null
          });
    };

    useEffect(() => {
        const allFieldsFilled = Object.values(documents).every(value => value && value !== "");
        setIsNextButtonDisabled(!allFieldsFilled);
    }, [documents]);

    useEffect(() => {
        if (state) {
            const { registerType, enityType, PartnershipType } = state;
            if (registerType === RegisterTypeOptions[0].label && enityType === EntityTypeOptions[0].label) {
                setStepper(STEPPER_INDIVIDUAL)
            } else if (registerType === RegisterTypeOptions[0].label && (PartnershipType === PartnerShipTypeOptions[1].label || PartnershipType === PartnerShipTypeOptions[5].label)) {
                setStepper(STEPPER_INDIVIDUAL_DIRECTOR)
            }
        }
    }, [state]);

   
    const registrationId = getLocalStorage('Application_registrationId');

    useEffect(() => {
        if(getLocalStorage("FCU/CPV")){
            getAPICall();
        }
       
    }, [])
    const getAPICall = async () => {
        try {
            const response = await get({
                url: getUploadPayoutDocsGETApiUrl(registrationId)
            });
            if (response && response.result) {
            

                setDocuments({
                    id: response.result.id,
                    fcu: {
                        id: response.result.fcuDocumentId,
                        name: response.result.fcuFileName,
                        url: response.result.fcuDocumentUrl,
                    },
                    cpv: {
                        id: response.result.cpvDocumentId,
                        name: response.result.cpvFileName               ,
                        url: response.result.cpvDocumentUrl,
                    },
                    fcuStatus: response.result.fcuStatus,
                    cpvStatus: response.result.cpvStatus
                });
            }
            else if(response?.error?.message){
                showToast(response?.error?.message,"error")
              }
            else {
                showToast("something went wrong","error")
            }
        } catch (error) {
            showToast("something went wrong","error")
            console.error("Error fetching documents data:", error);
        }
    };
    const handleNext = async () => {
        showLoadingIndicator()
        try {
            let payload: any;
            if (documents.id !== '') {
                payload = {
                    registrationId,
                    id: documents.id,
                    fcuDocumentId: documents.fcu.id,
                    fcuFileTypeId:documents.fcu?.fileTypeId,
                    fcuFileSize:documents.fcu?.file_size,
                    fcuFileType:documents.fcu?.file_type,
                    fcuFileName:documents.fcu.name,
                    fcuStatus: documents.fcuStatus,


                    cpvDocumentId: documents.cpv.id,
                    cpvFileTypeId:documents.cpv?.fileTypeId,
                    cpvFileType:documents.cpv?.file_type,
                    cpvFileSize:documents.cpv?.file_size,
                    cpvFileName:documents.cpv.name,
                    cpvStatus: documents.cpvStatus,
                    status: 1,
                };
            }
            else {
                payload = {
                    registrationId,
                    fcuDocumentId: documents.fcu.id,
                    fcuFileTypeId:documents.fuc?.fileTypeId,
                    fcuFileSize:documents.fuc?.file_size,
                    fcuFileType:documents.fuc?.file_type,
                    fcuFileName:documents.fcu.name,
                    fcuStatus: documents.fcuStatus,


                    cpvDocumentId: documents.cpv.id,
                    cpvFileTypeId:documents.cpv?.fileTypeId,
                    cpvFileType:documents.cpv?.file_type,
                    cpvFileSize:documents.cpv?.file_size,
                    cpvFileName:documents.cpv.name,
                    cpvStatus: documents.cpvStatus,
                    status: 1,
                };
            }

            const response = await post({
                url: getUploadPayoutDocsPostApiUrl(registrationId),
                obj: payload
            });

            if (response.result?.screenName) {
                setLocalStorage("FCU/CPV","FCU/CPV")
                hideLoadingIndicator()
                handleUploadNext()
            }else if(response?.error?.message){
                hideLoadingIndicator()
                showToast(response?.error?.message,"error")
              }
            else {
                hideLoadingIndicator()
                showToast("something went wrong","error")
            }
        } catch (error) {
            hideLoadingIndicator()
            showToast("something went wrong","error")
            console.error('Error occurred while submitting KYC details:', error);
        }
       
    };

    const statusOptions = [
        { value: 'Positive', label: 'Positive' },
        { value: 'Negative', label: 'Negative' },
        { value: 'Refer', label: 'Refer' },
    ];

    const Cpv = [
        { inputLabel: "FCU Document", name: "fcu", type: "file" ,id:"20e1a4f5-f8a7-4165-bf1e-856ddc7cebef"},
        { inputLabel: "FCU Status", name: "fcuStatus", type: "select" },
        { inputLabel: "CPV Document", name: "cpv", type: "file" ,id:"98a3b956-c005-4b5b-a2e2-716051da27f6"},
        { inputLabel: "CPV Status", name: "cpvStatus", type: "select" }
    ];
    const sanction = [
        { inputLabel: "FCU Document", name: "fcu", type: "file",id:"20e1a4f5-f8a7-4165-bf1e-856ddc7cebef" },
        { inputLabel: "FCU Status", name: "fcuStatus", type: "select" },
        { inputLabel: "CPV Document", name: "cpv", type: "file" ,id:"98a3b956-c005-4b5b-a2e2-716051da27f6"},
        { inputLabel: "CPV Status", name: "cpvStatus", type: "select" }
    ];
    const data = props?.cpvDetails === "sanction" ? sanction : Cpv;

    const handleChange = async (fieldName: any, e: any,type:any) => {
        
        if(type == "file"){
            showLoadingIndicator()
        const file = e.target.files?.[0];
        const fileSize = file?.size;
        const fileType = file?.type;
        const fileTypeId = e.target.id;
          if (fileSize > 1 * 1024 * 1024) {
            showToast("File size too large. Maximum size is 1 MB.","error");
            return;
          }
        
          if (fileType == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            showToast("Invalid file type .docx files are not allowed.","error");
            return;
          }
        if (file) {
            const response = await fileUpload(file);
            if (response?.result?.[0]?.file_id) {
                hideLoadingIndicator()
                setDocuments((prev: any) => ({
                    ...prev,
                    [fieldName]: {
                        id: response?.result?.[0]?.file_id,
                        name: response?.result?.[0]?.file_name,
                        ...response?.result?.[0],
                        fileTypeId:fileTypeId
                    }
                }));
            }else if (response?.error) {
                hideLoadingIndicator()
                const error = fieldName === '' ? null : response?.error?.message;
                setErrors({
                  ...errors,
                  [fieldName]: error
                });
              }
            
        } 
        }else{
            hideLoadingIndicator()
            setDocuments((prev: any) => ({
                ...prev,
                [fieldName]: e.target.value
            }));
        }
    };

    const fileUpload = async (file: any) => {
        const data = new FormData();
        data.append("file", file);
        const response: any = await uploadFileApiUrl(data)
        return response;
    };
    return (
        <>
          {visible ? 
        <>{loadingComponent()}</> :
        <div>
            <div css={registrationOtpContainer}>
                {data.map((item: any, index: any) => (
                    <div key={index}>
                        {item.type === "file" ? (
                            <>
                                <InputFile
                                    inputLabel={item.inputLabel}
                                    onChange={(e) => handleChange(item.name, e,"file")}
                                    css={documents[item.name].id ? regPanInputFile : regPanInput}
                                    mandatory={true}
                                    onClear={() => handleClear(item.name)}
                                    registration={false}
                                    docId={item.id}
                                    value={documents[item.name].id ? documents[item.name].name : ""}
                                    documentId={documents[item.name].id}
                                    fileName={documents[item.name]?.name } 
                                />
                    {errors[item.name] && <div css={{ color: colors.crimson, fontSize: '12px', textAlign: "left", marginTop: "-10px" }}>{errors[item.name]}</div>}
                    {(documents[item.name]?.id  )&& <PreviewComponent file={documents[item.name]?.id } /> }
                 
                 </>
                        ) : (
                            <CustomSelect
                                inputLabel={item.inputLabel}
                                value={documents[item.name]}
                                onChange={(e) => handleChange(item.name, e,"select")}
                                options={statusOptions}
                                placeholder="Please Select"
                                css={statusInput}
                                mandatory={true}
                            />
                        )}
                    </div>
                ))}
            </div>
            {props?.cpvDetails === "cpvDetails" ?
                <div css={registrationButtonContainer}>
                    <div css={actionButtons}>
                        <CustomButton
                            label={BACK_LABEL}
                            onClick={() => setValue((pre: any) => pre - 1)}
                            variant="outlined"
                            css={regBackButton}
                        />
                        {/* <CustomButton
                            label="Reject"
                            onClick={() => setValue((pre: any) => pre - 1)}
                            variant="outlined"
                            css={regBackButton}
                        /> */}
                        <CustomButton
                            label="Next"
                            onClick={() => setValue(4)}
                            css={!isNextButtonDisabled ? regNextButton : regNextButtonDisabled}
                        />
                    </div>
                </div>
                :
                <div css={registrationButtonContainer}>
                    <CustomButton
                        label={BACK_LABEL}
                        onClick={() => setValue((pre: any) => pre - 1)}
                        variant="outlined"
                        css={regBackButton}
                    />
                    <CustomButton
                        label={NEXT_LABEL}
                        onClick={handleNext}
                        css={!isNextButtonDisabled ? regNextButton : regNextButtonDisabled}
                    />
                </div>
            }
            <ToastContainer />
        </div>
        }
        </>
    );
}

export default UploadCpv;
