/** @jsxImportSource @emotion/react */
import {  useState } from "react";
import TabsComponent from "../../components/TabsComponent/TabsComponent";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { InitiateKycDetailsSection } from "../ApplicationReview/InitiateKyc/InitiateKyc.style";
import MultiFileUpload from "./MultiFileUpload";

const EsignDetails = (props: any) => {
  const { showDetails ,detailsData,setShowDetails} = props;
  const [value, setValue] = useState(0);

  const breadcrumbItems = [
    { label: 'E-Sign', href: '/e-sign' },
    { label: showDetails.ApplicationNo }
  ]

  const tabs = [
    {
      label: 'File Upload',
      content: (
        <div>
          <MultiFileUpload setShowDetails={setShowDetails}/>
        </div>
      ),
    },
    
  ];

  return (
    <div>
      <Breadcrumb items={breadcrumbItems} />
      <div css={InitiateKycDetailsSection}>
        <TabsComponent tabs={tabs} value={value} setValue={setValue}/>
      </div>
    </div>
  );
};

export default EsignDetails;
