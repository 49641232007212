/** @jsxImportSource @emotion/react */
import DashboardLoyout from "../../components/DashbaordLayout/DashbaordLayout";
import { useEffect, useState } from "react";
import { StringConstants } from "../../constants/Strings";
import {  VeirfyMainHeadding, payoutMainSection } from "../ApplicationReview/InitiateKyc/InitiateKyc.style";
import CustomTable from "../../components/Table/Table";
import LoanDetails from "./LoanDetails";
import { getApplicationListPOSTApiUrl } from "../../apiManger/apiConstants";
import { post } from "../../apiManger/apiMethods";
const columns = [
  { id: 'ApplicationNo', label: 'Application No', minWidth: 100 },
  { id: 'Name', label: 'Name', minWidth: 100 },
  { id: 'Location', label: 'Location', minWidth: 100 },
  { id: 'stage', label: 'Stage', minWidth: 100 },
  { id: 'product', label: 'Product', minWidth: 100 },
  { id: 'date', label: 'Disbursed Date', minWidth: 100 },
  { id: 'Details', label: 'Details', minWidth: 100 },
];

const data = [
  { ApplicationNo: '12345', Location: "Bangalore", stage: 'Under Review', product: 'Ev', Name: 'Terry Ekstrom Bothman', date: '13/02/2023' },
  { ApplicationNo: '12346', Location: "Bangalore", stage: 'Sanctioned', product: 'RTS', Name: 'Terry Ekstrom Bothman', date: '11/01/2023' },
  { ApplicationNo: '12347', Location: "Mangalore", stage: 'Sanctioned', product: 'Rooftop Solar', Name: 'Terry Ekstrom Bothman', date: '03/12/2023' },
  { ApplicationNo: '12348', Location: "Delhi", stage: 'Under Review', product: 'EV', Name: 'Terry Ekstrom Bothman', date: '22/09/2023' },
  { ApplicationNo: '12349', Location: "Chennai", stage: 'Under Review', product: 'RTS', Name: 'Terry Ekstrom Bothman', date: '10/02/2023' },
  { ApplicationNo: '12350', Location: "Mumbai", stage: 'Under Review', product: 'Rooftop Solar', Name: 'Terry Ekstrom Bothman', date: '13/04/2023' },
];

const LoanApplication = () => {
  const { LOAN_APPLICATION } = StringConstants
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterByStatus, setFilterByStatus] = useState("Pending")
  const [showDetails, setShowDetails] = useState(false)
  const [filters, setFilters] = useState(true)
  const [search, setSearch] = useState("")
  const [filterByReg, setFilterByReg] = useState({
    startDate: "",
    endDate: "",
    location: [],
    range: [],
    product: []
  })
  const [data, setData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [detailsData, setDetailsData] = useState();
  const formatDate = (date: any) => {
    const d = new Date(date);
    let month = '' + (d.getMonth() + 1);
    let day = '' + d.getDate();
    const year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  };


  useEffect(() => {
    getAPICall();
  }, [page, rowsPerPage, filterByStatus, search, filterByReg]);

  const getAPICall = async () => {
    const reqObj = {
      startDate: filterByReg.startDate ? formatDate(filterByReg.startDate) : "",
      endDate: filterByReg.endDate ? formatDate(filterByReg.endDate) : "",
      pageNo: page,
      pageSize: rowsPerPage,
      search: search,
      partnerTypeValue: filterByReg.location,
      entityTypeValue: filterByReg.range,
      partnershipTypeValue: filterByReg.product
    };
    const response = await post({
      url: getApplicationListPOSTApiUrl(),
      obj: reqObj
    });

    if (response?.result && response?.result?.data.length) {
      const array = response.result.data.map((item: any) => ({
       ApplicationNo: item.applicationNumber|| '',
        PartnerType: item.partnerSelection,
        EntityType: item.entityType,
        PartnershipType: item.partnershipType,
        Name: item.name || '',
        MobileNo: item.mobile,
        registrationId: item.id
      }));

      setData(array);
      setTotalRecords(response?.result?.totalRecords);
    } else {
      setData([]);
      setTotalRecords(0);
    }
  };
  const handleviewDetails = (rowData: any) => {
    setShowDetails(rowData)
  }
  const isMobile = window.innerWidth < 768;
  return (
    <div>
     
       
        {!showDetails ?
         <DashboardLoyout>
           <div css={payoutMainSection}>
          <div css={VeirfyMainHeadding}>{LOAN_APPLICATION}</div>
        </div>
          <CustomTable
            columns={columns}
            data={data}
            handleviewDetails={handleviewDetails}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setFilterByStatus={setFilterByStatus}
            filterByStatus={filterByStatus}
            filters={filters}
            filterByReg={filterByReg}
            setFilterByReg={setFilterByReg}
            setSearch={setSearch}
            search={search}
            setRowsPerPage={setRowsPerPage}
            totalRecords={totalRecords}

          />
          </DashboardLoyout>
           : (
            <>
              {!isMobile && (
                <DashboardLoyout>
               <LoanDetails showDetails={showDetails} setShowDetails={setShowDetails}/>

                </DashboardLoyout>
              )}
              {isMobile && (
                <LoanDetails showDetails={showDetails} setShowDetails={setShowDetails}
               />
              )}
            </>
          )}
      
   
    </div>
  );
};

export default LoanApplication;
