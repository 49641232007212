/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import DashboardLoyout from "../../components/DashbaordLayout/DashbaordLayout";
import AreaChart from "./AreaChart";
import { colors, typographyInter } from "../../scss/colors";
import { useState, useEffect, useCallback } from "react";
import CustomCard from "../../components/Card/Card";
import { utilsConstants } from "../../utils/utilsConstants";
import CustomBarChart from "./BarChart";
import { StringConstants } from "../../constants/Strings";
import { get } from "../../apiManger/apiMethods";
import { getDashboardGETApiUrl, getApplicationVsDisbursedGETApiUrl, getApplicationCountVsDisbursedCountGETApiUrl, getProductVsApplicationGETApiUrl, getProductVsDisbursedGETApiUrl } from "../../apiManger/apiConstants";
import Loder from "../../components/Loader/Loder";

export const marginBottom = css`
  margin-bottom: 20px;
`;
const ChartsContainer = css`
display:flex;
width:100%;
justify-content:space-between;
align-items:center;
gap:20px;
margin-top:20px;
@media (max-width: 768px) {
flex-direction:column;
}
`

const chartsLeftSection = css`
width:50%;
border:1px solid ${colors.stroke};
border-radius:5px;
padding:20px 10px;
@media (max-width: 768px) {
width:100%;
padding:0px;
border-radius:10px;
}
`
export const AreaSection = css`
width:65%;
border:1px solid ${colors.stroke};
border-radius:5px;
padding:10px;
height:328px;
@media (max-width: 768px) {
width:100%;
padding:0px;
}
`
export const ProgressSection = css`
width:35%;
border:1px solid ${colors.stroke};
border-radius:5px;
padding:10px;
height:328px
`
export const headerSectionArea = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 20px;
`;
const FilterSectionArea = css`
display:flex;
align-items:center;
justify-content:space-between;
gap:10px;
@media (max-width: 768px) {
    position: fixed;
    bottom: 24px; 
    left: 0;
    right: 0;
    background-color: ${colors.darkBlack};
    z-index: 1;
    border-radius:5px;
    gap:35px;
    height:30px;
  }
`
const headdingDashbaord = css`
color: ${colors.lightBlack};
${typographyInter('700', '24px')};
text-align:left;
  @media (max-width: 768px) {
    display:none;
  }
`
export const headding = css`
  color: ${colors.lightBlack};
${typographyInter('700', '16px')};
text-align:left;
@media (max-width: 768px) {
display:none;
}
`

export const Mobileheadding = css`
display:none;
@media (max-width: 768px) {
display:flex;
color: ${colors.lightBlack};
${typographyInter('700', '16px')};
text-align:left;
width:100%;
margin-top:10px;
}
`
const selectAreaOption = css`
 color: ${colors.placeholder};
${typographyInter('700', '12px')};
height:30px;
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;
padding:0px 10px;
@media (max-width: 768px) {
color:${colors.placeholder};
width:20%;
padding:0px;
}
`
const selectedAreaFilter = css`
 color: ${colors.lightBlack};
border: 1px solid ${colors.stroke};
${typographyInter('700', '12px')};
height:30px;
border-radius:5px;
display:flex;
align-items:center;
justify-content:center;
cursor:pointer;
padding:0px 10px;
@media (max-width: 768px) {
color:${colors.filterLabel};
width:20%;
padding:0px;
}
`



const CardSection = css`
  display: flex;
  justify-content: space-between;
`;
const CardTotalCount = css`
color: ${colors.lightBlack};
${typographyInter('700', '20px')};
@media (max-width: 768px) {
color: ${colors.crimson};
${typographyInter('700', '16px')};
}
`


const CardContainer = css`
display:flex;
justify-content:space-between;
width:100%;
gap: 10px;
@media  (max-width: 768px) {
flex-wrap: wrap;
}


`


const Dashboard = () => {
  const { DASHBOARD_AREA_FILTER } = utilsConstants;
  const [AreaFilter, setAreaFilter] = useState(DASHBOARD_AREA_FILTER[0]);
  const { DASHBOARD, AMT_DIS, COUNT_DIS, PRODUCT_APP, PRODUCT_DIS } = StringConstants;
  const [productVsApplications, setProductVsApplications] = useState([]);
  const [productVsDisbursed, setProductVsDisbursed] = useState([]);
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
  } = Loder();

  const [areaChartData, setAreaChartData] = useState({
    series1: Array(12).fill(0),
    series2: Array(12).fill(0),
    categories: [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ],
  });

  const [areaChart2Data, setAreaChart2Data] = useState({
    series1: Array(12).fill(0),
    series2: Array(12).fill(0),
    categories: [
      "Jan", "Feb", "Mar", "Apr", "May", "Jun",
      "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    ],
  });
  const [dashboardData, setDashboardData] = useState({
    totalApplications: "",
    applicationAmount: "",
    disbursedAmount: "",
    sanctionedUndisbursedAmount: "",
    payoutAmount: ""
  });


  useEffect(() => {
    fetchDashboardData();
    fetchAreaChartData();
    fetchAreaChartCountData();
    fetchProductVsApplicationsData();
    fetchProductVsDisbursedData();
  }, [AreaFilter]);

  const fetchDashboardData = async () => {
    showLoadingIndicator();
    try {
      const response = await get({
        url: getDashboardGETApiUrl()
      });
      if (response.result) {
        hideLoadingIndicator();
        setDashboardData(response.result);
      }
      else{
        hideLoadingIndicator();
      }
    } catch (error) {
      hideLoadingIndicator();
      console.error("Error fetching dashboard data:", error);
    }
  };
  const fetchAreaChartData = async () => {
    showLoadingIndicator();
    try {
      const response = await get({
        url: getApplicationVsDisbursedGETApiUrl(),
      });

      if (response.result) {
        hideLoadingIndicator();
        const allMonths = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        const series1 = Array(12).fill(0);
        const series2 = Array(12).fill(0);

        response.result.forEach((item: any) => {
          const monthIndex = allMonths.indexOf(item.month);
          if (monthIndex !== -1) {
            series1[monthIndex] = parseInt(
              item.applicationAmount.replace(/[^\d]/g, "")
            );
            series2[monthIndex] = parseInt(
              item.disbursedAmount.replace(/[^\d]/g, "")
            );
          }
        });

        setAreaChartData({ series1, series2, categories: allMonths });
      }
      else{
        hideLoadingIndicator();
      }
    } catch (error) {
      hideLoadingIndicator();
      console.error("Error fetching area chart data:", error);
    }
  };

  const fetchAreaChartCountData = async () => {
    showLoadingIndicator();
    try {
      const response = await get({
        url: getApplicationCountVsDisbursedCountGETApiUrl(),
      });

      if (response.result) {
        hideLoadingIndicator();
        const allMonths = [
          "Jan", "Feb", "Mar", "Apr", "May", "Jun",
          "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
        ];

        const series1 = Array(12).fill(0);
        const series2 = Array(12).fill(0);

        response.result.forEach((item: any) => {
          const monthIndex = allMonths.indexOf(item.month);
          if (monthIndex !== -1) {
            series1[monthIndex] = parseInt(
              item.totalApplications.replace(/[^\d]/g, "")
            );
            series2[monthIndex] = parseInt(
              item.disbursedCount.replace(/[^\d]/g, "")
            );
          }
        });

        setAreaChart2Data({ series1, series2, categories: allMonths });
      }
      else{
        hideLoadingIndicator();
      }
    } catch (error) {
      hideLoadingIndicator();
      console.error("Error fetching area chart data:", error);
    }
  }

  const fetchProductVsApplicationsData = async () => {
    showLoadingIndicator();
    try {
      const response = await get({
        url: getProductVsApplicationGETApiUrl()
      });
      if (response.result) {
        hideLoadingIndicator();
        const formattedData = response.result.map((item: any) => ({
          x: item.product,
          y: parseInt(item.applications.replace(/,/g, ''), 10)
        }));
        setProductVsApplications(formattedData);
      }
      else{
        hideLoadingIndicator();
      }
    } catch (error) {
      hideLoadingIndicator();
      console.error("Error fetching product vs applications data:", error);
    }
  };

  const fetchProductVsDisbursedData = async () => {
    showLoadingIndicator();
    try {
      const response = await get({
        url: getProductVsDisbursedGETApiUrl()
      });
      if (response.result) {
        hideLoadingIndicator();
        const formattedData = response.result.map((item: any) => ({
          x: item.product,
          y: parseInt(item.disbursedAmount.replace(/₹|,/g, ''), 10)
        }));
        setProductVsDisbursed(formattedData);
      }
      else{
        hideLoadingIndicator()
      }
    } catch (error) {
      hideLoadingIndicator()
      console.error("Error fetching product vs applications data:", error);
    }
  };
  const handleChangeFilter = (item: any) => {
    setAreaFilter(item)
  }

  const data: any = [
    { x: 'RTS', y: 1300 },
    { x: 'Ev Products', y: 700 },
    { x: 'Rooftop Solar', y: 1200 },
  ];
  const data2: any = [
    { x: 'RTS', y: 20500 },
    { x: 'Ev Products', y: 60000 },
    { x: 'Rooftop Solar', y: 30500 },
  ];

  return (
    <div>
      <DashboardLoyout>
      {visible ? 
        <>{loadingComponent()}</> :
        <> 
        <div css={headerSectionArea}>
          <div css={headdingDashbaord}>{DASHBOARD}</div>
          <div css={FilterSectionArea}>
            {DASHBOARD_AREA_FILTER.map((status) => (
              <div
                key={status}
                onClick={() => handleChangeFilter(status)}
                css={AreaFilter === status ? selectedAreaFilter : selectAreaOption}
              >
                {status}
              </div>
            ))}
          </div>
        </div>
        <div css={CardContainer}>
          {[{
            title: "TOTAL APPLICATIONS",
            totalAmount: dashboardData.totalApplications,
            percent: "+ 13%"
          },
          {
            title: "APPLICATION AMOUNT",
            totalAmount: dashboardData.applicationAmount,
            percent: "+ 11%"
          },
          {
            title: "DISBURSED AMOUNT",
            totalAmount: dashboardData.disbursedAmount,
            percent: "74%"
          },
          {
            title: "SANCTIONED UNDISBURSED",
            totalAmount: dashboardData.sanctionedUndisbursedAmount,
            percent: "74%"
          },
          {
            title: "PAYOUT AMOUNT",
            totalAmount: dashboardData.payoutAmount,
            percent: "26%"
          }].map((card, index) => (
            <CustomCard title={card.title} key={index}>
              <div css={CardSection}>
                <div css={CardTotalCount}>{card.totalAmount}</div>
              </div>
            </CustomCard>
          ))}
        </div>
        <div css={ChartsContainer}>
        <div css={Mobileheadding}>{AMT_DIS}.</div>
          <div css={chartsLeftSection}>
            <div css={headding}>{AMT_DIS}.</div>
            <AreaChart colors={['#5448E4', '#2CBEE1']} data={areaChartData} />
          </div>
          <div css={Mobileheadding}>{COUNT_DIS}</div>
          <div css={chartsLeftSection}>
            <div css={headding}>{COUNT_DIS}</div>
            <AreaChart colors={['#FF4500', '#4B0082']} data={areaChart2Data} />
          </div>
        </div>
        <div css={ChartsContainer}>
        <div css={Mobileheadding}>{PRODUCT_APP}</div>
          <div css={chartsLeftSection}>
            <div css={headding}>{PRODUCT_APP}</div>
            <CustomBarChart data={productVsApplications} colors={['#3359DC', '#2CA79F', '#EB730B']} />
          </div>
          <div css={Mobileheadding}>{PRODUCT_DIS}</div>
          <div css={chartsLeftSection}>
            <div css={headding}>{PRODUCT_DIS}</div>
            <CustomBarChart data={productVsDisbursed} colors={["#153070", "#972CF1", "#B00B43"]} currency={true} />
          </div>
        </div></>}
      </DashboardLoyout>
    </div>
  );
};

export default Dashboard;