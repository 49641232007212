import DashboardLoyout from "../../components/DashbaordLayout/DashbaordLayout";


const UploadCpv = () => {
  return (
    <div>
     <DashboardLoyout>
        Upload CPV Reports on DMS
    </DashboardLoyout>
    </div>
  );
};

export default UploadCpv;
