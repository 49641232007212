/** @jsxImportSource @emotion/react */

import React, { FC, ChangeEvent, useState, useEffect, useRef } from 'react';
import { styled } from '@mui/material/styles';
import { colors, typography } from '../../scss/colors';
import { css } from '@emotion/react';
import ClearIcon from '@mui/icons-material/Clear';
import { icon } from '../../assets/Images';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { successIcon } from '../Otp/Otp.style';
import { getLocalStorage } from '../../utils/browserHandlers/browserStorageHandler';
import CustomizedTooltips from '../ToolTip/ToolTip';

interface CustomInputProps {
  value?: any;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  customStyles?: React.CSSProperties;
  validate?: (value: string) => string | null;
  className?: string;
  mandatory?: boolean;
  inputLabel?: string;
  disabled?: boolean;
  onClear?: () => void;
  registration?: boolean;
  docId?: any;
  documentId?: string; 
  fileName ?:string;
}

const CustomTextField = styled('div')(({ theme }) => ({
  marginBottom: theme.spacing(2),
  '& .file-input-container': {
    position: 'relative',
  },
  '& .file-input': {
    display: 'none',
  },
  '& .custom-input': {
    backgroundColor: colors.white,
    height: '40px',
    width: '100%',
    border: `1px solid ${colors.inputBorder}`,
    display: 'flex',
    borderRadius: '4px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    color: colors.black,
  },
  '& .browse-section': {
    borderRight: `1px solid ${colors.inputBorder}`,
    display: 'flex',
    justifyContent: 'flex-start',
    width: '35%',
    height: '40px',
    backgroundColor: colors.browseText,
    borderRadius: '4px',
    cursor: 'pointer',
  },
  '& .browse-text': {
    padding: '7px 13px',
    color: colors.charcoal,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
  },
  '& .file-name': {
    paddingLeft: '2px',
    color: colors.charcoal,
    fontFamily: 'Plus Jakarta Sans',
    fontSize: '16px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  '& .clear-button': {
    cursor: 'pointer',
  },
}));

const iconStyle = {
  color: colors.lightGray,
  paddingTop: '5px',
};

const inputLabelCss = css`
  text-align: left;
  margin-bottom: 5px;
  color: ${colors.charcoal};
  ${typography('bold', '14px')};
`;

const labelMandatory = css`
  color: ${colors.crimson};
`;
const imgCss = css`
  padding-left: 4px;
  margin-bottom: -1px;
`;

const InputFile: FC<CustomInputProps> = ({
  value,
  onChange,
  customStyles,
  validate,
  className,
  mandatory = false,
  inputLabel,
  disabled = false,
  onClear,
  registration = true,
  docId = '',
  documentId,
  fileName
}) => {
  const [error, setError] = useState<string | null>(null);
  const [touched, setTouched] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (validate && touched) {
      const validationError = validate(value);
      setError(validationError);
    }
  }, [value, validate, touched]);

  

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (onChange) {
      onChange(event);
    }
    setTouched(true);
  };

  const handleClear = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
    if (onClear) {
      onClear();
    }
  };

  const disabledSelect = getLocalStorage("final") || disabled;
  const disabledStyles = css`
    background-color: ${disabledSelect ? '#f5f5f5' : colors.white} !important;
    border-color: ${disabledSelect ? '#e0e0e0' : colors.inputBorder} !important;
  `;

  return (
    <div>
      {inputLabel && (
        <div css={inputLabelCss}>
          {inputLabel}
          {mandatory && <span css={labelMandatory}>*</span>}
          <CustomizedTooltips title="Please upload PNG/PDf" placement="top-end">
          <img src={icon.validation_Error} alt="validation_error" css={imgCss} />
          </CustomizedTooltips>
        </div>
      )}
      <CustomTextField style={customStyles} className={className}>
        <div className="file-input-container">
          <div className="custom-input" css={disabledStyles}>
            <div className="browse-section" onClick={() => fileInputRef.current?.click()}>
              <div className="browse-text">Browse</div>
            </div>
            {fileName && ( 
              <>
                <div className="file-name">{fileName}</div>
                <div className="clear-button" onClick={handleClear}>
                  {registration ? (
                    <ClearIcon sx={iconStyle} />
                  ) : (
                    <CheckCircleIcon css={successIcon} />
                  )}
                </div>
              </>
            )}
          </div>
          <input
            ref={fileInputRef}
            type="file"
            className="file-input"
            onChange={handleChange}
            disabled={disabledSelect}
            id={docId}
            data-document-id={documentId} 
          />
        </div>
        {touched && error && <div css={css`color: red; margin-top: 5px;`}>{error}</div>}
      </CustomTextField>
    </div>
  );
};

export default InputFile;
