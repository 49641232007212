/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { StringConstants } from "../../../constants/Strings";
import CustomTable from "../../../components/Table/Table";
import { colors, typography } from "../../../scss/colors";
import { css } from '@emotion/react';
import CustomSelect from "../../../components/Select/Select";
import { regBackButton, regNextButton, regPanInput, registrationButtonContainer } from "../../Registration/Registration.style";
import CustomButton from "../../../components/Button/Button";
import { getLocalStorage, setLocalStorage } from '../../../utils/browserHandlers/browserStorageHandler';
import { get, post } from '../../../apiManger/apiMethods';
import { getApplicationListPOSTApiUrl, getPayoutSlabGETApiUrl, getUploadPayoutDocsPostApiUrl, getUploadPayoutSlabPostApiUrl } from '../../../apiManger/apiConstants';
import { useNavigate } from "react-router-dom";
import Loder from "../../../components/Loader/Loder";
import { useToastHook } from "../../../components/Toast/useToastHook";
import { ToastContainer } from "react-toastify";

const columns = [
  { id: 'a', label: 'A', minWidth: 100 },
  { id: 'b', label: 'B', minWidth: 100 },
  { id: 'c', label: 'C', minWidth: 100 },
  { id: 'd', label: 'D', minWidth: 100 },
  { id: 'e', label: 'E', minWidth: 100 },
];

const data = [
  { a: 'Flat 3%', b: '1L - 50L : 2%', c: '1L - 50L : 2.5%', d: '1L - 75L : 2.5%', e: 'Other' },
  { b: '51L - 125L : 2.5%', c: '51L - 100L : 2.5%', d: '76L And Above :3%', e: 'Flexibale As Per Branch Requirement' },
  { b: '', c: '101L - 200L : 3%' },
  { c: '201L - 300L : 3.25%' },
  { c: '301L And Above :3.5%' },
];

const PayoutMainHeadding = css`
  color: ${colors.lightBlack};
  ${typography('600', '24px')};
  margin-bottom: 20px;
`;

const payoutSelect = css`
  display: flex;
  justify-content: start;
  padding:20px 0px;
`;

const PayoutStructure = (props: any) => {
  const {showToast} = useToastHook()
  const { setValue, pagename ,value} = props;
  const { PAYOUT_HEADDING, SELECT_PLACEHOLDER, BACK_LABEL, NEXT_LABEL } = StringConstants;
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [dsaPayoutSlab, setDsaPayoutSlab] = useState("");
  const navigate = useNavigate();
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
  } = Loder();
  const registrationId = getLocalStorage('Application_registrationId');

  useEffect(() => {
    if(getLocalStorage("payoutSlab")){
      getAPI();
    }
   
  }, [])
  const handleSelectChange = (e: any) => {
    setDsaPayoutSlab(e.target.value);
  };

  const getAPI = async () => {
    showLoadingIndicator()

    const response = await get({
      url: getPayoutSlabGETApiUrl(registrationId)
    });

    if (response?.result) {
      hideLoadingIndicator()
      setDsaPayoutSlab(response.result.dsaPayoutSlab)
    }else if(response?.error?.message){
      showToast(response?.error?.message,"error")
      hideLoadingIndicator();
    }
  else {
      showToast("something went wrong","error")
      hideLoadingIndicator();
  }
  }

  const handleSubmit = async () => {
    showLoadingIndicator()

    const payload = {
      registrationId,
      dsaPayoutSlab,
      status: 1
    };

    try {
      const response = await post({
        url: getUploadPayoutSlabPostApiUrl(),
        obj: payload
      });

      if (response.result?.screenName) {
        setLocalStorage("payoutSlab","payoutSlab")
        hideLoadingIndicator()

        if (pagename == 'payout') {
          navigate('/sanction')
        }else {
          setValue(value + 1)
        }


      }
      else {
        hideLoadingIndicator()
      }
    } catch (error) {
      hideLoadingIndicator()

      console.error('Error occurred while submitting payout details:', error);
    }
   
  };

  return (
    <div>
      {visible ?
        <>{loadingComponent()}</> :
        <>
          <div css={PayoutMainHeadding}>{PAYOUT_HEADDING}</div>
          <CustomTable
            columns={columns}
            data={data}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            onlyTable={true}
            showPagination={false}
          />
          <div css={payoutSelect}>
            <CustomSelect
              options={[
                { value: 'A', label: 'A' },
                { value: 'B', label: 'B' },
                { value: 'C', label: 'C' },
                { value: 'D', label: 'D' },
                { value: 'E', label: 'E' }
              ]}
              placeholder={SELECT_PLACEHOLDER}
              value={dsaPayoutSlab}
              onChange={handleSelectChange}
              inputLabel="DSA Payout Slab"
              mandatory={true}
              css={regPanInput}
            />
          </div>
          <div css={registrationButtonContainer}>
            <CustomButton
              label={BACK_LABEL}
              onClick={() => setValue((pre: any) => pre - 1)}
              variant="outlined"
              css={regBackButton}
            />
            <CustomButton
              label={NEXT_LABEL}
              onClick={handleSubmit}
              css={regNextButton}
              disabled={!dsaPayoutSlab} 
            />
          </div>
          <ToastContainer />
        </>
      }
    </div>
  );
};

export default PayoutStructure;
