/** @jsxImportSource @emotion/react */
import { useState, startTransition, useEffect } from 'react';
import CustomStepper from '../../components/Stepper/Stepper';
import CustomButton from '../../components/Button/Button';
import { colors, typography } from '../../scss/colors';
import { css } from '@emotion/react';
import Layout from '../../components/Layout/Layout';
import { utilsConstants } from '../../utils/utilsConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { regBackButton, regNextButton, regNextButtonDisabled, registrationButtonContainer } from '../Registration/Registration.style';
import { StringConstants } from '../../constants/Strings';
import InputFile from '../../components/InputFile/InputFile';
import { icon } from '../../assets/Images';
import { get, post } from '../../apiManger/apiMethods';
import { getCompanyGETApiUrl, getCompanyKYCGETApiUrl, getCompanyKYCPostApiUrl, getDocumentApiUrl, getPartnerKYCGETApiUrl, getPartnerKYCPostApiUrl, getUploadFilePostApiUrl, uploadFileApiUrl } from '../../apiManger/apiConstants';
import { deleteLocalStorage, getLocalStorage, setLocalStorage } from '../../utils/browserHandlers/browserStorageHandler';
import Loder from '../../components/Loader/Loder';
import { Box, Grid } from '@mui/material';
import { previewSection } from '../ApplicationReview/InitiateKyc/KycDetails';
import { useToastHook } from '../../components/Toast/useToastHook';
import { ToastContainer } from 'react-toastify';
import PreviewComponent from './Preview';

const containerStyle = css`
  color: ${colors.charcoal};
  ${typography('600', '20px')};
`;

const breakLine = css`
  border: 1px solid rgba(100, 130, 130, 0.25);
  margin-bottom: 25px;
`;





const kycSubHeading = css`
  color: ${colors.charcoal};
  ${typography('600', '16px')}
  display: flex;
  justify-content: flex-start;
margin-bottom:20px;
`;

const submitGetBackText = css`
  color: ${colors.charcoal};
  ${typography('400', '16px')};
  line-height: 20.16px;
  margin-top: 50px;
 display: flex;
    justify-content: center;
    align-items: center;
     h3 {
     padding:0px 10px;
}

`;

export const errorMsg = css`
display:flex;
justify-content:start;
 color: ${colors.crimson};
  ${typography('500', '14px')};
`
const gridContainerStyle = css`
width: calc(100% + 30px);
@media(max-width:768px){
width: calc(100% + 20px);
}
`;
const formControlStyle = css`
  margin-bottom: 16px;
   width: 100%;
`;
const KycDocumentes = () => {
  const { showToast } = useToastHook()
  const { STEPPER, STEPPER_INDIVIDUAL, STEPPER_INDIVIDUAL_DIRECTOR, RegisterTypeOptions, EntityTypeOptions, PartnerShipTypeOptions } = utilsConstants;
  const { SuccessGiff } = icon;
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const { BACK_LABEL, SUBMIT_LABEL, KYC_DOCUMENTS, KYC_VERIFICATION_HEADING, KYC_VERIFICATION_COMPANY_HEADING, SUBMITTED_SUCCESS_HEADING, SUBMITTED_CONNECT_BACK_TEXT } = StringConstants;
  const navigate = useNavigate();
  const [documents, setDocuments] = useState<any>({
    pan: { fileId: "", fileName: "" },
    aadhar: { fileId: "", fileName: "" },
    voter: { fileId: "", fileName: "" },
    driving: { fileId: "", fileName: "" },
    passport: { fileId: "", fileName: "" },
    additional1: { fileId: "", fileName: "" },
    additional2: { fileId: "", fileName: "" },
    business: { fileId: "", fileName: "" },
    gst: { fileId: "", fileName: "" },
    udyam: { fileId: "", fileName: "" },
    additional3: { fileId: "", fileName: "" },
    additional4: { fileId: "", fileName: "" }
  });

  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
  } = Loder();
  const docList = [
    { inputLabel: "PAN Card", name: "pan", docid: '1ce76767-3999-4ceb-af72-a7ba96ac8f2d', value: '', fileId: '' },
    { inputLabel: "Aadhaar Card", name: "aadhar", docid: '2ce76767-3999-4ceb-af72-a7ba96ac8f2d', value: '', fileId: '' },
    { inputLabel: "Voter ID", name: "voter", docid: '3ce76767-3999-4ceb-af72-a7ba96ac8f2d', value: '', fileId: '' },
    { inputLabel: "Driving License", name: "driving", docid: "4ce76767-3999-4ceb-af72-a7ba96ac8f2d", value: '', fileId: '' }
  ];

  const docList2 = [
    { inputLabel: "Passport", name: "passport", docid: '5ce76767-3999-4ceb-af72-a7ba96ac8f2d', value: '', fileId: '' },
    { inputLabel: "Additional Document", name: "additional1", docid: '41d6e7c5-9431-4993-86fa-fa6deb01e5f1', value: '', fileId: '' },
    { inputLabel: "Additional Document", name: "additional2", docid: '10662581-4f7b-4c0d-918c-d937e1c9cf51', value: '', fileId: '' }
  ];

  const companydocList = [
    { inputLabel: "Business PAN Card", name: "business", docid: "c98bf386-ec7a-43f7-a7b3-bed71ec28ff8", value: '', fileId: '' },
    { inputLabel: "GST", name: "gst", docid: 'fd542557-09bf-4568-b20a-3d0bedc2c0d8', value: '', fileId: '' },
    { inputLabel: "Udyam Aadhaar", name: "udyam", docid: '15032b4c-5f45-47f7-8ec0-f1479af27a69', value: '', fileId: '' },
    { inputLabel: "Additional Document", name: "additional3", value: '', fileId: '' , docid: '41d6e7c5-9431-4993-86fa-fa6deb01e5f1'},
    { inputLabel: "Additional Document", name: "additional4", value: '', fileId: '', docid: '10662581-4f7b-4c0d-918c-d937e1c9cf51' }
  ];

  const [docListState, setDocListState] = useState(docList);
  const [docList2State, setDocList2State] = useState(docList2);
  const [companydocListState, setCompanyDocListState] = useState(companydocList);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [uploadedDocuments, setUploadedDocuments] = useState<any>([]);
  const [companyUploadedDoc, setCompanyUploadedDoc] = useState<any>([]);
  const registrationId = getLocalStorage('registrationId');

  const { state } = useLocation();
  const [stepper, setStepper] = useState(STEPPER);
  const entityType = getLocalStorage('Entity Type');
  const partnershipTypeLS = getLocalStorage('Partnership Type');
  const [stepCount, setStepCount] = useState(entityType === 'Individual' ? 3 : 4);
  const [errors, setErrors] = useState<any>({});
  const [isalreadySubmitted, setIsAlreadySubmitted] = useState(false)
  const [isCompanyAlreadySubmitted, setIsCompanyAlreadySubmitted] = useState(false)
  useEffect(() => {
    if (entityType === 'Individual') {
      setStepper(STEPPER.filter(step => step !== 'Company Details'));
    } else if (entityType === 'Business Entity') {
      if (partnershipTypeLS === "Sole Proprietorship") {
        setStepper(STEPPER);
      } else {
        setStepper(STEPPER_INDIVIDUAL_DIRECTOR);
      }
    }
  }, [entityType]);

  useEffect(() => {
    getAPICall();
    if (getLocalStorage('Entity Type') === "Business Entity") {
      getCompanyAPICall();
    }
  }, []);

  const getAPICall = async () => {
    showLoadingIndicator();
    const response = await get({
      url: getPartnerKYCGETApiUrl(registrationId)
    });
    if (response?.result) {
      hideLoadingIndicator();
      const updatedDocList = docList.map((item) => {
        const resItem = response?.result?.length && response?.result.find((res: any) => res.fileTypeId === item.docid);

        return resItem ? { ...item, value: resItem.fileName, documentId: resItem.id, fileUrl: resItem.fileUrl, fileId: resItem.fileId } : item;
      });

      const updatedDocList2 = docList2.map((item) => {
        const resItem = response?.result?.length && response?.result.find((res: any) => res.fileTypeId === item.docid);
        return resItem ? { ...item, value: resItem.fileName, documentId: resItem.id, fileUrl: resItem.fileUrl, fileId: resItem.fileId } : item;
      });
      setIsAlreadySubmitted(true);
      setDocListState(updatedDocList);
      setDocList2State(updatedDocList2);
    }
    else {
      hideLoadingIndicator();
    }
  };
  const getCompanyAPICall = async () => {
    showLoadingIndicator();
    const response = await get({
      url: getCompanyKYCGETApiUrl(registrationId)
    });
    if (response?.result) {
      hideLoadingIndicator();
      const updatedCompanyDocList = companydocList.map((item) => {
        const resItem = response?.result?.length && response?.result?.find((res: any) => res.fileTypeId === item.docid);
        return resItem ? { ...item, value: resItem.fileName, documentId: resItem.id, fileUrl: resItem.fileUrl, fileId: resItem.fileId } : item;
      });
      setIsCompanyAlreadySubmitted(true)
      setCompanyDocListState(updatedCompanyDocList);
    }
    else {
      hideLoadingIndicator();
    }
  };
  const handleNext = async () => {
    showLoadingIndicator();
    try {
      const validDocuments = uploadedDocuments.filter((doc: any) => doc !== null);
      const payloadItems = validDocuments.map((item: any) =>
      ({
        fileTypeId: item.fileTypeId,
        fileId: item.fileId,
        fileSize: item?.file_size,
        fileType: item?.file_type,
        fileName: item?.file_name,
        status: 1,
        id:item?.id
      }));
      const companyDocuments = companyUploadedDoc.filter((doc: any) => doc !== null);
      const companyPayload = companyDocuments.map((item: any) =>
      ({
        fileTypeId: item.fileTypeId,
        fileId: item.fileId,
        fileSize: item?.file_size,
        fileType: item?.file_type,
        fileName: item?.file_name,
        status: 1,
        id:item?.id
      }));


      let obj = {
        "registrationId": registrationId,
        "kyc": payloadItems
      }
      let obj2 = {
        "registrationId": registrationId,
        "kyc": companyPayload
      }
      const response = await post({
        url: getPartnerKYCPostApiUrl(registrationId),
        obj: obj
      });
      let response2: any = {};
      if ((getLocalStorage('Entity Type') === "Business Entity" && companyPayload)) {
        response2 = await post({
          url: getCompanyKYCPostApiUrl(registrationId),
          obj: obj2
        });
      }
      if (response.result) {
        hideLoadingIndicator();
        startTransition(() => {
          setStepCount(5);
        });
        if(response.result?.screenName == "verify_kyc"){
        setIsSubmitted(true);
        setLocalStorage("final", true)
        }else {
          showToast("Please retry again", "error")
        }
       
      } else if (response?.error?.message) {
        showToast(response?.error?.message, "error")
        hideLoadingIndicator();
      } else {
        showToast("something went wrong", "error")
        hideLoadingIndicator();
      }

    } catch (error) {
      showToast("something went wrong", "error")
      hideLoadingIndicator();
      console.error("Failed to submit individual details:", error);
    }
  };

  const handleBack = () => {
    if (entityType.includes("Individual")) {
      setStepCount(1);
      navigate("/bank-details");
    } else if ((entityType.includes("Business")) && (partnershipTypeLS.includes("Proprietorship"))) {
      setStepCount(2);
      navigate("/bank-details");
    } else if ((entityType.includes("Business")) && (!partnershipTypeLS.includes("Proprietorship"))) {
      setStepCount(3);
      navigate('/bank-details')
    }
  };
  const handleChange = async (fieldName: any, e: any, type: any) => {
    const file = e.target.files?.[0];
    const fileTypeId = e.target.id;
    const documentId = e.target.getAttribute('data-document-id');
    const fileSize = file.size;
  const fileType = file.type;
    if (fileSize > 1 * 1024 * 1024) {
      showToast("File size too large. Maximum size is 1 MB.","error");
      return;
    }
    if (fileType == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
      showToast("Invalid file type .docx files are not allowed.","error");
      return;
    }else if(fileType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType == "application/vnd.ms-excel"){
      showToast("Invalid file type .xlsx or .xls files are not allowed.", "error");
      return;
    }
    if (file) {
      const response: any = await fileUpload(file);

      if (response?.result?.[0]?.file_id) {
        setDocuments((prevDocuments: any) => ({
          ...prevDocuments,
          [fieldName]: {
            fileId: response?.result[0]?.file_id,
            fileName: file.name
          }
        }));
        if (type === "company") {
          setCompanyUploadedDoc((prev: any) => {
            const updatedDocuments = prev.filter((doc: any) => doc.documentType !== fieldName);
            return [
              ...updatedDocuments,
              {
                ...response.result[0],
                documentType: fieldName,
                fileTypeId: fileTypeId,
                fileId: response.result[0].file_id,
                id: documentId || null
              }
            ];
          });
      
        } else {
          setUploadedDocuments((prev: any) => {
            const updatedDocuments = prev.filter((doc: any) => doc.documentType !== fieldName);
            return [
              ...updatedDocuments,
              {
                ...response.result[0],
                documentType: fieldName,
                fileTypeId: fileTypeId,
                fileId: response.result[0].file_id,
                id: documentId
              }
            ];
          });
        }
      } else if (response?.error) {
        const error = fieldName === '' ? null : response?.error?.message;
        setErrors({
          ...errors,
          [fieldName]: error
        });
      } else {
        showToast("something went wrong", "error")
      }
    }
  };
  const fileUpload = async (file: any) => {
    const data = new FormData();
    data.append("file", file);
    const response: any = await uploadFileApiUrl(data);
    return response;
  };
  const handleClear = (fieldName: any) => {
    setDocuments({
      ...documents,
      [fieldName]: ""
    });
    setErrors({
      ...errors,
      [fieldName]: null
    });
  };
  useEffect(() => {
    let existingArray = JSON.parse(getLocalStorage("completed") || "[]");
    const entityType = getLocalStorage('Entity Type');
    const partnershipTypeLS = getLocalStorage('Partnership Type');
    if (entityType?.includes("Individual") && existingArray?.includes("individual") && existingArray?.includes("bank")) {
      setIsNextButtonDisabled(false);
    } else if (entityType?.includes("Business") && partnershipTypeLS?.includes("Proprietorship") && existingArray?.includes("individual") && existingArray?.includes("company") && existingArray?.includes("bank")) {
      setIsNextButtonDisabled(false);
    } else if (entityType?.includes("Business") && !partnershipTypeLS?.includes("Proprietorship") && existingArray?.includes("company") && existingArray?.includes("director") && existingArray?.includes("bank")) {
      setIsNextButtonDisabled(false);
    } else {
      setIsNextButtonDisabled(true);
    }
  }, [isNextButtonDisabled]);

  useEffect(() => {
    if (state) {
      const { registerType, enityType, PartnershipType } = state;
      if (registerType === RegisterTypeOptions[0]?.label && enityType === EntityTypeOptions[0]?.label) {
        setStepper(STEPPER_INDIVIDUAL);
      } else if (registerType === RegisterTypeOptions[0]?.label && (PartnershipType === PartnerShipTypeOptions[1]?.label || PartnershipType === PartnerShipTypeOptions[5]?.label)) {
        setStepper(STEPPER_INDIVIDUAL_DIRECTOR);
      }
    }
  }, [stepper]);



  const errorMsgText = Object.values(errors).every(error => error === null);
  const mobile = window.innerWidth < 768;
  return (
    <Layout>
      {!mobile && <CustomStepper steps={stepper} stepCount={stepCount} />}
      {visible ?
        <>{loadingComponent()}</> :
        <>   {!isSubmitted ? (
          <div>

            <div css={containerStyle}>
              {KYC_DOCUMENTS}
            </div>
            <hr css={breakLine} />
            <div css={kycSubHeading}>
              {KYC_VERIFICATION_HEADING}
            </div>
            <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
              <Grid container spacing={2} css={gridContainerStyle}>
                {/* <div css={registrationOtpContainer}> */}
                {docListState?.map((item: any, index: any) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <InputFile
                      key={index}
                      inputLabel={item.inputLabel}
                      onChange={(e) => handleChange(item.name, e, "partner")}
                      css={formControlStyle}
                      onClear={() => handleClear(item.name)}
                      docId={item.docid}
                      value={documents[item.name]}
                      documentId={item.documentId}
                      fileName={documents[item.name]?.fileName || item.value}
                    />
                    {errors[item.name] && <div css={{ color: colors.crimson, fontSize: '12px', textAlign: "left", marginTop: "-10px" }}>{errors[item.name]}</div>}
                    {(documents[item.name]?.fileId || item?.fileId) && <PreviewComponent file={documents[item.name]?.fileId || item?.fileId} />
                    }
                  </Grid>
                ))}
              </Grid>
            </Box>
            {/* </div> */}
            {/* <div css={kycPassportContainer}> */}
            <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
              <Grid container spacing={2} css={gridContainerStyle}>
                {docList2State.map((item: any, index: any) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <InputFile
                      key={index}
                      inputLabel={item.inputLabel}
                      onChange={(e) => handleChange(item.name, e, "partner")}
                      css={formControlStyle}
                      onClear={() => handleClear(item.name)}
                      docId={item.docid}
                      value={item.value}
                      documentId={item.documentId}
                      fileName={documents[item.name]?.fileName || item.value}
                    />
                    {errors[item.name] && <div css={{ color: colors.crimson, fontSize: '12px', textAlign: "left", marginTop: "-10px" }}>{errors[item.name]}</div>}
                    {(documents[item.name]?.fileId || item?.fileId) && <PreviewComponent file={documents[item.name]?.fileId || item?.fileId} />
                    }
                  </Grid>
                ))}
              </Grid>
            </Box>
            {/* </div> */}
            {(getLocalStorage('Entity Type') === "Business Entity") && (
              <>
                <div css={kycSubHeading}>
                  {KYC_VERIFICATION_COMPANY_HEADING}
                </div>
                {/* <div css={registrationOtpContainer}> */}
                <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
                  <Grid container spacing={2} css={gridContainerStyle}>
                    {companydocListState.map((item: any, index: any) => (
                      <Grid item xs={12} sm={6} md={3} key={index}>
                        <InputFile
                          key={index}
                          inputLabel={item.inputLabel}
                          onChange={(e) => handleChange(item.name, e, "company")}
                          css={formControlStyle}
                          onClear={() => handleClear(item.name)}
                          docId={item.docid}
                          value={item.value}
                          documentId={item.documentId}
                          fileName={documents[item.name]?.fileName || item.value}
                        />
                        {errors[item.name] && <div css={{ color: colors.crimson, fontSize: '12px', textAlign: "left", marginTop: "-10px" }}>{errors[item.name]}</div>}
                        {(documents[item.name]?.fileId || item?.fileId) && <PreviewComponent file={documents[item.name]?.fileId || item?.fileId} />
                        }
                      </Grid>
                    ))}
                  </Grid>
                </Box>
                {/* </div> */}
              </>
            )}
            {/* {!errorMsgText &&
            <div css={errorMsg}>
              Invalid File Type
            </div>
          } */}
            <div css={registrationButtonContainer}>
              <CustomButton
                label={BACK_LABEL}
                onClick={handleBack}
                variant="outlined"
                css={regBackButton}
                disabled={stepCount === 0}
              />
              <CustomButton
                label={SUBMIT_LABEL}
                onClick={handleNext}
                css={!isNextButtonDisabled ? regNextButton : regNextButtonDisabled}
              />
            </div>
          </div>) :
          (<div>
            <div css={containerStyle}>
              {SUBMITTED_SUCCESS_HEADING}
            </div>
            <hr css={breakLine} />
            <div>
              <img src={SuccessGiff} alt="no_success_img" />
            </div>
            <div css={submitGetBackText}>
              Your application <h3>{getLocalStorage('application_number')}</h3>has submitted to us. We will connect back with you shortly!
            </div>
          </div>)

        }

        </>}
      <ToastContainer />
    </Layout>
  );
};

export default KycDocumentes;