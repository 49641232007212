import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepConnector from '@mui/material/StepConnector';
import { styled } from '@mui/material/styles';
import { stepConnectorClasses } from '@mui/material/StepConnector';
import { StepIconProps } from '@mui/material/StepIcon';
import { icon } from '../../assets/Images';
import { useNavigate } from 'react-router-dom';
import "./Stepper.scss"
const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 10,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#E12C36',
    },
  },
  [`& .${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: '#E12C36',
    },
  },
  '& .MuiStepConnector-line': {
    borderColor: '#9E9E9E',
    marginLeft: '-6%',
    marginRight: '-6%',
    [theme.breakpoints.down('sm')]: {
      marginLeft: '-18%', 
      marginRight: '-18%',
    },
  },
}));

const CustomStepIconRoot = styled('div')<{
  ownerState: { active?: boolean; completed?: boolean };
}>(({ theme, ownerState }) => ({
  color: theme.palette.mode === 'dark' ? theme.palette.grey[700] : theme.palette.grey[300],
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'relative',
  height: 24,
  ...(ownerState.active && {
    color: '#E12C36',
  }),
  '& .CustomStepIcon-tick': {
    color: '#E12C36',
    fontSize: 18,
  },
  '& .CustomStepIcon-circle': {
    width: ownerState.active ? 11 : 16,
    height: ownerState.active ? 11 : 16,
    borderRadius: '50%',
    backgroundColor: ownerState.active ? '#E12C36' : (ownerState.completed ? '#E12C36' : '#9E9E9E'),
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  },
  
  '& .CustomStepIcon-ring': {
    position: 'absolute',
    top: '50%',
    left: '50%',
    width: 16,
    height: 16,
    borderRadius: '50%',
    border: ownerState.active ? '2px solid #E12C36' : (ownerState.completed ? '2px solid #E12C36' : '2px solid #9E9E9E'),
    transform: 'translate(-50%, -50%)',
  },
}));

const CustomStepIcon = (props: StepIconProps) => {
  const { active, completed, className } = props;

  return (
    <CustomStepIconRoot ownerState={{ completed, active }} className={className}>
      {completed ? (
        <img src={icon.CheckIcon} alt="no_checkicon_img" className="CustomStepIcon-tick"/>
      ) : (
        <>
          <div className="CustomStepIcon-ring" />
          <div className="CustomStepIcon-circle" />
        </>
      )}
    </CustomStepIconRoot>
  );
};

const CustomStepperStyle = styled(Stepper)(({ theme }) => ({
  backgroundColor: 'transparent',
  padding: theme.spacing(3),
 
  '& .MuiStepLabel-label': {
    marginTop: theme.spacing(1),
    whiteSpace: 'nowrap',
  },
  '& .MuiStep-root' :{
    cursor: "pointer",
  },
  '& .Mui-disabled' : {
    cursor: "pointer"
  },
 
}));

interface CustomStepperProps {
  steps: string[];
  stepCount: number;
  disableOnClick?: boolean;
}

const CustomStepper: React.FC<CustomStepperProps> = ({ steps, stepCount, disableOnClick = false }) => {
  const navigate = useNavigate();
  
  const ROUTES = steps.map(step => {
    switch (step) {
      case 'Registration': return '/register';
      case 'Individual Details': return '/individual-details';
      case 'Company Details': return '/company-details';
      case 'Bank Details': return '/bank-details';
      case 'KYC Documents': return '/kyc-documents';
      case 'Director Details': return '/director-details';

      default: return '/';
    }
  });

  const handleStepClick = (index: number) => {
    if (!disableOnClick) {
      navigate(ROUTES[index]);
    }
  };

  return (
    <CustomStepperStyle activeStep={stepCount} connector={<CustomStepConnector />} alternativeLabel>
      {steps.map((label, index) => (
        <Step key={label} completed={stepCount > index} onClick={() => handleStepClick(index)}>
          <StepLabel StepIconComponent={CustomStepIcon}>{label}</StepLabel>
        </Step>
      ))}
    </CustomStepperStyle>
  );
};

export default CustomStepper;
