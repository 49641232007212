/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import DashboardLoyout from "../../components/DashbaordLayout/DashbaordLayout";
import TabsComponent from "../../components/TabsComponent/TabsComponent";
import {  VeirfyMainHeadding, payoutMainSection } from "../ApplicationReview/InitiateKyc/InitiateKyc.style";
import Profile from "./Profile";
import ChangePassword from "./ChangePassword";
import { StringConstants } from "../../constants/Strings";
import { css } from "@emotion/react";

const InitiateKycDetailsSection = css`
margin-top:20px;
@media (max-width: 768px) {
margin-top:0px;
margin-left:-10px;
}
            `
const Account = () => {
    const [value, setValue] = useState(0);
    const {ACCOUNT} = StringConstants;
    const [isProfileUpdated, setIsProfileUpdated] = useState(false); 
    const handleProfileUpdate = () => {
      setIsProfileUpdated(prevState => !prevState);
  };
    const tabs = [
      {
        label: 'Profile',
        content: (
          <div>
          <Profile  onUpdate={handleProfileUpdate}/>
          </div>
        ),
      },
      // {
      //   label: 'Change Password',
      //   content:
      //     <div>
      //       <ChangePassword />
      //     </div>
      // },
     
    ];

    return(
        <div key={isProfileUpdated ? 'updated' : 'not-updated'}>
            <DashboardLoyout>
            <div css={payoutMainSection}>
          <div css={VeirfyMainHeadding}>{ACCOUNT}</div>
        </div>
        <div css={InitiateKycDetailsSection}>
          <TabsComponent tabs={tabs} value={value} setValue={setValue} center={true}/>
          </div>
            </DashboardLoyout>
        </div>
    )
}
export default Account;