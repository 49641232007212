export const StringConstants = {
    Header_Strings: {
        header_main: "Welcome to",
        company_name: "Ashv Finance",
        header_tagline: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, veesijod"
    },
    TERMS_AND_CONDITION_STRING: {
        TERMS_AND_CONDITION_LABEL: "Terms & Conditions",
        TERM_AND_CONDITION_PARA_1: "The Vendor/Partner/Agency (“the Company”) must compulsorily adhere to the following terms & conditions throughout the tenure of empanelment with Ashv Finance Ltd. (“Ashv”). ",
        TERM_AND_CONDITION_PARA_2: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
        TERM_AND_CONDITION_PARA_3: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?",
        TERM_AND_CONDITION_PARA_4: "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur.",
        TERM_AND_CONDITON_FOOTER: "By entering the OTP  Below you agree to the above Terms & Conditions."
    },

    LOGIN_STRING: {
        WELCOME: "Welcome Back",
        WELCOME_DESC: "We're excited to see you again. Please enter your email and password to log in to your account.",
        DESC: "Lorem ipsum dolor sit amet consectetur. Proin massa ut justo pellentesque diam sit enim facilisis egestas.",
        REMEMBER_ME: "Remember me",
        FORGOT: "Forgot password?",
        NO_ACC: "Don’t have an account? ",
        FREE_ACC: "Create free account",
        LOGIN: "Login",
        SIGN_IN: "Sign In",
        LABEL_EMAIL:"Email ID Or Mobile Number",
        LABEL_PASSWORD:"Password",
    },
    SIGNUP_STRING: {
        WELCOME: "Welcome to our community",
        WELCOME_DESC: "Lorem ipsum dolor sit amet consectetur. Blandit eu viverra ac lobortis dapibus ullamcorper ornare amet in.",
        DESC: "Lorem ipsum dolor sit amet consectetur. Proin massa ut justo pellentesque diam sit enim facilisis egestas.",
        REMEMBER_ME: "Remember me",
        FORGOT: "Forgot password?",
        NO_ACC: "Already Have An Account?",
        FREE_ACC: "Login Here",
        LOGIN: "Sign Up",
        SIGN_IN: "Sign Up",
        LABEL_FULLNAME:"Full Name",
        LABEL_EMAIL:"Email ID Or Mobile Number",
        LABEL_PASSWORD:"Password",
    },

    SEND_OTP: "Send OTP",
    VERIFY_OTP_SUCCESS: "OTP Verification Successful",
    VERIFY_OTP_FAILURE: "OTP Verification Error",
    SUCCESS: "success",
    ERROR: "Error",
    RESEND_OTP: "Resend OTP",
    MOBILE_NO_LABEL: "Mobile No.",
    MOBILE_TYPE: "mob",
    MOBILE_PLACEHOLDER: "Enter Mobile No",
    OTP_LABEL: "OTP",
    OTP_TYPE: "otp",
    OTP_PLACEHOLER: "Please Enter OTP",
    RESEND_OTP_IN: "Resend OTP In",
    VERIFY_OTP: "Verify OTP",
    ATTEMPTS_LEFT: "Attempts Left",
    REGISTER_TYPE: "reg",
    PARTNER_TYPE: "partner",
    ENTITY_TYPE: "entity",
    PARTNERSHIP_TYPE: "partnerShip",
    PAN_TYPE: "pan",
    ONBOARDING_HEADING: "OTP consent for bureau and onboarding",
    PAN_LABEL: "PAN",
    PAN_PLACEHOLDER: "Enter PAN",
    SELECT_PLACEHOLDER: "Please Select",
    REG_LABEL: "Registration Type",
    PARTNER_LABEL: "Partner Type",
    ENTITY_LABEL: "Entity Type",
    BACK_LABEL: "Back",
    NEXT_LABEL: "Next",
    INDIVIDUAL_DETAILS: "Individual Details",
    DIRECTOR_DETAILS: "Director Details",
    ADDMORE_BUTTON_LABEL: "+ Add More Directors",
    KYC_DOCUMENTS: "KYC Documents",
    KYC_VERIFICATION_HEADING: "KYC Verification for Partner",
    KYC_VERIFICATION_COMPANY_HEADING: "KYC Verification for Company ",
    SUBMIT_LABEL: "Submit",
    SUBMITTED_SUCCESS_HEADING: "Application Submitted Successfully",
    SUBMITTED_CONNECT_BACK_TEXT: "Your application has submitted to us. We will connect back with you shortly!",
    SETTINGS:"Settings",
    PENDING:"Pending",
    APPROVED:"Approved",
    REJECTED:"Rejected",
    ALL:"All",
    VERIFY_KYC_HEADING:"Initiate KYC",
    VERIFY_CPV_HEADING:"Initiate CPV/FCU",
    PAYOUT_STRUCTURE:"Payout Structure",
    SANCTION:"Sanction",
    PAYOUT_HEADDING:"DSA Payout Slab",
    SANCTION_APPROVED:"Sanction 1 Approved By",
    COMMENT:"Comment",
    COMMENTVALUE:"Yes, you can try us for free for 30 days. If you want, we’ll provide you with a free, personalized 30-minute onboarding call to get you up and running as soon as possible.",
    ESIGN:"E-Sign",
    EMPLOYEE_MANAGEMENT:"Employee Management",
    ACCOUNT:"Account",
    EXPORT_PDF:"Export To PDF",
    APLLICATION_YEILD:"Application yield",
    PRODUCT_OUTPUT:"Product Output",
    PAYOUT_DETAILS:"Payout Details",
    LOAN_APLLICATION:"Loan Applications",
    SUMMARY:"Summary of loan applications and outcomes",
    SEEALL:"See All Transactions",
    OVERVIEW_DES:"Overview of payment Distributions",
    SEEALL_PAYOUT:"See All Payouts",
    LOAN_APPLICATION:"Loan Applications",
    DASHBOARD:"Dashboard",
    AMT_DIS:"Total application Amount vs disbursed count.",
    COUNT_DIS:"Total Application count vs disbursed amount.",
    PRODUCT_APP:"Product vs Applications",
    PRODUCT_DIS:"Product vs Disbursed",
    SANCTION_label:"Sanction 2",
    DASHBOARD_ROUTE:"dashboard",
    INITIATE_ROUTE:"initiate-kyc",
    INITIATE_CPV_ROUTE:"initiate-cpv",
    PAYOUT_ROUTE:"payout",
    SANCTION_ROUTE:"sanction",
    SANACTION_TWO_ROUTE:"sanction-2",
    ACCOUNT_ROUTE:"account",
    ESIGN_ROUTE:"e-sign",
    LOAN_ROUTE:"loan-application",
    PAYOUT_DETAILS_ROUTE:"payout-details",
    USER_NAME:"User Name",
    SCREEN_NAME:{
        APPLICATION:"application"
    }



}

