/** @jsxImportSource @emotion/react */
import DashboardLoyout from "../../components/DashbaordLayout/DashbaordLayout";
import { useEffect, useState } from "react";
import { StringConstants } from "../../constants/Strings";
import { EsignButtonSection, VeirfyMainHeadding, payoutMainSection, selectOutlinePayoutButton, selectPayoutButton } from "../ApplicationReview/InitiateKyc/InitiateKyc.style";
import CustomTable from "../../components/Table/Table";
import EsignDetails from "./EsignDetails";
import CustomButton from "../../components/Button/Button";
import CustomModal from "../../components/Modal/Modal";
import { css } from "@emotion/react";
import CustomSelect from "../../components/Select/Select";
import { regBackButton, regNextButton, regPanInput } from "../Registration/Registration.style";
import CustomInput from "../../components/Input/Input";
import { getApplicationDetailsGETApiUrl, getApplicationListPOSTApiUrl } from "../../apiManger/apiConstants";
import { get, post } from "../../apiManger/apiMethods";
import { setLocalStorage } from "../../utils/browserHandlers/browserStorageHandler";
import { formatDate } from "../ApplicationReview/InitiateKyc/InitiateKyc";
const columns = [
  { id: 'ApplicationNo', label: 'Application No', minWidth: 100 },
  { id: 'PartnerType', label: 'Partner Type', minWidth: 100 },
  { id: 'EntityType', label: 'Entity Type', minWidth: 100 },
  { id: 'PartnershipType', label: 'Partnership Type', minWidth: 100 },
  { id: 'Name', label: 'Name', minWidth: 100 },
  { id: 'MobileNo', label: 'Mobile No', minWidth: 100 },
  { id: 'Action', label: 'Action', minWidth: 100 },
];


const OtpVerifyModalSection = css`
display:flex;
gap:20px;
align-items: center;
`
const buttonSection = css`
display:flex;
justify-content:center;
gap:20px;
`
const Esign = () => {
  const { ESIGN } = StringConstants
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterByStatus, setFilterByStatus] = useState("Pending")
  const [showDetails, setShowDetails] = useState(false)
  const [filters, setFilters] = useState(true)
  const [search, setSearch] = useState("")
  const [filterByReg, setFilterByReg] = useState({
    startDate: "",
    endDate: "",
    partnerType: [],
    entityType: [],
    partnerShipType: []
  })
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [detailsData, setDetailsData] = useState();
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  console.log("filter logs", {
    status: filterByStatus,
    startDate: filterByReg.startDate,
    endDate: filterByReg.endDate,
    search: search,
    partnerType: filterByReg.partnerType,
    entityType: filterByReg.entityType,
    partnerShipType: filterByReg.partnerShipType
  })
 
  useEffect(() => {
    getAPICall();
  }, [page, rowsPerPage, filterByStatus, search, filterByReg]);

  const getAPICall = async () => {
    const reqObj = {
      startDate: filterByReg.startDate ? formatDate(filterByReg.startDate) : "",
      endDate: filterByReg.endDate ? formatDate(filterByReg.endDate) : "",
      pageNo: page,
      pageSize: rowsPerPage,
      search: search,
      partnerTypeValue: filterByReg.partnerType,
      entityTypeValue: filterByReg.entityType,
      partnershipTypeValue: filterByReg.partnerShipType,
      status:filterByStatus,
      stage:'esign'
    };
    const response = await post({
      url: getApplicationListPOSTApiUrl(),
      obj: reqObj
    });

    if (response?.result && response?.result?.data.length) {
      const array = response.result.data.map((item: any) => ({
       ApplicationNo: item.applicationNumber|| '',
        PartnerType: item.partnerSelection,
        EntityType: item.entityType,
        PartnershipType: item.partnershipType,
        Name: item.name || '',
        MobileNo: item.mobile,
        registrationId: item.id
      }));

      setData(array);
      setTotalRecords(response?.result?.totalRecords);
    } else {
      setData([]);
      setTotalRecords(0);
    }
  };
  const handleviewDetails = (rowData: any) => {
    fetchDetails(rowData)

  }
  const fetchDetails = async (rowData: any) => {
    const response = await get({
      url: getApplicationDetailsGETApiUrl(rowData.registrationId),

    });
    if (response?.result) {
      setLocalStorage('Application_registrationId',rowData.registrationId)

      setDetailsData(response.result)
      setShowDetails(rowData);
    }
  
  }
  return (
    <div>
      <DashboardLoyout>
        <div css={payoutMainSection}>
          <div css={VeirfyMainHeadding}>{ESIGN}</div>
          {/* {showDetails &&
            <div css={EsignButtonSection}>
              <CustomButton
                label="E-Sign Link"
                onClick={() => alert("clicked")}
                variant="outlined"
                css={selectOutlinePayoutButton}
              />
              <CustomButton
                label="E-Sign OTP Verification"
                onClick={handleOpen}
                css={selectPayoutButton}
              />
            </div>} */}
        </div>
        {!showDetails ?
          <CustomTable
            columns={columns}
            data={data}
            handleviewDetails={handleviewDetails}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setFilterByStatus={setFilterByStatus}
            filterByStatus={filterByStatus}
            filters={filters}
            filterByReg={filterByReg}
            setFilterByReg={setFilterByReg}
            setSearch={setSearch}
            search={search}
            setRowsPerPage={setRowsPerPage}
            totalRecords={totalRecords}

          />
          :
          <EsignDetails showDetails={showDetails} detailsData={detailsData} setShowDetails={setShowDetails}/>

        }
      </DashboardLoyout>
      <CustomModal
        open={open}
        onClose={handleClose}
        header="E-Sign"
      >

        <div css={OtpVerifyModalSection}>
          <div>
            <CustomSelect options={[]}
              placeholder="Please Select"
              onChange={(e) => alert("onchange")}
              inputLabel="Select User"
              mandatory={true}
              css={regPanInput}
            />
          </div>
          <div>
            <CustomButton
              label="Send OTP"
              onClick={() => alert("cliked")}
              css={regNextButton}

            />
          </div>
        </div>
        {[
          { label: 'Enter OTP (Ibad Shaikh)', name: 'userOtp', type: 'text', placeholder: 'Enter OTP (Ibad Shaikh)' },
          { label: 'Enter OTP (Aditya Sharma)', name: 'anotheruserOtp', type: 'text', placeholder: 'Enter OTP (Aditya Sharma)' },

        ].map((field) => (
          <CustomInput
            type={field.type}
            css={regPanInput}
            mandatory={true}
            placeholder={field.placeholder}
            inputLabel={field.label}
          />
        ))}
        <div css={buttonSection}>

          <CustomButton
            label="Confirm"
            onClick={handleClose}
            css={regNextButton}
          />
          <CustomButton
            label="Cancel"
            onClick={handleClose}
            variant="outlined"
            css={regBackButton}
          />
        </div>
      </CustomModal>
    </div>
  );
};

export default Esign;
