import './Layout.scss';
import Header from '../Header/Header';
import SubLayout from '../SubLayout/SubLayout';
import CustomStepper from '../Stepper/Stepper';
import { utilsConstants } from '../../utils/utilsConstants';
import { useEffect, useState } from 'react';
import { getLocalStorage } from '../../utils/browserHandlers/browserStorageHandler';

const Layout = (props: any) => {
    const { children } = props;
    const {STEPPER,STEPPER_INDIVIDUAL_DIRECTOR} = utilsConstants;
    const [stepper, setStepper] = useState(STEPPER)
    const mobile = window.innerWidth < 768;
    const [stepCount, setStepCount] = useState(1);
    const entityType = getLocalStorage('Entity Type');
    const partnershipTypeLS = getLocalStorage('Partnership Type');

    useEffect(() => {
        if (entityType === 'Individual') {
            setStepper(STEPPER.filter(step => step !== 'Company Details'));
        }
        else if (entityType === 'Business Entity') {
            if (partnershipTypeLS === "Sole Proprietorship") {
                setStepper(STEPPER)
            }
            else {
                setStepper(STEPPER_INDIVIDUAL_DIRECTOR);

            }
        }
    }, [entityType]);

    useEffect(() => {
        const currentStepIndex = determineCurrentStepIndex(stepper);
        setStepCount(currentStepIndex);
      }, [stepper]);

      const determineCurrentStepIndex = (steps:any) => {
        switch (window.location.pathname) {
          case '/register': return steps.indexOf('Registration');
          case '/individual-details': return steps.indexOf('Individual Details');
          case '/company-details': return steps.indexOf('Company Details');
          case '/bank-details': return steps.indexOf('Bank Details');
          case '/kyc-documents': return steps.indexOf('KYC Documents');
          case '/director-details': return steps.indexOf('Director Details');
          default: return 0;
        }
      };
    return (
        <div className='main-container'>
            <div>
                <Header />
                {mobile && (
                <div className='mobile-stepper'>
                  <CustomStepper steps={stepper} stepCount={stepCount} />
                </div>
            )}
                <SubLayout>
                    {children}
                </SubLayout>
            </div>
        </div>
    )
}


export default Layout;