import { icon } from "../../assets/Images";

const {overviewInactive,overviewActive,veirfyInactive,verifyActive,sactionActive,
  sactionInactive,esignActive,esignInactive,employeeInactive,employeeActive,PayoutInactive,PayoutActive,LoanInactive,LoanActive} = icon;
export const SideBarData: any = [
  {
    title: "Overview",
    path: "/dashboard",
    icon: overviewInactive,
    active_icon: overviewActive,
  },
  {
    title: "Application Review",
    path: "/initiate-kyc",
    icon: veirfyInactive,
    active_icon: verifyActive,
    subMenus: [
      {
        title: "Initiate KYC",
        path: "/initiate-kyc",
        icon: "",
      },
      {
        title: "Initiate CPV/FCU",
        path: "/initiate-kyc/initiate-cpv",
        icon: "",
      },
      {
        title: "Payout Structure",
        path: "/initiate-kyc/payout",
        icon: "",
      }
    ]
  },
  {
    title: "Sanction",
    path: "/sanction",
    icon: sactionInactive,
    active_icon: sactionActive,
    subMenus: [
      {
        title: "Sanction 1",
        path: "/sanction",
        icon: "",
      },
      {
        title: "Sanction 2",
        path: "/sanction-2",
        icon: "",
      },
    ]
  },
  {
    title: "E-Sign",
    path: "/e-sign",
    icon: esignInactive,
    active_icon: esignActive,
  },
  {
    title: "Empanelment",
    path: "/empanelment",
    icon: employeeInactive,
    active_icon: employeeActive,
  },
  
];

export const UserSideBarData : any = [
  {
    title: "Overview",
    path: "/dashboard",
    icon: overviewInactive,
    active_icon: overviewActive,
  },
 
  {
    title: "Loan Applications",
    path: "/loan-application",
    icon: LoanInactive,
    active_icon: LoanActive,
  },
];

