/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import CustomInput from "../../components/Input/Input";
import { regBackButton, regNextButton, regNextButtonDisabled, registrationButtonContainer } from "../Registration/Registration.style";
import { ValidName, getEighteenYearsAgoDate, getMinDate, isMobileValid, maskAadhaar, validEmail, validateAadhaar, validatePAN } from "../../constants/Validations";
import CustomSelect from "../../components/Select/Select";
import { StringConstants } from "../../constants/Strings";
import { registrationOtpContainer } from "../IndividualDetails/IndividualDetails";
import CustomButton from "../../components/Button/Button";
import { get, post } from "../../apiManger/apiMethods";
import { getProfileGETApiUrl, getProfilePOSTApiUrl } from "../../apiManger/apiConstants";
import Loder from "../../components/Loader/Loder";
import { ToastContainer } from 'react-toastify';
import { useToastHook } from "../../components/Toast/useToastHook";
import {  setLocalStorage } from "../../utils/browserHandlers/browserStorageHandler";
import { Box, Grid } from '@mui/material';
import { css } from '@emotion/react';
import { useNavigate } from "react-router-dom";

const Profile = (props:any) => {
    const navigate = useNavigate()
    const {onUpdate} = props;
    const {showToast} = useToastHook()
    const {SELECT_PLACEHOLDER,BACK_LABEL,NEXT_LABEL}  = StringConstants;
    const [individualDetails, setIndividialDetail] = useState<any>({
        firstName: "",
        lastName: "",
        mobileNo: "",
        emailId: "",
        gender:"",
        panNo: "",
        aadharNo: "", 
        registrationId:""
    })
    const [errors, setErrors] = useState({});
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
    const mobileNumber = "9123133048";
    const {
        showLoadingIndicator,
        hideLoadingIndicator,
        loadingComponent,
        visible,
      } = Loder();
    useEffect(() => {
        const fetchProfileData = async () => {
            showLoadingIndicator()
            try {
               
                    const response = await get({
                      url: getProfileGETApiUrl(mobileNumber)
                    });
                    if (response.result) {
                        hideLoadingIndicator()
                        setIndividialDetail({
                            firstName: response.result.firstName || "",
                            lastName: response.result.lastName || "",
                            mobileNo: response.result.mobileNumber || "",
                            emailId: response.result.emailId || "",
                            gender: response.result.gender || "",
                            panNo: response.result.panNumber || "",
                            aadharNo: response.result.aadharNumber || "",
                            dateOfBirth: response.result.dob || "",
                            registrationId:response.result.registrationId||""
                        });
                    }
                    else{
                        hideLoadingIndicator();
                    }
                  } catch (error) {
                    hideLoadingIndicator();
                    console.error("Error fetching product vs applications data:", error);
                  }
              
        
    }

        fetchProfileData();
    }, []);
    const validateForm = () => {
        for (let key in individualDetails) {
            if (!individualDetails[key]) {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {
        const isFormValid = Object.values(errors).every(error => error === null);

        if (validateForm() && isFormValid) {
            setIsNextButtonDisabled(false);
        } else {
            setIsNextButtonDisabled(true);
        }

    }, [individualDetails, errors]);

    const getValidator = (fieldName: any) => {
        switch (fieldName) {
            case 'firstName':
            case 'lastName':
                return ValidName;
            case 'mobileNo':
                return isMobileValid;
            case 'emailId':
                return validEmail;
            case 'panNo':
                return validatePAN
            case 'aadharNo':
                return validateAadhaar
           
            default:
                return () => null;
        }
    };

    const handleChange = (fieldName: any, value: any) => {
       
        setIndividialDetail({
            ...individualDetails,
            [fieldName]: value
        });
        const validatorFn = getValidator(fieldName);
        const error = validatorFn(value);
        setErrors({
            ...errors,
            [fieldName]: error
        });
    };
    const handleSave = async () => {
        showLoadingIndicator();
        try {
            const payload = {
                registrationId: individualDetails.registrationId,
                firstName: individualDetails.firstName,
                lastName: individualDetails.lastName,
                mobileNumber: individualDetails.mobileNo,
                emailId: individualDetails.emailId,
                gender: individualDetails.gender,
                panNumber: individualDetails.panNo,
                aadharNumber: individualDetails.aadharNo,
                dob: individualDetails.dateOfBirth,
                companyName: "",
                directorName: ""
            };
    
            const response = await post({
                url:getProfilePOSTApiUrl(),
                obj: payload
            });
    
            if (response?.result) {
                hideLoadingIndicator();
                onUpdate(); 
                showToast("Profile updated successfully!","success")
                setLocalStorage("userName",`${response.result.firstName} ${response.result.lastName}`)
            } else {
                hideLoadingIndicator();
                showToast("Failed to update profile!","error")
            }
        } catch (error) {
            hideLoadingIndicator()
            showToast("something went wrong","error")
        }
    };
    const gridContainerStyle = css`
    width: calc(100% + 30px);
    @media(max-width:768px){
  width: calc(100% + 20px);
 }
    `;
    const formControlStyle = css`
  margin-bottom: 16px;
   width: 100%;
`;
    return(
        <>
        {visible ? 
        <>{loadingComponent()}</> :
        <> 
        {/* <div css={registrationOtpContainer}> */}
        <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
                <Grid container spacing={2} css={gridContainerStyle}>
                <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="First Name"
                        value={individualDetails.firstName}
                        onChange={(e) => handleChange('firstName', e.target.value)}
                        type="text"
                        css={formControlStyle}
                        required
                        placeholder='Enter First Name'
                        mandatory={true}
                        validate={ValidName}
                        inputProps={{ maxLength: 26 }}
                    />
                     </Grid>
                      <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Last Name"
                        value={individualDetails.lastName}
                        onChange={(e) => handleChange('lastName', e.target.value)}
                        type="text"
                        css={formControlStyle}
                        required
                        placeholder='Enter Last Name'
                        mandatory={true}
                        validate={ValidName}
                        inputProps={{ maxLength: 26 }}
                    />
                       </Grid>
                      <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Mobile No."
                        value={individualDetails.mobileNo}
                        type="text"
                        css={formControlStyle}
                        required
                        placeholder='Enter Mobile No.'
                        mandatory={true}

                    />
                 </Grid>
                      <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Email ID"
                        value={individualDetails.emailId}
                        onChange={(e) => handleChange('emailId', e.target.value)}
                        type="text"
                        css={formControlStyle}
                        required
                        placeholder='Enter Email ID'
                        mandatory={true}
                        validate={validEmail}
                    />
                     </Grid>
                      <Grid item xs={12} sm={6} md={3} >
                    <CustomSelect options={[{ value: "Male", label: "Male" }, { value: "Female", label: "Female" }, { value: "Others", label: "Others" }]}
                        placeholder={SELECT_PLACEHOLDER}
                        value={individualDetails.gender}
                        onChange={(e) => handleChange('gender', e.target.value)}
                        inputLabel="Gender"
                        mandatory={true}
                        css={formControlStyle}
                    />
                   </Grid>
                      <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="PAN"
                        value={individualDetails.panNo}
                        onChange={(e) => handleChange("panNo", e.target.value)}
                        type="text"
                        css={formControlStyle}
                        required
                        placeholder='Enter PAN'
                        mandatory={true}
                        validate={validatePAN}
                    />
                      </Grid>
                      <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Aadhaar No"
                        value={maskAadhaar(individualDetails.aadharNo)}
                        onChange={(e) => handleChange('aadharNo', e.target.value)}
                        type="text"
                        css={formControlStyle}
                        required
                        placeholder='Enter Aadhaar No'
                        mandatory={true}
                        validate={validateAadhaar}
                    />
                       </Grid>
                      <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Date Of Birth"
                        value={individualDetails.dateOfBirth}
                        type="date"
                        css={formControlStyle}
                        onChange={(e) => handleChange('dateOfBirth', e.target.value)}
                        required
                        max={getEighteenYearsAgoDate()}
                        mandatory={true}
                    />
                     </Grid>
                     </Grid>
                     </Box>
        {/* </div> */}
        <div css={registrationButtonContainer}>
                    <CustomButton
                        label={BACK_LABEL}
                        onClick={() => navigate('/dashboard')}
                        variant="outlined"
                        css={regBackButton}
                    />
                    <CustomButton
                        label={'Save'}
                        onClick={() => handleSave()}
                        css={!isNextButtonDisabled ? regNextButton : regNextButtonDisabled}

                    />
                   
                </div>
                <ToastContainer />
                </>}
        </>
    )
}
export default Profile;