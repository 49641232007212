/** @jsxImportSource @emotion/react */
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import { css } from '@emotion/react';
import { colors, typographyInter } from '../../scss/colors';

const style = css `
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: auto;
  background-color: ${colors.white};
  box-shadow: 24;
  padding:15px 0px;
  border-radius:5px;
  overflow-y: auto;
    height: 100vh;
`

const headerStyle = css `
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${colors.stroke};
   height:45px;
    color: ${colors.lightBlack};
    ${typographyInter('medium', '16px')};
`
const headerStyleCenter = css `
  display: flex;
  justify-content: flex-end;
  align-items: center;
  border-bottom: 1px solid ${colors.stroke};
  gap: 35%;
  height:45px;
  color: ${colors.lightBlack};
    ${typographyInter('medium', '16px')};
`
const modalStyles = {
  zIndex: 900
  
};

const childrenSection = css`
overflow-y:auto;
`
const IconCss = css`
padding-right:20px;
`

const CustomModal = ({ open, onClose, header, children,isCenter=false }:any) => {
  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="modal-title"
      aria-describedby="modal-description"
      style={modalStyles}
      sx={{'.MuiModal-backdrop':{
        display:"none"
      }

      }}
    >
      <Box css={style}>
        <div>
        <div css={isCenter ? headerStyleCenter : headerStyle}>
          <div>
            {header}
          </div>
          <IconButton onClick={onClose} aria-label="close" css={IconCss}>
            <CloseIcon />
          </IconButton>
        </div>
        <Box css={childrenSection}>
          {children}
        </Box>
        </div>
      </Box>
    </Modal>
  );
};

export default CustomModal;
