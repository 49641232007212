
/** @jsxImportSource @emotion/react */
import { Link,  useLocation } from "react-router-dom";

import { colors,  typographyInter } from '../../scss/colors'; 
import { css } from '@emotion/react';
import {  useState } from "react";
import { icon } from "../../assets/Images";




const SideMenuContainer = css`
width: 100%;
justify-content: flex-start;
align-items: center;
margin-bottom: 20px; 
`
const SideMenuContainerSub = css`
width: 100%;
`

const SidebarPills = css`
display: block;
color: ${colors.lightBlack};
${typographyInter("400", "14px")}
text-decoration: none;
 `
const SidebarPilsActive = css`
display: block;
color: ${colors.crimson};
background-color: ${colors.lightred};
${typographyInter("600", "14px")};
border-radius:6px;
text-decoration: none;
@media (max-width: 768px) {
border-radius:0px;
${typographyInter("500", "14px")};
}
`


const Iconwrapper = css`
display:flex;
min-height: 32px;
align-items: center;
width:inherit;
`
const ImgSection = css`
width:20%;
text-align: center;
display: flex;
justify-content: center;
@media (max-width: 768px) {


}
`
const HeadingWrapper = css`
width:80%;
text-align: left;
cursor: pointer;
line-height:24px;
@media (max-width: 768px) {

}
`

const SubmenuWrapper = css`
width: 100%;
height: 32px;
color: $white;
display: flex;
justify-content: flex-start;
align-items: center;
${typographyInter("600", "13px")};
padding-left: 12px;
`
const SubheadingWrapper = css`
text-align: left;
color:${colors.lightBlack};
${typographyInter("400", "14px")};
margin-left:30px;
@media (max-width: 768px) {
    margin-left:34px;
  }
`
const SubmenuActive = css`
color:${colors.crimson};
margin-left:30px;
${typographyInter("400", "14px")};
 @media (max-width: 768px) {
    margin-left:34px;
  }
`
const SubmenuDecorative = css`
text-decoration: none;
`
const arrowactiveicon = css`
position: absolute;
right: 22px;
 @media (max-width: 768px) {
    right:10px;
    
  }
`

const arrowInactiveicon = css`
position: absolute;
right: 22px;
padding-top:5px;
 @media (max-width: 768px) {
    right:10px;
    
  }
`

const roatateIcon = css`
transform: rotate(-90deg);
transition: transform 0.5s linear;
`
const submenuMainSection = css`
margin-bottom:10px;
`

const SideMenuMainSection = css`
margin-bottom:20px;
`
const SideMenu = (props:any) => {
const {item,index} = props;
const location = useLocation();
const [subnav, setSubnav] = useState(true);
const showSubnav = () => setSubnav(!subnav);
  return (
    <>
        <div css={location.pathname.includes(item.path) && item.subMenus && subnav? SideMenuContainerSub : location.pathname.includes(item.path) && !subnav ?  SideMenuMainSection :SideMenuContainer}> 
          <Link
            to={item.path}
            key={index}
            onClick={item.subMenus && showSubnav}
            css={location.pathname.includes(item.path)
                ? SidebarPilsActive
                : SidebarPills
            }
          >
            <div css={Iconwrapper}>
              <div css={ImgSection}>
              <img
                src={
                  location.pathname.includes(item.path)
                    ? `${item.active_icon}`
                    : `${item.icon}`
                }
                alt="no_icons"
               
              />
              </div>
               <div css={HeadingWrapper}>&nbsp;{item.title}</div>
               {item.subMenus ? (
              <div css={location.pathname.includes(item.path) ? arrowactiveicon : arrowInactiveicon}>
                <img
                  src={
                    location.pathname.includes(item.path)
                      ? icon.sidebararrow
                      : icon.inactiveSidebarArrow
                  }
                  css={subnav ? "" : roatateIcon}
                  alt="no_img"
                />
              </div>
            ) : null}
            </div>
           
          </Link>
        </div>
     
        {subnav && location.pathname.includes(item.path) && (
        <>
          <div  css={submenuMainSection}>
          {item.subMenus?.map((ele:any, index:any) => {
            return (
              <Link to={ele.path} key={index} css={SubmenuDecorative}>
                <div css={SubmenuWrapper}>
                  <div
                    css={
                      ele.path === location.pathname
                        ? SubmenuActive
                        : SubheadingWrapper
                    }
                  >
                    {ele.title}
                  </div>
                </div>
              </Link>
             
            );
          })}
           </div>
       </>
      )}
    </>
  );
};

export default SideMenu;
