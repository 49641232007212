/** @jsxImportSource @emotion/react */
import { getDocumentApiUrl } from '../../apiManger/apiConstants';
import { get } from '../../apiManger/apiMethods';
import { getLocalStorage } from '../../utils/browserHandlers/browserStorageHandler';
import { previewSection } from '../ApplicationReview/InitiateKyc/KycDetails';

const PreviewComponent = ({ file }: { file: any }) => {

    const fetchPreview = async () => {
        try {
          const url = getDocumentApiUrl(file);
          const token = getLocalStorage("access_token");
      
          if (!token) {
            console.error("Authorization token is missing.");
            return;
          }
      
          const headers = {
            Authorization: `Bearer ${token}`,
          };
      
          const response = await fetch(url, { headers });
      
          if (response.ok) {
            const blob = await response.blob();
            const blobUrl = window.URL.createObjectURL(blob);
            window.open(blobUrl, '_blank');
          } else {
            const errorData = await response.json();
            console.error("Error fetching preview:", errorData);
          }
        } catch (error) {
          console.error('Error fetching preview:', error);
        }
      };
      
      

    return (
      <div onClick={() => fetchPreview()} css={previewSection}>
        Preview
      </div>
    );

};

export default PreviewComponent;
