import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';

const AreaChart = (props:any) => {
  const {colors, data} = props;
  const chartRef = useRef(null);

  useEffect(() => {
    const options = {
      series: [{
        name: 'Application Amount',
        data: data.series1 || [] 
      }, {
        name: 'Disbursed Amount',
        data: data.series2 || [] 
      }],
      chart: {
        width:'100%',
        height: 300,
        type: 'area',
        toolbar: {
            show: false 
          }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        curve: 'smooth'
      },
      colors: colors,
      xaxis: {
        categories: data.categories || [],
        labels: {
          formatter: function (value:any) {
            return value;
          },
          style: {
            colors: '#84919A',
            fontSize: '11px',
            fontFamily:'Inter',
            fontWeight:'500'
          }
        }
      },
      yaxis: {
        show: false 
      },
      tooltip: {
        x: {
          format: 'MMM yyyy' 
        },
        y: {
          formatter: function (val:any) {
            return `₹${val.toLocaleString('en-IN')}`; 
          }
        }
      },
      fill: {
        type: 'solid',
        colors: ['#F2FAF9']
      },
      legend: {
        show: false
      }
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data]);

  return <div id="chart" ref={chartRef} style={{width:"100%"}}></div>;
};

export default AreaChart;
