import React, { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const useToastHook = () => {
  const showToast = (message:any, type:any) => {
    if (type == "success") {
      toast.success(message, {
        autoClose: 2000,
        position: "top-right",
        hideProgressBar: true,
        className: "text-capitalize bg-success-subtle text-white bg-dark",
        pauseOnHover: false,
      });
    }

    if (type == "warning") {
      toast.warn(message, {
        autoClose: 2000,
        position: "top-right",
        hideProgressBar: true,
        className: "text-capitalize text-white bg-dark",
        pauseOnHover: false,
      });
    }

    if (type == "error") {
      toast.error(message, {
        autoClose: 2000,
        position: "top-right",
        hideProgressBar: true,
        className: "text-capitalize text-white bg-dark",
        pauseOnHover: false,
      });
    }
  };

  return { showToast };
};
