export const colors = {
    white: '#ffffff',
    black: '#000000',
    error: '#ba0000',
    success: '#76ca66',
    gray: '#808080',
    charcoal: '#464646',
    crimson: '#e12c36',
    monarch: '#ff8d23',
    denim: '#0079ea',
    benchmark: '#009587',
    cottonBall: '#f2f8fe',
    buttonOrange:'#e8273e',
    inputBorder:'#B8B8B8',
    lightGray:'#7E7E7E',
    lightred:'#F6C0C3',
    browseText:"#ECECEC",
    lightBlue:"#F2FAF9",
    lightBlack:"#252C32",
    poppypower:"#E8273E",
    saltboxblue:"#64748B",
    coalmine:"#52525B",
    narwhalgrey:"#090914",
    washme:"#F8FAFC",
    bloodburst:"#ff4b4b",
    bluereflection:"#cbd5e1",
    redpower:"#d73c3c",
    pearl:"#f8f8f8",
    steam:"#DDDDDD",
    blueIce:"#737F9E",
    coarseWool:"#161C25",
    black08:"rgba(0, 0, 0, 0.08)",
    tableGray:"#6E7C87",
    tableFont:"#7E8299",
    tableLabel:"#464B4F",
    stroke:"#D0D5DD",
    dropdownToggel:"#f5f5f5",
    placeholder:"#84919A",
    tableHeader:"#F9FAFB",
    green:"#22C55E",
    lightGreen:'#DCFCE7',
    lightRed:'#F6C0C3',
    darkGreen:"#14532D",
    lightYellow:"#FEF9C3",
    yellow:"#713F12",
    darkYellow:"#FACC15",
    darkBlack:"#0D0D0D",
    filterLabel:"#E0E6EB"
  };
  

const weights:any = {
    thin: 100,
    'extra-light': 200,
    'ultra-light': 200,
    light: 300,
    normal: 400,
    book: 400,
    regular: 400,
    medium: 500,
    'semi-bold': 600,
    'demi-bold': 600,
    bold: 700,
    'extra-bold': 800,
    'ultra-bold': 900,
    heavy: 900,
    black: 900,
    ultra: 900,
    'ultra-black': 900,
    'extra-ultra': 900,
  };
  
  const returnWeight = (weight: string | number) => {
    return weights[weight] || weight;
  };
  
  export const typography = (weight: string, size: string) => `
    font-family: 'Plus Jakarta Sans', sans-serif;
    font-weight: ${returnWeight(weight)};
    font-size: ${size};
  `;

  export const typographyInter = (weight: string, size: string) => `
    font-family: 'Inter';
    font-weight: ${returnWeight(weight)};
    font-size: ${size};
  `;