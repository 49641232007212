import React from 'react';
import { TextField, InputAdornment } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { styled } from '@mui/system';
import { colors } from '../../scss/colors';

const SearchInput = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-root': {
    border: `0px solid ${colors.stroke}`,
    borderRadius: 8,
    padding: '5px 10px',
    height: '42px',
    '& fieldset': {
      borderColor: colors.stroke,
    },
    '&:hover fieldset': {
      borderColor:colors.stroke,
    },
    '&.Mui-focused fieldset': {
      borderColor:colors.stroke,
    },
  },
}));

const CustomSearch = (props:any) => {
  const {setSearch,search,setPage}= props;
  const handleChange =(e:any)=>{
    setSearch(e.target.value)
    setPage(0)
  }
  return (
    <SearchInput
      variant="outlined"
      placeholder="Mobile number..."
      value={search}
      onChange={(e) =>handleChange(e)}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        ),
      }}
      fullWidth
    />
  );
};

export default CustomSearch;
