import './SubLayout.scss';
import styled from '@emotion/styled';
import {colors} from '../../scss/colors';

export const SubContainer = styled.div`
background-color: ${colors.white};
border-radius: 7px;
width: 80%;
display: inline-block;
padding: 20px;
margin-bottom:40px;
`

const SubLayout = (props: any) => {
    const { children } = props;
    return (
        <SubContainer>
            {children}
        </SubContainer>
    )
}


export default SubLayout;