/** @jsxImportSource @emotion/react */
import CustomSelect from "../../components/Select/Select";
import { utilsConstants } from "../../utils/utilsConstants";
import { StringConstants } from "../../constants/Strings";
import CustomDateSelect from "../CustomeDateSelect/CustomeDateSelect";
import { css } from '@emotion/react';
import { colors } from "../../scss/colors";
import { getLocalStorage } from "../../utils/browserHandlers/browserStorageHandler";

 const FilterTypesSection = css`
display: flex;
justify-content: flex-start;
flex-wrap: wrap;
justify-content:space-between;
width:100%;
`
const SelectDropdown = css`
 width:240px ;
 height: 40px;
 color:${colors.placeholder};
`

const Filters = (props: any) => {
    const role = getLocalStorage("role")
   const { filterByReg,setFilterByReg} = props;
    const { PartnerTypeOptions, PartnerShipTypeOptions, EntityTypeOptions } = utilsConstants;
    const { SELECT_PLACEHOLDER, REGISTER_TYPE, PARTNER_TYPE, PARTNER_LABEL,  ENTITY_TYPE, ENTITY_LABEL, PARTNERSHIP_TYPE } = StringConstants;

    const handleSelectChange = (event: any, type: any) => {
        const value = event.target.value;
        if (type === PARTNER_TYPE) {
            setFilterByReg({
                ...filterByReg,
                partnerType:value,
                 })
        } else if (type === ENTITY_TYPE) {
            setFilterByReg({
                ...filterByReg,
                entityType:value,
                 })
        } else if (type === PARTNERSHIP_TYPE) {
            setFilterByReg({
                ...filterByReg,
                partnerShipType:value,
                 })
        }
    };
    const loanApllication = window.location.pathname
    return (
            <div css={FilterTypesSection}>
                <CustomDateSelect 
                    placeholder={SELECT_PLACEHOLDER}
                    onChange={(e:any) => handleSelectChange(e, REGISTER_TYPE)}
                    inputLabel="Date Range"
                    mandatory={true}
                    css={SelectDropdown}
                    setFilterByReg={setFilterByReg}
                    filterByReg={filterByReg}
                />
                {loanApllication?.includes('/loan-application') ? <>
                    <CustomSelect options={[{value:"Bangalore",label:"Bangalore"}]}
                checkBox={true}
                    placeholder={SELECT_PLACEHOLDER}
                    value={filterByReg.partnerType}
                    onChange={(e:any) => handleSelectChange(e, PARTNER_TYPE)}
                    inputLabel="Location"
                    mandatory={true}
                    css={SelectDropdown}
                /> 
                <CustomSelect  options={[{value:"1",label:"1"}]}
                checkBox={true}
                    placeholder={SELECT_PLACEHOLDER}
                    value={filterByReg.partnerType}
                    onChange={(e:any) => handleSelectChange(e, PARTNER_TYPE)}
                    inputLabel="Stage"
                    mandatory={true}
                    css={SelectDropdown}
                />

                <CustomSelect  options={[{value:"Ev",label:"Ev"}]}
                    checkBox={true}
                    placeholder={SELECT_PLACEHOLDER}
                    value={filterByReg.entityType}
                    onChange={(e:any) => handleSelectChange(e, ENTITY_TYPE)}
                    inputLabel="Product"
                    mandatory={true}
                    css={SelectDropdown}
                />
                </>:
                <>
                <CustomSelect options={PartnerTypeOptions}
                checkBox={true}
                    placeholder={SELECT_PLACEHOLDER}
                    value={filterByReg.partnerType}
                    onChange={(e:any) => handleSelectChange(e, PARTNER_TYPE)}
                    inputLabel={PARTNER_LABEL}
                    mandatory={true}
                    css={SelectDropdown}
                />

                <CustomSelect options={EntityTypeOptions}
                    checkBox={true}
                    placeholder={SELECT_PLACEHOLDER}
                    value={filterByReg.entityType}
                    onChange={(e:any) => handleSelectChange(e, ENTITY_TYPE)}
                    inputLabel={ENTITY_LABEL}
                    mandatory={true}
                    css={SelectDropdown}
                />
                <CustomSelect options={PartnerShipTypeOptions}
                    checkBox={true}
                    placeholder={SELECT_PLACEHOLDER}
                    value={filterByReg.partnerShipType}
                    onChange={(e:any) => handleSelectChange(e, PARTNERSHIP_TYPE)}
                    inputLabel="Partnership Type"
                    mandatory={true}
                    css={SelectDropdown}
                />
                </>
}
            </div>
    )
}
export default Filters;