/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import CustomInput from '../../../components/Input/Input';
import CustomButton from '../../../components/Button/Button';
import { colors, typography } from '../../../scss/colors';
import { css } from '@emotion/react';
import { useNavigate } from 'react-router-dom';
import { regBackButton, regNextButton, regNextButtonDisabled, registrationButtonContainer } from '../../Registration/Registration.style';
import { StringConstants } from '../../../constants/Strings';
import CustomSelect from '../../../components/Select/Select';
import { ValidName, getEighteenYearsAgoDate, isMobileValid, maskAadhaar, removeAadhaarMask, validEmail, validateAadhaar, validatePAN, validatePostalCode, whiteSpaceValid } from '../../../constants/Validations';
import { get, post, put } from '../../../apiManger/apiMethods';
import { getIndividualGETApiUrl, getIndividualPostApiUrl, getStateGETApiUrl } from '../../../apiManger/apiConstants';
import { getLocalStorage, setLocalStorage } from '../../../utils/browserHandlers/browserStorageHandler';
import Loder from '../../../components/Loader/Loder';
import { Box, Grid } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { useToastHook } from '../../../components/Toast/useToastHook';



export const registrationOtpContainer = css`
display: flex;
align-items: flex-start;
flex-wrap:wrap;
justify-content:space-between;
            `
const indiviualDetailsContainer = css`
            display: flex;
            align-items: flex-start;
            flex-wrap:wrap;
            justify-content:space-between;
            width:74%;
                        `
const regPanInput = css`
 width:220px ;
 height: 40px;
 margin-bottom:30px;
            `
const IndividualDetails = (props: any) => {
    const { setValue, details, registrationId } = props;
    const {showToast} = useToastHook()
    const [aadhar,setAadhar] = useState(details?.aadhar ? details?.aadhar :"")
    const [individualDetails, setIndividialDetail] = useState<any>({
        firstName: details?.firstName,
        lastName: details?.lastName,
        mobileNo: details?.mobile,
        emailId: details?.emailId,
        gender: details?.gender,
        panNo: details?.pan,
        aadharNo: details?.aadhar,
        dateOfBirth: details?.dob,
        addressLine1: details?.addressLineOne,
        addressLine2: details?.addressLineTwo,
        addressLine3: details?.addressLineThree,
        landmark: details?.landmark,
        postCode: details?.postalCode,
        city: details?.city,
        state: details?.state,

    })
    const [errors, setErrors] = useState({});
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
    const [isFormChanged, setIsFormChanged] = useState(false)
    const [isAlreadySubmitted, setIsAlreadySubmitted] = useState<any>('')
    const { BACK_LABEL, NEXT_LABEL, SELECT_PLACEHOLDER, INDIVIDUAL_DETAILS } = StringConstants;
    // const registrationId = getLocalStorage('registrationId');
    const individual_id = getLocalStorage('individual_id');
    const {
        showLoadingIndicator,
        hideLoadingIndicator,
        loadingComponent,
        visible,
    } = Loder();
    useEffect(() => {
        getStateList()
        if (details?.id) {
            setIsAlreadySubmitted(true)
        }
        if (individual_id != undefined) {
            getAPICall();
        }
    }, [])

    const getAPICall = async () => {
        showLoadingIndicator();
        const response = await get({
            url: getIndividualGETApiUrl(registrationId, individual_id)
        });
        if (response?.result) {
            hideLoadingIndicator();
            const { firstName, lastName, mobile, pan, emailId, gender, aadhar, dob, addressLineOne, addressLineTwo, addressLineThree, landmark, postalCode, city, state } = response.result
            setIndividialDetail({
                firstName: firstName,
                lastName: lastName,
                mobileNo: mobile,
                emailId: emailId,
                gender: gender,
                panNo: pan,
                aadharNo: aadhar,
                dateOfBirth: dob,
                addressLine1: addressLineOne,
                addressLine2: addressLineTwo,
                addressLine3: addressLineThree,
                landmark: landmark,
                postCode: postalCode,
                city: city,
                state: state,
            });
            setAadhar(aadhar)
            setIsAlreadySubmitted(true)
        }
        else {
            hideLoadingIndicator()
        }
    }
    const [stateList, setStateList] = useState([])

    const getStateList = async () => {
        const response = await get({
            url: getStateGETApiUrl()
        });
        if (response?.result) {
            setStateList((response?.result?.map((state: any) => ({ label: state.state, value: state.id }))))
        }
    }
    const handleNext = async () => {
        showLoadingIndicator();
        const reqObj = {
            registrationId: registrationId,
            firstName: individualDetails.firstName,
            lastName: individualDetails.lastName,
            mobile: individualDetails.mobileNo,
            emailId: individualDetails.emailId,
            gender: individualDetails.gender,
            pan: individualDetails.panNo,
            aadhar: individualDetails.aadharNo,
            dob: individualDetails.dateOfBirth,
            addressLineOne: individualDetails.addressLine1,
            addressLineTwo: individualDetails.addressLine2,
            addressLineThree: individualDetails.addressLine3,
            landmark: individualDetails.landmark,
            postalCode: individualDetails.postCode,
            city: individualDetails.city,
            state: individualDetails.state,
            status: 1
        };

        try {
            let response;
                if (isAlreadySubmitted === "") {
                    response = await post({
                        url: getIndividualPostApiUrl(registrationId),
                        obj: reqObj
                    });
                }
                else if (isAlreadySubmitted === true) {
                    response = await post({
                        url: getIndividualPostApiUrl(registrationId),
                        obj: { ...reqObj, "id" : individual_id ? individual_id : details?.id?details?.id:"" }
                    });
                }
            if (response.result) {
                hideLoadingIndicator()
                setLocalStorage('individual_id', response.result.id)

            }
            else {
                hideLoadingIndicator()
            }
            if(isFormChanged && response?.result){
                hideLoadingIndicator()

                if(getLocalStorage("Application_EntityType")?.includes("Business") && getLocalStorage('Application_PartnershipType')?.includes('Sole')){
                    setValue(3)
                }else{
                setValue(2)
                }
              }else if(!isFormChanged){
                hideLoadingIndicator()

                if(getLocalStorage("Application_EntityType")?.includes("Business") && getLocalStorage('Application_PartnershipType')?.includes('Sole')){
                    setValue(3)
                }else{
                setValue(2)
                }
              }else{
                hideLoadingIndicator()

                showToast("something went wrong","error")
              }

        } catch (error) {
            hideLoadingIndicator()

            showToast("something went wrong","error")
        }
        
    };

    const handleBack = () => {
        setValue((pre: any) => pre - 1)
    };




    const validateForm = () => {
        for (let key in individualDetails) {
            if (key !== "addressLine2" && key !== "landmark" && key !== "addressLine3" && !individualDetails[key]) {
                return false;
            }
        }
        return true;
    };

    useEffect(() => {
        const isFormValid = Object.values(errors).every(error => error === null);
        if (validateForm() && isFormValid) {
            setIsNextButtonDisabled(false);
        } else {
            setIsNextButtonDisabled(true);
        }

        getStateList()
    }, [individualDetails, errors]);
    useEffect(() => {
        const validatorFn = getValidator(individualDetails.aadharNo);
        const error = validatorFn(individualDetails.aadharNo);

        setErrors({
            ...errors,
            aadharNo: error
        });

    }, [individualDetails.aadharNo])
    const getValidator = (fieldName: any) => {
        switch (fieldName) {
            case 'firstName':
            case 'lastName':
                return ValidName;
            case 'mobileNo':
                return isMobileValid;
            case 'emailId':
                return validEmail;
            case 'panNo':
                return validatePAN
            case 'aadharNo':
                return validateAadhaar
            case 'addressLine1':
            case 'addressLine2':
            case 'addressLine3':
            case 'landmark':
            case 'city':
                return whiteSpaceValid
            case 'postCode':
                return validatePostalCode
            default:
                return () => null;
        }
    };

    const handleChange = (fieldName: any, value: any) => {
        setIsFormChanged(true)
        if (fieldName === 'aadharNo') {
            const unmaskedValue = removeAadhaarMask(value);
            setAadhar(unmaskedValue); 
    
            setIndividialDetail({
                ...individualDetails,
                [fieldName]: unmaskedValue
            });
        }else{
        setIndividialDetail({
            ...individualDetails,
            [fieldName]: value
        });
    }
        const validatorFn = getValidator(fieldName);
        const error = validatorFn(value);
        setErrors({
            ...errors,
            [fieldName]: error
        });
    };
    const gridContainerStyle = css`
    width: calc(100% + 30px);
    @media(max-width:768px){
  width: calc(100% + 20px);
 }
    `;
    const formControlStyle = css`
  margin-bottom: 16px;
   width: 100%;
`;
    return (
            <div>
                {/* <div css={registrationOtpContainer}> */}
                {visible ? 
        <>{loadingComponent()}</> :
        <>     <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
                <Grid container spacing={2} css={gridContainerStyle}>
                <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="First Name"
                        value={individualDetails.firstName}
                        onChange={(e) => handleChange('firstName', e.target.value)}
                        type="text"
                        css={formControlStyle}
                        required
                        placeholder='Enter First Name'
                        mandatory={true}
                        validate={ValidName}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Last Name"
                        value={individualDetails.lastName}
                        onChange={(e) => handleChange('lastName', e.target.value)}
                        type="text"
                        css={formControlStyle}
                        required
                        placeholder='Enter Last Name'
                        mandatory={true}
                        validate={ValidName}
                    />
                         </Grid>
                         <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Mobile No."
                        value={individualDetails.mobileNo}
                        type="text"
                        css={formControlStyle}
                        required
                        placeholder='Enter Mobile No'
                        mandatory={true}
                        onChange={(e) => handleChange('mobileNo', e.target.value)}
                        validate={isMobileValid}
                    />
                         </Grid>
                         <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Email ID"
                        value={individualDetails.emailId}
                        onChange={(e) => handleChange('emailId', e.target.value)}
                        type="text"
                        css={formControlStyle}
                        required
                        placeholder='Enter Email ID'
                        validate={validEmail}
                        mandatory={true}
                    />
                         </Grid>
                         <Grid item xs={12} sm={6} md={3} >
                    <CustomSelect options={[{ value: "Male", label: "Male" }, { value: "Female", label: "Female" }, { value: "Others", label: "Others" }]}
                        placeholder={SELECT_PLACEHOLDER}
                        value={individualDetails.gender}
                        onChange={(e) => handleChange('gender', e.target.value)}
                        inputLabel="Gender"
                        mandatory={true}
                        css={formControlStyle}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="PAN"
                        value={individualDetails.panNo}
                        onChange={(e) => handleChange("panNo", e.target.value.toUpperCase())}
                        type="text"
                        css={formControlStyle}
                        required
                        placeholder='Enter PAN'
                        mandatory={true}
                        validate={validatePAN}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Aadhaar No"
                        value={maskAadhaar(aadhar)}
                        onChange={(e) => handleChange('aadharNo', e.target.value)}
                        type="text"
                        css={formControlStyle}
                        required
                        placeholder='Enter Aadhaar No'
                        mandatory={true}
                        validate={validateAadhaar}
                        maskValue={true}
                        max={12}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Date Of Birth"
                        value={individualDetails.dateOfBirth}
                        type="date"
                        css={formControlStyle}
                        onChange={(e) => handleChange('dateOfBirth', e.target.value)}
                        required
                        max={getEighteenYearsAgoDate()}
                        mandatory={true}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Address Line 1"
                        value={individualDetails.addressLine1}
                        onChange={(e) => handleChange('addressLine1', e.target.value)}
                        placeholder='Enter Address'
                        type="text"
                        css={formControlStyle}
                        required
                        mandatory={true}
                        validate={whiteSpaceValid}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Address Line 2"
                        value={individualDetails.addressLine2}
                        onChange={(e) => handleChange('addressLine2', e.target.value)}
                        placeholder='Enter Address'
                        type="text"
                        css={formControlStyle}
                        required
                        validate={whiteSpaceValid}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Address Line 3"
                        value={individualDetails.addressLine3}
                        onChange={(e) => handleChange('addressLine3', e.target.value)}
                        placeholder='Enter Address'
                        type="text"
                        css={formControlStyle}
                        required
                        validate={whiteSpaceValid}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Landmark"
                        value={individualDetails.landmark}
                        onChange={(e) => handleChange('landmark', e.target.value)}
                        placeholder='Enter Landmark'
                        type="text"
                        css={formControlStyle}
                        required
                        validate={whiteSpaceValid}
                    />
                    </Grid>
                {/* </div> */}
                {/* <div css={indiviualDetailsContainer}> */}
                <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="Postal Code"
                        value={individualDetails.postCode}
                        onChange={(e) => handleChange('postCode', e.target.value)}
                        placeholder='Enter Postal Code'
                        type="text"
                        css={formControlStyle}
                        required
                        mandatory={true}
                        validate={validatePostalCode}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                    <CustomInput
                        inputLabel="City / Town"
                        value={individualDetails.city}
                        onChange={(e) => handleChange('city', e.target.value)}
                        placeholder='Enter City/Town'
                        type="text"
                        css={formControlStyle}
                        required
                        mandatory={true}
                        validate={whiteSpaceValid}
                    />
                    </Grid>
                    <Grid item xs={12} sm={6} md={3} >
                    <CustomSelect options={stateList}
                        placeholder={SELECT_PLACEHOLDER}
                        value={individualDetails.state}
                        onChange={(e) => handleChange('state', e.target.value)}
                        inputLabel="State"
                        mandatory={true}
                        css={formControlStyle}
                    />
                    </Grid>
                {/* </div> */}
                  </Grid>
                  </Box>
            {/* // </div> */}

            <div css={registrationButtonContainer}>
                <CustomButton
                    label={BACK_LABEL}
                    onClick={handleBack}
                    variant="outlined"
                    css={regBackButton}
                />
                <CustomButton
                    label={NEXT_LABEL}
                    onClick={handleNext}
                    css={!isNextButtonDisabled ? regNextButton : regNextButtonDisabled}

                />
            </div>
            <ToastContainer />
        </>}
        </div>
    )
}


export default IndividualDetails;