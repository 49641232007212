import React, { FC } from 'react';
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

interface CustomButtonProps {
  label: string;
  onClick?: () => void;
  variant?: 'text' | 'outlined' | 'contained';
  customStyles?: React.CSSProperties;
  className?: string; 
  disabled?:boolean;
}

const CustomButtonStyle = styled(Button)(({ theme }) => ({
  backgroundColor: '#1976d2',
  color: '#ffffff',
  '&:hover': {
    backgroundColor: '#115293',
  },
  padding: 0,
  borderRadius: '8px',
  fontSize: '1rem',
  textTransform: 'none',
}));

const CustomButton: FC<CustomButtonProps> = ({ label, onClick, variant = 'contained', customStyles,className,disabled  }) => {
  return (
    <CustomButtonStyle
      onClick={onClick}
      variant={variant}
      style={customStyles}
      className={className}
      disabled={disabled}
    >
      {label}
    </CustomButtonStyle>
  );
};

export default CustomButton;
