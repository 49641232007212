import './Header.scss'
import { StringConstants } from '../../constants/Strings';

const Header = () => {
    const { Header_Strings: { header_main, company_name, header_tagline } } = StringConstants;
    return (
        <div className='header-main-container'>
            <div className='header-main-text'>
                {header_main} <span className='header-company-name'>{company_name}</span>
            </div>
            <div className='header-tagline-text'>{header_tagline}</div>
        </div>
    );
};

export default Header;
