import Checkbox from '@mui/material/Checkbox';
import { ChangeEvent, useEffect, useState } from 'react';
import { colors } from '../../scss/colors';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';

const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

interface Option {
  value: string;
  label: string;
}

interface ColorCheckboxesProps {
  options: Option[];
  onChange?: (checkedItems: string[]) => void;
  value?: string[];
}

export default function MultiCheckBox({ options, onChange,value = []}: ColorCheckboxesProps) {
  const [checkedItems, setCheckedItems] = useState<string[]>(value);

  useEffect(() => {
    setCheckedItems(value);
  }, [value]);
  
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = event.target;
    setCheckedItems((prev) => {
      const newCheckedItems = checked ? [...prev, name] : prev.filter((item) => item !== name);
      if (onChange) {
        onChange(newCheckedItems);
      }
      return newCheckedItems;
    });
  };

  return (
    <FormGroup>
      {options.map((option) => (
        <FormControlLabel
          key={option?.value}
          control={
            <Checkbox
              {...label}
              checked={checkedItems.includes(option?.value)}
              onChange={handleChange}
              name={option?.value}
              sx={{
                color: colors.crimson,
                '&.Mui-checked': {
                  color: colors.crimson,
                },
                '&.MuiCheckbox-root': {
                  marginLeft: '0px',
                  marginRight: '0px'
                },
              }}
            />
          }
          label={option.label}
        />
      ))}
    </FormGroup>
  );
}
