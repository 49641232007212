/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { colors, typography } from '../../scss/colors';

export const headerStyles = css`
  text-align: left;
  padding: 20px 0px;
  ${typography('700', '36px')};
`;

export const sectionTitleStyles = (color: any) => css`
  display: flex;
  padding-bottom: 5px;
  ${typography('bold', '16px')};
  color:${colors.coarseWool};
  & span {
    border: 1px solid ${color};
    border-radius: 50%;
    background: ${color};
    color: white;
    padding: 2px 8px;
    margin-left:20px;
    ${typography('bold', '12px')};
  }
`;

export const sectionContainerStyles = css`
  background: ${colors.pearl};
  padding: 20px;
  border-radius: 5px;
  height: 100%;
`;

export const cardStyles = css`
  margin-bottom: 10px;
  height: 160px;
  border: 1px solid ${colors.steam};
  box-shadow: 0px 2px 4px 0px ${colors.black08};


`;

export const itemTitleStyles = css`
  text-align: left;
  padding: 5px 0px;
  ${typography('bold', '16px')};
  color:${colors.coarseWool};
`;

export const itemDateStyles = css`
  text-align: left;
  padding: 5px 0px;
  ${typography('regular', '12px')};
color:${colors.blueIce};
`;

export const itemUpdatedStyles = css`
  text-align: left;
  padding: 5px 0px;
  ${typography('regular', '12px')};
  color:${colors.blueIce}
`;

export const avatarGroupStyles = css`
  margin-top: 0px;
  .MuiAvatar-root {
    margin-left: 0 !important;
    height: 18px;
    width: 18px;
    font-size: 10px;
  }
`;

export const itemInfoStyles = css`
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  align-items: baseline;
`;

export const itemInfoDivStyles = css`
  padding: 0px 10px 0px 0px;
  color:${colors.blueIce};
  ${typography('bold', '12px')};
`;

export const overviewStyles = css`
  padding: 0px 20px 20px 20px;
`;

export const gridItemStyles = css`
  display: flex;
  flex-direction: column;
`;

export const gridContainerStyles = css`
  align-items: stretch;
`;

export const boxMarginBottomStyles = css`
  margin-bottom: 10px;
`;

export const itemInfoDivContainerStyles = css`
  display: flex;
`;
