/** @jsxImportSource @emotion/react */
import { useState, useEffect } from 'react';
import CustomButton from '../../../components/Button/Button';
import { colors, typography } from '../../../scss/colors';
import { css } from '@emotion/react';
import { utilsConstants } from '../../../utils/utilsConstants';
import { useLocation, useNavigate } from 'react-router-dom';
import { regBackButton, regNextButton, regNextButtonDisabled, registrationButtonContainer } from '../../Registration/Registration.style';
import { StringConstants } from '../../../constants/Strings';
import InputFile from '../../../components/InputFile/InputFile';
import { getLocalStorage } from '../../../utils/browserHandlers/browserStorageHandler';
import { getCompanyKYCGETApiUrl, getDocumentApiUrl, getPartnerKYCGETApiUrl, getUploadFilePostApiUrl, uploadFileApiUrl } from '../../../apiManger/apiConstants';
import { get } from '../../../apiManger/apiMethods';
import Loder from '../../../components/Loader/Loder';

import { errorMsg } from '../../KycDocumentes/KycDocumentes';
import { Box, Grid } from '@mui/material';
import { useToastHook } from '../../../components/Toast/useToastHook';
import { ToastContainer } from 'react-toastify';
import PreviewComponent from '../../KycDocumentes/Preview';

const registrationOtpContainer = css`
margin-top:15px;
display: flex;
align-items: flex-start;
flex-wrap:wrap;
justify-content:space-between;
            `

const kycPassportContainer = css`
margin-top:8px;
display: flex;
align-items: flex-start;
justify-content:space-between;
width:74%;
  @media (max-width:768px){
flex-wrap:wrap;
}
 `

const regPanInput = css`
 width:225px ;
 height: 40px;
 margin-bottom:35px;

   @media (max-width:768px){
   width:345px;
}
            `
 const regPanInputFile = css`
 width:225px ;
 width:240px ;
 height: 40px;
margin-bottom:20px;
  `

const kycSubHeading = css`
color: ${colors.charcoal};
${typography('600', '16px')}
display:flex;
justify-content:flex-start;
margin-bottom:20px;
`

export const previewSection = css`
color: ${colors.buttonOrange};
${typography('700', '16px')}
text-decoration:line;
text-decoration: underline;
display: flex;
margin-bottom:20px;
cursor:pointer;
`

const KycDocumentes = (props: any) => {
    const { setValue, handleNext ,setUploadedDocuments,setCompanyUploadDocuments} = props;
    const { STEPPER, STEPPER_INDIVIDUAL, STEPPER_INDIVIDUAL_DIRECTOR, RegisterTypeOptions, EntityTypeOptions, PartnerShipTypeOptions } = utilsConstants;
    const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
    const {
        showLoadingIndicator,
        hideLoadingIndicator,
        loadingComponent,
        visible,
      } = Loder();
    const { BACK_LABEL, SUBMIT_LABEL, KYC_VERIFICATION_COMPANY_HEADING,KYC_VERIFICATION_HEADING } = StringConstants;
    const [errors, setErrors] = useState<any>({});
    const [documents, setDocuments] = useState<any>({
        pan: { fileId: "", fileName: "" },
        aadhar: { fileId: "", fileName: "" },
        voter: { fileId: "", fileName: "" },
        driving: { fileId: "", fileName: "" },
        passport: { fileId: "", fileName: "" },
        additional1: { fileId: "", fileName: "" },
        additional2: { fileId: "", fileName: "" },
        business: { fileId: "", fileName: "" },
        gst: { fileId: "", fileName: "" },
        udyam: { fileId: "", fileName: "" },
        additional3: { fileId: "", fileName: "" },
        additional4: { fileId: "", fileName: "" }
      });
    const docList = [
        { inputLabel: "PAN Card", name: "pan", id: '1ce76767-3999-4ceb-af72-a7ba96ac8f2d', value: '',fileUrl:'',fileId:'' },
        { inputLabel: "Aadhaar Card", name: "aadhar", id: '2ce76767-3999-4ceb-af72-a7ba96ac8f2d', value: '' ,fileUrl:'',fileId:''},
        { inputLabel: "Voter ID", name: "voter", id: '3ce76767-3999-4ceb-af72-a7ba96ac8f2d', value: '',fileUrl:'',fileId:'' },
        { inputLabel: "Driving License", name: "driving", id: "4ce76767-3999-4ceb-af72-a7ba96ac8f2d", value: '' ,fileUrl:'',fileId:''}
    ];

    const docList2 = [
        { inputLabel: "Passport", name: "passport", id: '5ce76767-3999-4ceb-af72-a7ba96ac8f2d', value: '',fileUrl:'' ,fileId:''},
        { inputLabel: "Additional Document", name: "additional1", id: '41d6e7c5-9431-4993-86fa-fa6deb01e5f1', value: '' ,fileUrl:'',fileId:''},
        { inputLabel: "Additional Document", name: "additional2", id: '10662581-4f7b-4c0d-918c-d937e1c9cf51', value: '' ,fileUrl:'',fileId:''}
    ];

    const companydocList = [
        { inputLabel: "Business PAN", name: "business", id: "c98bf386-ec7a-43f7-a7b3-bed71ec28ff8", value: '',fileUrl:'',fileId:'' },
        { inputLabel: "GST", name: "gst", id: 'fd542557-09bf-4568-b20a-3d0bedc2c0d8', value: '' ,fileUrl:'',fileId:''},
        { inputLabel: "Udyam Aadhaar", name: "udyam", id: '15032b4c-5f45-47f7-8ec0-f1479af27a69', value: '' ,fileUrl:'',fileId:''},
        { inputLabel: "Additional Document", name: "additional3", value: '' ,fileUrl:'',fileId:'', id: '41d6e7c5-9431-4993-86fa-fa6deb01e5f1'},
        { inputLabel: "Additional Document", name: "additional4", value: '' ,fileUrl:'',fileId:'', id: '10662581-4f7b-4c0d-918c-d937e1c9cf51'}
    ];
    const {showToast} = useToastHook()
    const [docListState, setDocListState] = useState(docList);
    const [docList2State, setDocList2State] = useState(docList2);
    const [companydocListState, setCompanyDocListState] = useState(companydocList);
    const registrationId = getLocalStorage('Application_registrationId');
    const { state } = useLocation();
    const [stepper, setStepper] = useState(STEPPER)

    useEffect(() => {
        getAPICall();
        if (getLocalStorage('Application_EntityType') === "Business Entity") {
            getCompanyAPICall();
        }
    }, []);
    const getAPICall = async () => {
        showLoadingIndicator();
        const response = await get({
            url: getPartnerKYCGETApiUrl(registrationId)
        });
        if(response?.result){
            hideLoadingIndicator();
        const updatedDocList = docList.map((item) => {
            const resItem = response.result?.find((res: any) => res.fileTypeId === item.id);
            return resItem ? { ...item, value: resItem.fileName, documentId: resItem.id ,fileUrl:resItem.fileUrl,fileId:resItem.fileId} : item;
        });

        const updatedDocList2 = docList2.map((item) => {
            const resItem = response.result?.find((res: any) => res.fileTypeId === item.id);
            return resItem ? { ...item, value: resItem.fileName, documentId: resItem.id ,fileUrl:resItem.fileUrl,fileId:resItem.fileId} : item;
        });

        setDocListState(updatedDocList);
        setDocList2State(updatedDocList2);
    }
    else{
        hideLoadingIndicator();
    }
    };


    const getCompanyAPICall = async () => {
        showLoadingIndicator();
        const response = await get({
            url: getCompanyKYCGETApiUrl(registrationId)
        });

        if(response?.result){
            hideLoadingIndicator();
        const updatedCompanyDocList = companydocList.map((item) => {
            const resItem =response?.result && response?.result?.find((res: any) => res.fileTypeId === item.id);
            return resItem ? { ...item, value: resItem.fileName, documentId: resItem.id ,fileUrl:resItem.fileUrl,fileId:resItem.fileId} : item;
        });

        setCompanyDocListState(updatedCompanyDocList);
    }
    else{
        hideLoadingIndicator();
    }
    };
    
    const handleChange = async (fieldName: any, e: any,type:any) => {
        const file = e.target.files?.[0];
        const fileTypeId = e.target.id;
        const documentId = e.target.getAttribute('data-document-id');
        const fileSize = file.size;
        const fileType = file.type;
          if (fileSize > 1 * 1024 * 1024) {
            showToast("File size too large. Maximum size is 1 MB.","error");
            return;
          }
        
          if (fileType == "application/vnd.openxmlformats-officedocument.wordprocessingml.document") {
            showToast("Invalid file type .docx files are not allowed.","error");
            return;
          }else if(fileType == "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" || fileType == "application/vnd.ms-excel"){
            showToast("Invalid file type .xlsx or .xls files are not allowed.", "error");
            return;
          }
        if (file) {
          const response: any = await fileUpload(file);
      
          if (response?.result?.[0]?.file_id) {
            setDocuments((prevDocuments: any) => ({
              ...prevDocuments,
              [fieldName]: {
                fileId: response?.result[0]?.file_id,  
                fileName: file.name  
              }
            }));
            if(type === "company"){
                setCompanyUploadDocuments((prev: any) => {
                    const updatedDocuments = prev.filter((doc: any) => doc.documentType !== fieldName);
                    return [...updatedDocuments, { 
                        ...response.result[0], 
                        documentType: fieldName, 
                        fileTypeId: fileTypeId, 
                        fileId: response?.result[0]?.file_id,
                        id: documentId || null
                    }];
                });
            }else{
            setUploadedDocuments((prev: any) => {
              const updatedDocuments = prev.filter((doc: any) => doc.documentType !== fieldName);
              return [...updatedDocuments, { 
                  ...response.result[0], 
                  documentType: fieldName, 
                  fileTypeId: fileTypeId, 
                  fileId: response?.result[0]?.file_id,
                  id: documentId || null
              }];
          });
        }
          }else if(response?.error){
            const error = fieldName === '' ? null : response?.error?.message ;
            setErrors({
                ...errors,
                [fieldName]: error
            });
        }else{
          showToast("something went wrong","error")
        }
        }
      };

      const fileUpload = async (file: any) => {
        const data = new FormData();
        data.append("file", file);
        const response: any = await uploadFileApiUrl(data);
        return response;
      };

    const handleClear = (fieldName: any) => {
        setDocuments({
            ...documents,
            [fieldName]: ""
        });
        setErrors({
            ...errors,
            [fieldName]: null
          });
    }
    useEffect(() => {
        const mandatoryFeild = Object.values(documents).every(value => value);
        if (mandatoryFeild) {
            setIsNextButtonDisabled(false);
        } else {
            setIsNextButtonDisabled(true);
        }
    }, [isNextButtonDisabled, documents]);

    useEffect(() => {
        if (state) {
            const { registerType, enityType, PartnershipType } = state;
            if (registerType === RegisterTypeOptions[0].label && enityType === EntityTypeOptions[0].label) {
                setStepper(STEPPER_INDIVIDUAL)
            } else if (registerType === RegisterTypeOptions[0].label && (PartnershipType === PartnerShipTypeOptions[1].label || PartnershipType === PartnerShipTypeOptions[5].label)) {
                setStepper(STEPPER_INDIVIDUAL_DIRECTOR)
            }
        }
    }, [stepper])

    const gridContainerStyle = css`
width: calc(100% + 30px);
@media(max-width:768px){
width: calc(100% + 20px);
}
`;
const formControlStyle = css`
  margin-bottom: 16px;
   width: 100%;
`;
    return (
        <div>
            {/* <div css={registrationOtpContainer}> */}
            {visible ? 
        <>{loadingComponent()}</> :
        <>        <div css={kycSubHeading}>
            {KYC_VERIFICATION_HEADING}
          </div>
            <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
            <Grid container spacing={2} css={gridContainerStyle}>
                {docListState?.map((item: any, index: any) => (
                    <Grid item xs={12} sm={6} md={3} key={index}>
                        <InputFile
                            inputLabel={item.inputLabel}
                            onChange={(e) => handleChange(item.name, e,"partner")}
                            css={formControlStyle}
                            onClear={() => handleClear(item.name)}
                            registration={false}
                            docId={item.id}
                            value={item.value}
                            documentId={item.documentId}
                            fileName={documents[item.name]?.fileName || item.value} 
                        />
                        {errors[item.name] && <div css={{ color: colors.crimson, fontSize: '12px', textAlign: "left", marginTop: "-10px" }}>{errors[item.name]}</div>}
                        {(documents[item.name]?.fileId || item?.fileId )&& <PreviewComponent file={documents[item.name]?.fileId || item?.fileId } />}
                   </Grid>
                ))}
                </Grid>
                </Box>
            {/* </div> */}
            {/* <div css={kycPassportContainer}> */}
            <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
            <Grid container spacing={2} css={gridContainerStyle}>
                {docList2State?.map((item: any, index: any) => (
                  <Grid item xs={12} sm={6} md={3} key={index}>
                        <InputFile
                            inputLabel={item.inputLabel}
                            onChange={(e) => handleChange(item.name, e,"partner")}
                            css={formControlStyle}
                            onClear={() => handleClear(item.name)}
                            registration={false}
                            docId={item.id}
                            value={item.value}
                            documentId={item.documentId}
                            fileName={documents[item.name]?.fileName || item.value} 
                        /> 
                         {errors[item.name] && <div css={{ color: colors.crimson, fontSize: '12px', textAlign: "left", marginTop: "-10px" }}>{errors[item.name]}</div>}
                         {(documents[item.name]?.fileId || item?.fileId )&& <PreviewComponent file={documents[item.name]?.fileId || item?.fileId } /> }
              </Grid>
                ))}
                </Grid>
             </Box>
            {/* </div> */}
            {(getLocalStorage('Application_EntityType') === "Business Entity") && (
                <>
                    <div css={kycSubHeading}>
                        {KYC_VERIFICATION_COMPANY_HEADING}
                    </div>
                    {/* <div css={registrationOtpContainer}> */}
                    <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
                    <Grid container spacing={2} css={gridContainerStyle}>
                        {companydocListState.map((item: any, index: any) => (
                            <Grid item xs={12} sm={6} md={3} key={index}>
                                <InputFile
                                    inputLabel={item.inputLabel}
                                    onChange={(e) => handleChange(item.name, e,"company")}
                                    css={formControlStyle}
                                    onClear={() => handleClear(item.name)}
                                    registration={false}
                                    docId={item.id}
                                    value={item.value}
                                    documentId={item.documentId}
                                    fileName={documents[item.name]?.fileName || item.value} 
                                />
                                 {errors[item.name] && <div css={{ color: colors.crimson, fontSize: '12px', textAlign: "left", marginTop: "-10px" }}>{errors[item.name]}</div>}
                                 {(documents[item.name]?.fileId || item?.fileId )&& <PreviewComponent file={documents[item.name]?.fileId || item?.fileId } /> }
                         </Grid>
                        ))}
                        </Grid>
             </Box>
                    {/* </div> */}
                </>
            )}
             {/* {!errorMsgText && 
          <div css={errorMsg}>
          Invalid File Type
          </div>
          } */}
            <div css={registrationButtonContainer}>
                <CustomButton
                    label={BACK_LABEL}
                    onClick={() => setValue((pre: any) => pre - 1)}
                    variant="outlined"
                    css={regBackButton}
                />
               
                <CustomButton
                    label="Next"
                    onClick={() =>handleNext() }
                    css={ regNextButton }

                />
            </div>
            <ToastContainer />
           </>}
        </div>
    )
}


export default KycDocumentes;
