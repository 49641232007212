import { getLocalStorage } from "../utils/browserHandlers/browserStorageHandler";
import {
    GET_PROPS_TYPE,
    POST_PROPS_TYPE,
    PUT_PROPS_TYPE,
  } from "./apiMethodsInterface";
  
  export const get = ({ url, header = {} }: GET_PROPS_TYPE) => {
    const token = getLocalStorage("access_token")
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...header.headers,
    };
  
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  
    return fetch(url, {
      headers,
    })
      .then((response) => {
        if (response.status < 400) {
          return response.json();
        }
        return response.json();
      })
      .then(async (response) => {
        return response;
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  export const post = async ({ url, obj, header = {} }: POST_PROPS_TYPE) => {
    const token = getLocalStorage("access_token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...header.headers,
    };
  
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  
    return fetch(url, {
      method: "POST",
      headers:  headers,
      body: JSON.stringify(obj),
    })
      .then((response) => {
        return response.json();
      })
      .then(async (data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  export const put = async ({ url, obj, header = {} }: PUT_PROPS_TYPE) => {
    const token = getLocalStorage("access_token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...header.headers,
    };
  
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  
    return fetch(url, {
      method: "PUT",
      headers: headers,
      body: JSON.stringify(obj),
    })
      .then((response) => {
        return response.json();
      })
      .then(async (data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  };
  
  export const deleteApiMethod = async ({
    url,
    obj,
    header = {},
  }: POST_PROPS_TYPE) => {
    const token = getLocalStorage("access_token");
    const headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...header.headers,
    };
  
    if (token) {
      headers.Authorization = `Bearer ${token}`;
    }
  
    return fetch(url, {
      method: "DELETE",
      headers: headers,
      body: JSON.stringify(obj),
    })
      .then((response) => {
        return response.json();
      })
      .then(async (data) => {
        return data;
      })
      .catch((error) => {
        console.error(error);
      });
  };