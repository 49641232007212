/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import TabsComponent from "../../../components/TabsComponent/TabsComponent";
import RegistrationDetails from "../InitiateKyc/RegistrationDetails";
import DirectorDetails from "../InitiateKyc/DirectorDetails";
import CompanyDetails from "../InitiateKyc/CompanyDetails";
import BankDetails from "../InitiateKyc/BankDetails";
import KycDocumentes from "../InitiateKyc/KycDetails";
import { InitiateKycDetailsSection } from "../InitiateKyc/InitiateKyc.style";
import UploadCpv from "../InitiateCpv/UploadCpv";
import PayoutStructure from "../../Sanction/Sanction1/PayoutStructure";
import { getLocalStorage } from "../../../utils/browserHandlers/browserStorageHandler";
import { backCss, mobileNavigation } from "../InitiateKyc/InitiateKycDetails";
import { icon } from "../../../assets/Images";
import { useNavigate } from "react-router-dom";
import IndividualDetails from "../InitiateKyc/IndividualDetails";
import { post } from "../../../apiManger/apiMethods";
import { getCompanyKYCPostApiUrl, getPartnerKYCPostApiUrl } from "../../../apiManger/apiConstants";
import Loder from "../../../components/Loader/Loder";
import { useToastHook } from "../../../components/Toast/useToastHook";
import IdVerification from "../InitiateKyc/IdVerification";
import Cibil from "../InitiateKyc/Cibil";
const InitiatePayoutDetails = (props: any) => {
  const { showDetails,detailsData ,setShowDetails} = props;
  const navigate = useNavigate()
  const [value, setValue] = useState(0);
  const {registrationDetails,bankDetails,individualDetails,companyDetails,directorDetails,companyKycDetails,partnerKYCDetails}=detailsData.data;
  const [entityType, setEntityType] = useState('');
  const [partner, setPartner] = useState('');
  const [uploadedDocuments, setUploadedDocuments] = useState<any>([]);
  const [companyUploadDocuments, setCompanyUploadDocuments] = useState<any>([]);
  const breadcrumbItems = [
    { label: 'Application Review', href: '/initiate-kyc/payout' },
    { label: 'Payout Structure',subHref:'/initiate-kyc/payout' },
    { label: showDetails.ApplicationNo }
  ];
  const { showToast } = useToastHook();

  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
} = Loder();
const handleNext = async() => {
  showLoadingIndicator();
  try {
    const validDocuments = uploadedDocuments.filter((doc: any) => doc !== null);
    const payloadItems = validDocuments.map((item: any) => 
    ({
    fileTypeId: item.fileTypeId,
    fileId: item.fileId,
    fileSize: item?.file_size,
    fileType:  item?.file_type,
    fileName: item?.file_name,
    status: 1,
    id: item?.id
  }));
    let obj={
      "registrationId":detailsData?.data?.registrationId,
      "kyc":payloadItems
    }
 
    const response = await post({
      url: getPartnerKYCPostApiUrl(detailsData?.data?.registrationId),
      obj: obj
    });
   
    if (response.result) {
      hideLoadingIndicator();
    }
    else if(response?.error?.message){
      hideLoadingIndicator();
      showToast(response?.error?.message,"error")
    }
  } catch (error) {
    hideLoadingIndicator();
    console.error('Error occurred while submitting KYC details:', error);
  }
 if( getLocalStorage('Application_EntityType') === "Business Entity"){
  showLoadingIndicator();
  try {
  const companyloadItems = companyUploadDocuments.map((item: any) => 
    ({
    fileTypeId: item.fileTypeId,
    fileId: item.fileId,
    fileSize: item?.file_size,
    fileType:  item?.file_type,
    fileName: item?.file_name,
    status: 1,
    id: item?.id
  }));
    let obj2={
      "registrationId":detailsData?.data?.registrationId,
      "kyc":companyloadItems
    }
  
      const response2 = await post({
        url: getCompanyKYCPostApiUrl(detailsData?.data?.registrationId),
        obj: obj2
      });
    if ( response2?.result) {
      hideLoadingIndicator();
    }
    else if(response2?.error?.message){
      hideLoadingIndicator();
      showToast(response2?.error?.message,"error")
    }
  } catch (error) {
    hideLoadingIndicator();
    console.error('Error occurred while submitting KYC details:', error);
  }
}
setValue(value + 1)
}
  const handleUploadNext = () => {
    setValue(value + 1)
  }
  const Individualtabs = [
    {
      label: 'Registration Details',
      content: (
        <div>
          <RegistrationDetails
          setValue={setValue} 
            details={registrationDetails}
            setShowDetails={setShowDetails}
          />

        </div>
      ),
    },
    {
      label: ' Individual Details', content:
        <div>
          <IndividualDetails setValue={setValue} details={individualDetails} registrationId={detailsData?.data?.registrationId} />
        </div>
    },
    {
      label: 'Bank Details', content:
        <div>
          <BankDetails setValue={setValue} details={bankDetails} registrationId={detailsData?.data?.registrationId}/>
        </div>
    },
    {
      label: 'CIBIL', content:
        <div>
          <Cibil setValue={setValue} value={value} />
        </div>
    },
    {
      label: 'ID Verification', content:
        <div>
          <IdVerification setValue={setValue}  value={value} />
        </div>
    },
    { label: 'KYC Details', content: <div><KycDocumentes setValue={setValue} handleNext={handleNext} setUploadedDocuments={setUploadedDocuments} setCompanyUploadDocuments={setCompanyUploadDocuments}/></div> },
    { label: 'FCU/CPV', content: <div><UploadCpv setValue={setValue} handleUploadNext={handleUploadNext}/></div> },
    { label: 'Payout Structure', content: <div><PayoutStructure setValue={setValue} pagename="payout"/></div>},

  ];

  const Proprietortabs = [
    {
      label: 'Registration Details',
      content: (
        <div>
          <RegistrationDetails   setValue={setValue} 
            details={registrationDetails}
            setShowDetails={setShowDetails}/>
        </div>
      ),
    },
    {
      label: 'Company Details', content:
        <div>
          <CompanyDetails setValue={setValue}  details={companyDetails} registrationId={detailsData?.data?.registrationId}/>
        </div>
    },
    {
      label: ' Individual Details', content:
        <div>
          <IndividualDetails setValue={setValue} details={individualDetails} registrationId={detailsData?.data?.registrationId}/>
        </div>
    },
    {
      label: 'Bank Details', content:
        <div>
          <BankDetails setValue={setValue} details={bankDetails} registrationId={detailsData?.data?.registrationId}/>
        </div>
    },
    {
      label: 'CIBIL', content:
        <div>
          <Cibil setValue={setValue} value={value} />
        </div>
    },
    {
      label: 'ID Verification', content:
        <div>
          <IdVerification setValue={setValue}  value={value} />
        </div>
    },
    { label: 'KYC Details', content: <div><KycDocumentes setValue={setValue} handleNext={handleNext} setUploadedDocuments={setUploadedDocuments} setCompanyUploadDocuments={setCompanyUploadDocuments}/></div> },
    { label: 'FCU/CPV', content: <div><UploadCpv setValue={setValue} handleUploadNext={handleUploadNext}/></div> },
    { label: 'Payout Structure', content: <div><PayoutStructure setValue={setValue} pagename="payout"/></div>},

  ];
  const Businesstabs = [
    {
      label: 'Registration Details',
      content: (
        <div>
          <RegistrationDetails    setValue={setValue} 
            details={registrationDetails}
            setShowDetails={setShowDetails}
            />
        </div>
      ),
    },
    {
      label: 'Director Details',
      content:
        <div>
          <DirectorDetails setValue={setValue} details={directorDetails} registrationId={detailsData?.data?.registrationId}/>
        </div>
    },
    {
      label: 'Company Details', content:
        <div>
          <CompanyDetails setValue={setValue} details={companyDetails} registrationId={detailsData?.data?.registrationId}/>
        </div>
    },
    {
      label: 'Bank Details', content:
        <div>
          <BankDetails setValue={setValue} details={bankDetails} registrationId={detailsData?.data?.registrationId}/>
        </div>
    },
    {
      label: 'CIBIL', content:
        <div>
          <Cibil setValue={setValue} value={value} />
        </div>
    },
    {
      label: 'ID Verification', content:
        <div>
          <IdVerification setValue={setValue}  value={value} />
        </div>
    },
    { label: 'KYC Details', content: <div><KycDocumentes setValue={setValue} handleNext={handleNext} setUploadedDocuments={setUploadedDocuments} setCompanyUploadDocuments={setCompanyUploadDocuments}/></div> },
    { label: 'FCU/CPV', content: <div><UploadCpv setValue={setValue} handleUploadNext={handleUploadNext}/></div> },
    { label: 'Payout Structure', content: <div><PayoutStructure setValue={setValue} pagename="payout"/></div>},

  ];
  useEffect(() => {
    const fetchValues = () => {
      const latestEntityType = getLocalStorage('Application_EntityType');
      const latestPartner = getLocalStorage('Application_PartnershipType');
      setEntityType(latestEntityType);
      setPartner(latestPartner);
    };

    fetchValues();
  }, []);
  return (
    <div>
      <Breadcrumb items={breadcrumbItems} />
      {visible ? 
        <>{loadingComponent()}</> :
        <>     <div css={mobileNavigation}>
          <div css={backCss} onClick={() => setShowDetails(false)}>
            <img src={icon.NavigateBack} alt="no_navigate" />
          </div>
          <div>
          Payout Structure - {showDetails.PartnerType}
          </div>
      </div>
      <div css={InitiateKycDetailsSection}>
        <TabsComponent tabs={entityType?.includes("Individual")
            ? Individualtabs
            : (entityType?.includes("Business") && partner?.includes("Proprietorship"))
              ? Proprietortabs
              : Businesstabs} value={value} setValue={setValue}/>
      </div>
      </>}
    </div>
  );
};

export default InitiatePayoutDetails;
