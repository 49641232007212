/** @jsxImportSource @emotion/react */
import React, { useEffect, useState } from 'react';
import CustomInput from '../../components/Input/Input';
import { StringConstants } from '../../constants/Strings';
import { linkStyle, footerStyle, signInButtonStyle, optionsStyle, inputStyle, descriptionStyle, titleStyle, containerStyle, welcomeSectionStyle, welcomeTitleStyle, welcomeTextStyle, loginSectionStyle, loginFormStyle, ResendOtpText, verifySection, NavigateImg, signInDisabledButtonStyle, ResendOtpTextDisable } from "./Login.style";
import { setLocalStorage } from '../../utils/browserHandlers/browserStorageHandler';
import { useNavigate } from 'react-router-dom';
import { icon } from '../../assets/Images';
import { dymanicWhiteSpaceValid, whiteSpaceValid } from '../../constants/Validations';
import { post } from '../../apiManger/apiMethods';
import { signInApiUrl, verifySignInApiUrl } from '../../apiManger/apiConstants';
import { useToastHook } from '../../components/Toast/useToastHook';
import { ToastContainer } from 'react-toastify';
import { jwtDecode } from 'jwt-decode';
import Loder from '../../components/Loader/Loder';


const LoginWithOtp = (props: any) => {
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
  } = Loder();
  const { showToast } = useToastHook()
  const navigate = useNavigate()
  const { userName, password,setShowOtpForm } = props;
  const [otp, setOtp] = useState('');
  const { LOGIN_STRING: { WELCOME, WELCOME_DESC, FREE_ACC, NO_ACC, DESC, REMEMBER_ME, FORGOT, LOGIN, SIGN_IN, LABEL_EMAIL, LABEL_PASSWORD } } = StringConstants;
  const [timer, setTimer] = useState<number>(120);
  const [isTimerRunning, setIsTimerRunning] = useState<boolean>(true);
  const [error, setErrors] = useState<any>(null)
  const handleOtpChange = (e: any) => {
    const value = e.target.value
    if (/^\d*$/.test(value)) {
      setOtp(value);
      const error = whiteSpaceValid(value);
      setErrors(error);
    }
   
  };

  useEffect(() => {
    let intervalId: NodeJS.Timeout;
    if (isTimerRunning) {
      intervalId = setInterval(() => {
        setTimer((prevTimer) => {
          if (prevTimer <= 1) {
            clearInterval(intervalId);
            setIsTimerRunning(false); 
            return 0;
          }
          return prevTimer - 1;
        });
      }, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isTimerRunning]);

  const resetTimer = async () => {
    showLoadingIndicator()
    const payload = {
      username: userName,
      password: password
    }
    const response = await post({
      url: signInApiUrl(),
      obj: payload
    });

    if (response?.result) {
      hideLoadingIndicator()
      setTimer(120);
      setIsTimerRunning(true);
    } else if (response?.error?.message) {
      hideLoadingIndicator()
      showToast(response?.error?.message, "error")
    }
    else {
      hideLoadingIndicator()
      showToast("something went wrong", "error")
    }

  };

  const formatTime = (time: number) => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
  };

  const handleSignIn = async () => {
    showLoadingIndicator()
    const payload = {
      username: userName,
      password: password,
      otp: otp,
    }
    const response = await post({
      url: verifySignInApiUrl(),
      obj: payload
    });

    if (response?.result) {
      hideLoadingIndicator()
      setLocalStorage("access_token", response.result.access_token)
      setLocalStorage("refresh_token", response.result.refresh_token)
      try {
        const decodedToken: any = jwtDecode(response.result.access_token);
        const userGroups = decodedToken?.user_groups;
        setLocalStorage("user_groups", userGroups);
        navigate("/dashboard")
        // if (userGroups?.includes("customer") || userGroups?.includes("partner")) {
        //   navigate("/dashboard")
        // }else if(userGroups?.includes("sanction_p1") || userGroups?.includes("sanction_p2")){
        //   navigate("/sanction")
        // }else if(userGroups?.includes("legal")){
        //   navigate("/empanelment")
        // }else{
        //   navigate("/initiate-kyc")
        // }
      } catch (error) {
        console.error("Failed to decode token:", error);
      }

    } else if (response?.error?.message) {
      hideLoadingIndicator()
      showToast(response?.error?.message, "error")
    }
    else {
      hideLoadingIndicator()
      showToast("something went wrong", "error")
    }
  }
  return (
   
    <div css={containerStyle}>
       {visible ? 
      <>{loadingComponent()}</> :
      <> 
      <div css={welcomeSectionStyle}>
        <div css={welcomeTitleStyle}>{WELCOME}</div>
        <div css={welcomeTextStyle}>
          {WELCOME_DESC}
        </div>
      </div>
      <div css={loginSectionStyle}>
        <div css={loginFormStyle}>
          <div css={verifySection}>
            <div css={NavigateImg} onClick={()=> setShowOtpForm(false)}><img src={icon.NavigateBack} alt="no_img" /></div>
            <div css={titleStyle}>OTP Verification</div>
          </div>
          <div css={descriptionStyle}>Lorem ipsum dolor sit amet consectetur. Proin massa ut justo pellentesque diam sit enim facilisi egestas
          </div>
          <CustomInput
            css={inputStyle}
            type="text"
            inputLabel="OTP"
            value={otp}
            onChange={handleOtpChange}
            info={true}
            validate={(value) => dymanicWhiteSpaceValid(value, "Invalid otp")}
          />
          <div css={optionsStyle}>
            <label>
              ({formatTime(timer)})
            </label>
            <div onClick={resetTimer} css={isTimerRunning?ResendOtpTextDisable:ResendOtpText}>Resend OTP?</div>
          </div>
          <button css={error == null && otp ? signInButtonStyle : signInDisabledButtonStyle} onClick={handleSignIn}>{SIGN_IN}</button>
          {/* <div css={footerStyle}>
            <p>{NO_ACC} <a href="/signup" css={linkStyle}>{FREE_ACC}</a></p>
          </div> */}
        </div>
      </div>
      <ToastContainer />
      </>
       }
    </div>
  
  );
};

export default LoginWithOtp;
