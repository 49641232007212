/** @jsxImportSource @emotion/react */
import { useState, startTransition, ChangeEvent, useEffect } from 'react';
import CustomInput from '../../components/Input/Input';
import CustomButton from '../../components/Button/Button';
import { colors, typography } from '../../scss/colors';
import { css } from '@emotion/react';
import Layout from '../../components/Layout/Layout';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { regBackButton, regNextButton, regNextButtonDisabled, registrationButtonContainer, regiterTypeSection } from '../Registration/Registration.style';
import { StringConstants } from '../../constants/Strings';
import CustomSelect from '../../components/Select/Select';
import { icon } from '../../assets/Images';
import CustomStepper from '../../components/Stepper/Stepper';
import { utilsConstants } from '../../utils/utilsConstants';
import { getBankGETApiUrl, getBankPUTApiUrl, getBankPostApiUrl, getBankVerifyPOSTApiUrl } from '../../apiManger/apiConstants';
import { get, post, put } from '../../apiManger/apiMethods';
import { getLocalStorage, setLocalStorage } from '../../utils/browserHandlers/browserStorageHandler';
import { ifscCodeValidation, validAccountNumber, ValidName } from '../../constants/Validations';
import Loder from '../../components/Loader/Loder';
import { useToastHook } from '../../components/Toast/useToastHook';
import { ToastContainer } from 'react-toastify';


const containerStyle = css`
  color: ${colors.charcoal};
  ${typography('600', '20px')};
`;

const breakLine = css`
  border: 1px solid rgba(100, 130, 130, 0.25);
  margin-bottom: 25px;
`;

const gridContainerStyle = css`
width: calc(100% + 30px);
@media(max-width:768px){
width: calc(100% + 20px);
}
`;
const formControlStyle = css`
margin-bottom: 16px;
width: 100%;
`;

const bankSaveStyle = css`
  margin-top: 22px;
  text-align: left;
  display: flex;
  align-items: center;
   @media(max-width:768px){
  flex-direction:column;
    margin-left: 0px;
}
`;



const saveValidateButtonStyle = (props: { disabled: boolean }) => css`
  background-color: ${colors.white};
  color: ${colors.crimson};
  padding: 10px 20px;
  border: 1px solid ${colors.crimson};
  border-radius: 4px;
  cursor: pointer;
  opacity: ${props.disabled ? 0.5 : 1}; 
  ${typography('bold', '16px')};
  @media(max-width:768px){
width: 100%;
}
  &:disabled {
    background-color: white;
    cursor: not-allowed;
  }
  &:hover:enabled {
    background-color: ${colors.crimson};
    color: ${colors.white};
  }
`;

const successMessageStyle = css`
  color: ${colors.lightGray};
  margin-left: 15px;
  ${typography('bold', '14px')};
  padding-right:10px;
`;

const errorMessageStyle = css`
  color: ${colors.lightGray};
  margin-left: 15px;
  ${typography('bold', '14px')};
  padding-right:10px;
`;

const messageContainerStyle = css`
  display: flex;
  align-items: center;
  margin-left: 15px;
 @media(max-width:768px){
    margin-top: 20px;
}
`;

const bankContainer = css`
padding:0px 10px 0px 20px;
@media(max-width:768px){
padding:0px;
}
`
const BankDetails = () => {
    const {showToast} = useToastHook()
    const { state } = useLocation();

    const { BACK_LABEL, NEXT_LABEL } = StringConstants;
    const navigate = useNavigate();
    const { STEPPER, STEPPER_INDIVIDUAL, STEPPER_INDIVIDUAL_DIRECTOR, BUSINESS_ACCOUNT_TYPE, ACCOUNT_TYPE } = utilsConstants;
    const [stepper, setStepper] = useState(STEPPER)
    // const [isFormChanged, setIsFormChanged] = useState<any>('')
    const [isAlreadySubmitted, setIsAlreadySubmitted] = useState<any>('')
    const [formValues, setFormValues] = useState<any>({
        accountNo: '',
        ifscCode: '',
        accountHolderName: '',
        accountType: '',
        bankName: '',
        branchName: ''
    });
    const {
        showLoadingIndicator,
        hideLoadingIndicator,
        loadingComponent,
        visible,
    } = Loder();
    const [validationMessage, setValidationMessage] = useState<string | null>(null);
    const [isValidationSuccessful, setIsValidationSuccessful] = useState<any>('');
    const [isFieldModified, setIsFieldModified] = useState(false);
    const mandatoryFields = [
        'accountNo',
        'ifscCode',
        'accountHolderName',
        'accountType',
        'bankName'
    ];
    const registrationId = getLocalStorage('registrationId');
    const bank_id = getLocalStorage('bank_id');
    const isFormComplete = () => {
        return mandatoryFields.every((field) => {
            const value = formValues[field];
            return value !== undefined && value.trim() !== '';
        });
    };
    useEffect(() => {
        if (bank_id != undefined) {
            getAPICall();
        }

    }, [])
    const entityType = getLocalStorage('Entity Type');
    const partnershipTypeLS = getLocalStorage('Partnership Type');
    const [stepCount, setStepCount] = useState(entityType === 'Individual' ? 2 : 3);
    const [errors, setErrors] = useState({});
    const [status, setStatus] = useState<any>()
    useEffect(() => {
        if (entityType === 'Individual') {
            setStepper(STEPPER.filter(step => step !== 'Company Details'));
        }
        else if (entityType === 'Business Entity') {
            if (partnershipTypeLS === "Sole Proprietorship") {
                setStepper(STEPPER)
            }
            else {
                setStepper(STEPPER_INDIVIDUAL_DIRECTOR);

            }
        }
    }, [entityType]);
    const getAPICall = async () => {
        showLoadingIndicator();
        const response = await get({
            url: getBankGETApiUrl(registrationId, bank_id)
        });
        if (response?.result) {
            hideLoadingIndicator();
            const { accountNo, ifscCode, accountHolderName, accountType, bankName, branchName,status } = response?.result
            setFormValues({
                accountNo: accountNo,
                ifscCode: ifscCode,
                accountHolderName: accountHolderName,
                accountType: accountType,
                bankName: bankName,
                branchName: branchName,
                status:status
            });
            // setIsValidationSuccessful(true)
            setIsAlreadySubmitted(true)
            if(status == 0 ){
                setValidationMessage("Bank Details Verification Failed")
                setIsValidationSuccessful(false)
            }else if(status == 1){
                setValidationMessage("Bank Details Verification Successful!")
                setIsValidationSuccessful(true)
            }
        }
        else {
            hideLoadingIndicator();
        }
    }

    const handleNext = async () => {
        showLoadingIndicator();
        const reqObj = {
            registrationId: registrationId,
            accountNo: formValues.accountNo,
            ifscCode: formValues.ifscCode,
            accountHolderName: formValues.accountHolderName,
            accountType: formValues.accountType,
            bankName: formValues.bankName,
            branchName: formValues.branchName,
            status: status

        }
        try {
            let response;

            if (isAlreadySubmitted === "") {
                response = await post({
                    url: getBankPostApiUrl(registrationId),
                    obj: reqObj
                });
            }
            else if (isAlreadySubmitted === true) {
                response = await post({
                    url: getBankPostApiUrl(registrationId),
                    obj: { ...reqObj, "id": bank_id }
                });
            }



            if (response.result) {
                hideLoadingIndicator();
                let existingArray = JSON.parse(getLocalStorage("completed") || "[]");
                if (!existingArray?.includes("bank")) {
                    existingArray.push("bank");
                }
                setLocalStorage("completed", JSON.stringify(existingArray));
                setLocalStorage('bank_id', response.result.id)

                // startTransition(() => {
                if (stepCount === 3 || stepCount === 2) {
                    setStepCount(stepCount + 1);
                    navigate('/kyc-documents', {
                        state: {
                            "registerType": state?.registerType,
                            "partnertype": state?.partnertype,
                            "enityType": state?.enityType,
                            "PartnershipType": state?.PartnershipType,
                        },
                    });
                }
            }else if(response?.error?.message){
                showToast(response?.error?.message,"error")
                hideLoadingIndicator();
              }
            else {
                showToast("something went wrong","error")
                hideLoadingIndicator();
            }

        } catch (error) {
            showToast("something went wrong","error")
            hideLoadingIndicator();
            console.error("Failed to submit individual details:", error);
        }

    };

    const handleBack = () => {
        if (entityType?.includes("Individual")) {
            setStepCount(1);
            navigate("/individual-details");
        } else if ((entityType?.includes("Business")) && (partnershipTypeLS?.includes("Proprietorship"))) {
            setStepCount(2);
            navigate("/company-details");
        } else if ((entityType?.includes("Business")) && (!partnershipTypeLS?.includes("Proprietorship"))) {
            setStepCount(3);
            navigate('/director-details')
        }
    };
    const getValidator = (fieldName: any) => {
        switch (fieldName) {
            case 'ifscCode':
                return ifscCodeValidation;
            case 'accountNo':
                return validAccountNumber;
            case 'accountHolderName':
            case 'branchName':
            case 'bankName':
                return ValidName;
            default:
                return () => null;
        }
    };

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormValues((prevValues: any) => ({
            ...prevValues,
            [name]: value
        }));
        setIsFieldModified(true);
        setValidationMessage("");
        const validatorFn = getValidator(name);
        const error = value === '' ? null : validatorFn(value);
        setErrors({
            ...errors,
            [name]: error
        });
    };

    const handleSelectChange = (name: string, value: string) => {
        setFormValues((prevValues: any) => ({
            ...prevValues,
            [name]: value
        }));
        setIsFieldModified(true);
        setValidationMessage("");
    };
    const handleSaveValidate = async () => {
        showLoadingIndicator();
        try {
            const verifyPayload = {
                context_id: getLocalStorage('application_number'),
                type: "BANK_ACCOUNT_NUMBER",
                uid: formValues.accountNo,
                ifsc_code: formValues.ifscCode,
                created_for: "SKILL MINE"
            };

            const response = await post({
                url: getBankVerifyPOSTApiUrl(),
                obj: verifyPayload
            });

            if (response?.result?.status === 'VALID') {
                hideLoadingIndicator()
                setValidationMessage("Bank Details Verification Successful!");
                setIsValidationSuccessful(true);
                setStatus(1)
            } else {
                hideLoadingIndicator()
                setValidationMessage("Bank Details Verification Failed");
                setIsValidationSuccessful(false);
                setStatus(0)
            }
        } catch (error) {
            hideLoadingIndicator()
            console.error("Bank details validation failed:", error);
            setValidationMessage("Bank Details Verification Failed");
            setIsValidationSuccessful(false);
            setStatus(0)
        }
    };
    // const handleSaveValidate = () => {

    //     setValidationMessage("Bank Details Verification Successful!");
    //     setIsValidationSuccessful(true);
    //     setIsFieldModified(false);
    // };
    const isFormValid = Object.values(errors).every(error => error === null);
    const buttonDisable = isFormComplete() && isFieldModified && isFormValid

    useEffect(() => {
        if (state) {
            const { registerType, enityType, PartnershipType } = state;
            if (registerType === "Partner" && enityType === "Individual") {
                setStepper(STEPPER_INDIVIDUAL)
            } else if (registerType === "Partner" && (PartnershipType === "Partnership" || PartnershipType === "Private Ltd")) {
                setStepper(STEPPER_INDIVIDUAL_DIRECTOR)
            }

        }
    }, [STEPPER_INDIVIDUAL, STEPPER_INDIVIDUAL_DIRECTOR, state, stepper])

    const mobile = window.innerWidth < 768;
    return (
        <Layout>
            {!mobile && <CustomStepper steps={stepper} stepCount={stepCount} />}
            <div css={bankContainer}>
                <div css={containerStyle}>
                    Bank Details
                </div>
                <hr css={breakLine} />
                {visible ?
                    <>{loadingComponent()}</> :
                    <>   <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
                        <Grid container spacing={2} css={gridContainerStyle}>
                            {[
                                { label: 'Account No', name: 'accountNo', type: 'text', placeholder: 'Enter Account No', valid: validAccountNumber },
                                { label: 'IFSC Code', name: 'ifscCode', type: 'text', placeholder: 'Enter IFSC Code', valid: ifscCodeValidation },
                                { label: 'Account Holder Name', name: 'accountHolderName', type: 'text', placeholder: 'Enter Account Holder Name', valid: ValidName },
                                { label: 'Account Type', name: 'accountType', type: 'select', options: entityType?.includes("Business") && !partnershipTypeLS?.includes("Proprietorship") ? BUSINESS_ACCOUNT_TYPE : ACCOUNT_TYPE },
                                { label: 'Bank Name', name: 'bankName', type: 'text', placeholder: 'Enter Bank Name',valid: ValidName  },
                                { label: 'Branch Name', name: 'branchName', type: 'text', placeholder: 'Enter Branch Name',valid: ValidName  }
                            ].map((field, index) => (
                                <Grid item xs={12} sm={6} md={3} key={index}>
                                    {field.type === 'select' ? (
                                        <CustomSelect
                                            options={field.options}
                                            placeholder="Please Select"
                                            value={formValues[field.name]}
                                            onChange={(event) => handleSelectChange(field.name, event.target.value)}
                                            inputLabel={field.label}
                                            css={formControlStyle}
                                            mandatory

                                        />
                                    ) : (
                                        <>
                                            <CustomInput
                                                value={formValues[field.name]}
                                                onChange={handleInputChange}
                                                type={field.type}
                                                css={formControlStyle}
                                                required={mandatoryFields?.includes(field.name)}
                                                placeholder={field.placeholder}
                                                name={field.name}
                                                inputLabel={field.label}
                                                mandatory={!field.name?.includes("branchName")}
                                                validate={field.valid}
                                            />
                                        </>
                                    )}
                                </Grid>
                            ))}
                            <Grid item xs={12} sm={6} md={6}>
                                <div css={bankSaveStyle}>
                                    <button
                                        css={saveValidateButtonStyle({ disabled: !buttonDisable })}
                                        onClick={handleSaveValidate}
                                        disabled={!buttonDisable}
                                    >
                                        Save & Validate
                                    </button>
                                    {validationMessage && (
                                        <div css={messageContainerStyle}>
                                            <span css={isValidationSuccessful ? successMessageStyle : errorMessageStyle}>
                                                {validationMessage}
                                            </span>
                                            {isValidationSuccessful ? <img src={icon.GreenCheckIcon} alt="no_green_img" /> : <img src={icon.ErrorIcon} alt="no_error_img" />}

                                        </div>
                                    )}
                                </div>
                            </Grid>
                        </Grid>
                    </Box>

                        <div css={registrationButtonContainer}>
                            <CustomButton
                                label={BACK_LABEL}
                                onClick={handleBack}
                                variant="outlined"
                                css={regBackButton}
                            />
                            <CustomButton
                                label={NEXT_LABEL}
                                onClick={handleNext}
                                css={(isValidationSuccessful === true || isValidationSuccessful === false) ? regNextButton : regNextButtonDisabled}
                            // disabled={buttonDisable}
                            />
                        </div>
                    </>}
                    <ToastContainer />
            </div>
        </Layout>
    );
};

export default BankDetails;
