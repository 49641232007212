/** @jsxImportSource @emotion/react */
import  { useState, ChangeEvent, useEffect } from 'react';
import CustomInput from '../../../components/Input/Input';
import CustomButton from '../../../components/Button/Button';
import { colors } from '../../../scss/colors';
import { css } from '@emotion/react';
import { utilsConstants } from '../../../utils/utilsConstants';
import { useLocation } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { regBackButton, regNextButton, regNextButtonDisabled, regPanInput, registrationButtonContainer, regiterTypeSection } from '../../Registration/Registration.style';
import { StringConstants } from '../../../constants/Strings';
import CustomSelect from '../../../components/Select/Select';
import { getCompanyGETApiUrl, getCompanyPostApiUrl, getContituteGETApiUrl, getIndustryGETApiUrl, getStateGETApiUrl, getSubIndustryGETApiUrl } from '../../../apiManger/apiConstants';
import { get, post } from '../../../apiManger/apiMethods';
import { getLocalStorage, setLocalStorage } from '../../../utils/browserHandlers/browserStorageHandler';
import { useToastHook } from '../../../components/Toast/useToastHook';
import { ToastContainer } from 'react-toastify';
import Loder from '../../../components/Loader/Loder';
import { dymanicWhiteSpaceValid, getTodaysDate, validateGst, validatePAN, validatePostalCode, validEmail, whiteSpaceValid } from '../../../constants/Validations';



const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]{1}$/;
const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
const gstRegex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[A-Z0-9]{1}Z[A-Z0-9]{1}$/;
const postalCodeRegex = /^[1-9][0-9]{5}$/;
const CompanyDetails = (props:any) => {
  const {showToast} = useToastHook()
  const{setValue,details,registrationId} = props;
  const { state } = useLocation();
  const [stateList,setStateList] = useState([])
  const [constituteType,setConstituteType]= useState([])
  const [industryType,setIndustryType]= useState([])
  const [subIndustryType,setSubIndustryType]= useState([])
  const { BACK_LABEL, NEXT_LABEL } = StringConstants;
  const { STEPPER,STEPPER_INDIVIDUAL ,STEPPER_INDIVIDUAL_DIRECTOR} = utilsConstants;
  const [isAlreadySubmitted, setIsAlreadySubmitted] = useState<any>('')
  const [stepper, setStepper] = useState(STEPPER)
  const [isNextButtonDisabled, setIsNextButtonDisabled] = useState(true);
  const [formValues, setFormValues] = useState<any>({
    businessName: '',
    dateOfIncorporation: '',
    businessPanNo: '',
    constitutionType: '',
    location: '',
    corporateDetails: '',
    monthlyVolumes: '',
    emailId: '',
    companyType: '',
    natureOfBusiness: '',
    industryType: '',
    subIndustryType: '',
    city:'',
    state:'',
    postalCode:''
  });
  const [isFormChanged,setIsFormChanged]=useState(false)
const mandatoryFields = [
  'businessName',
  'dateOfIncorporation',
  'businessPanNo',
  'constitutionType',
  'monthlyVolumes',
  'emailId',
  'gstNumber',
  'addressLineOne',
  'city',
  'state',
  'postalCode'
];

const {
  showLoadingIndicator,
  hideLoadingIndicator,
  loadingComponent,
  visible,
} = Loder();
  const company_id = getLocalStorage('company_id');
  useEffect(() => {
    if(stateList.length == 0){
      getStateList()
      }
      if(constituteType.length == 0){
      getContituteList()
      }
      if(industryType.length == 0){
        getIndustryList()
      }

        getAPICall();

}, [])
const getStateList = async () => {
  const response = await get({
    url: getStateGETApiUrl()
  });
  if(response?.result){
    setStateList((response?.result?.map((state:any) => ({ label: state.state , value: state.id }))))
  }
}
const getContituteList = async () => {
  const response = await get({
    url: getContituteGETApiUrl()
  });
  if (response?.result) {
    setConstituteType((response?.result?.map((item: any) => ({ label: item.constitutionType, value: item.id }))))
  }
}
const getIndustryList = async () => {
  const response = await get({
    url: getIndustryGETApiUrl()
  });
  if (response?.result) {
    setIndustryType((response?.result?.map((item: any) => ({ label: item.industryType, value: item.id }))))
  }
}
const getSubIndustryList = async (industryId: string) => {
  const response = await get({
    url: getSubIndustryGETApiUrl(industryId)
  });
  if (response?.result) {
    setSubIndustryType((response?.result?.map((item: any) => ({ label: item.subIndustryType, value: item.id }))))
  }
}
  const getAPICall = async () => {
    showLoadingIndicator();
    const response = await get({
        url: getCompanyGETApiUrl(registrationId,  company_id ? company_id : details?.id?details?.id:"" )
    });
    if(response?.result){
      hideLoadingIndicator();
    const { businessName, businessPanNo, dateOfIncorporation, constitutionType, location, gstNumber,monthlyVolumes,emailId,industryType,subIndustryType,addressLineOne,addressLineTwo,addressLineThree,landmark,postalCode,city,state} = response.result
    setFormValues({
      businessName: businessName,
      dateOfIncorporation: dateOfIncorporation,
      businessPanNo: businessPanNo,
      constitutionType: constitutionType,
      location: location,
      monthlyVolumes: monthlyVolumes,
      emailId: emailId,
      industryType:industryType,
      subIndustryType: subIndustryType,
      gstNumber:gstNumber,
      addressLineOne:addressLineOne,
      addressLineTwo:addressLineTwo,
      addressLineThree:addressLineThree,
      landmark:landmark,
      postalCode:postalCode,
      city:city,
      state:state
        
    });
    if(industryType){
      getSubIndustryList(industryType); 
    }
    setIsAlreadySubmitted(true)
  }
  else{
    hideLoadingIndicator()
  }
}

const [errors, setErrors] = useState<any>({});
  const getValidator = (fieldName: any) => {
    switch (fieldName) {
        case 'businessPanNo':
          return validatePAN;
        case 'monthlyVolumes':
        case 'addressLineOne':
        case 'addressLineTwo':
        case 'addressLineThree':
        case 'landmark':
        case 'city':
            return whiteSpaceValid;
        case 'emailId':
            return validEmail;
        case 'gstNumber':
            return validateGst;
        case 'panNo':
            return validatePAN
        case 'postalCode':
           return validatePostalCode
      
        default:
            return () => null;
    }
};
  const handleInputChange = (fieldName:any,value: any) => {
    setIsFormChanged(true)
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [fieldName]: value
    }));
    if (mandatoryFields.includes(fieldName)) {

    const validatorFn = getValidator(fieldName);
        const error =  validatorFn(value);
        setErrors({
            ...errors,
            [fieldName]: error
        });
      }
  };


  const handleSelectChange = (name: string, value: string) => {
    setIsFormChanged(true)
    setFormValues((prevValues: any) => ({
      ...prevValues,
      [name]: value
    }));
    if (name === 'industryType') {
      getSubIndustryList(value);
    }
  };

  useEffect(() => {
    if (state) {
        const { registerType, enityType ,PartnershipType} = state;
        if (registerType === "Partner"  && enityType === "Individual" ) {
            setStepper(STEPPER_INDIVIDUAL)
        }else if(registerType === "Partner"  && (PartnershipType === "Partnership" || PartnershipType === "Private Ltd")){
            setStepper(STEPPER_INDIVIDUAL_DIRECTOR)
        }
    }
}, [STEPPER_INDIVIDUAL, STEPPER_INDIVIDUAL_DIRECTOR, state, stepper])
const handleNext = async () => {
    showLoadingIndicator()
  const reqObj = {
    businessName: formValues.businessName,
    dateOfIncorporation: formValues.dateOfIncorporation,
    businessPanNo: formValues.businessPanNo,
    constitutionType: formValues.constitutionType,
    monthlyVolumes: formValues.monthlyVolumes,
    emailId: formValues.emailId,
    industryType: formValues.industryType,
    subIndustryType: formValues.subIndustryType,
    gstNumber: formValues.gstNumber,
    addressLineOne: formValues.addressLineOne,
    addressLineTwo: formValues.addressLineTwo,
    addressLineThree: formValues.addressLineThree,
    landmark: formValues.landmark,
    postalCode: formValues.postalCode,
    city: formValues.city,
    state: formValues.state,

  }
  try {
    let response;

    if (isAlreadySubmitted === "") {
      response = await post({
        url: getCompanyPostApiUrl(registrationId),
        obj: reqObj
      });
    }
    else if (isAlreadySubmitted === true) {
      response = await post({
        url: getCompanyPostApiUrl(registrationId),
        obj: { ...reqObj, "id": company_id ? company_id : details?.id?details?.id:"" }
      });
    }

    if (response?.result) {
      hideLoadingIndicator();
      setLocalStorage('company_id', response.result.id)
    }
    if(isFormChanged && response?.result){
      hideLoadingIndicator();
      if(getLocalStorage("Application_EntityType")?.includes("Business") && !getLocalStorage('Application_PartnershipType')?.includes('Sole')){
        setValue(3)
    }else{
      setValue(2)
    }
    }else if(!isFormChanged){
      hideLoadingIndicator()
      if(getLocalStorage("Application_EntityType")?.includes("Business") && !getLocalStorage('Application_PartnershipType')?.includes('Sole')){
        setValue(3)
    }else{
      setValue(2)
    }
    }else{
      hideLoadingIndicator();
      showToast("something went wrong","error")
    }
  }
  catch {
    hideLoadingIndicator()
    showToast("something went wrong","error")
  }
  
};
const gridContainerStyle = css`
width: calc(100% + 30px);
@media(max-width:768px){
width: calc(100% + 20px);
}
`;
const formControlStyle = css`
  margin-bottom: 16px;
   width: 100%;
`;
const createValidator = (errorMessage: string) => (value: string) => dymanicWhiteSpaceValid(value, errorMessage);


useEffect(() => {
  const isFieldsFilled = mandatoryFields.every((field) => formValues[field]?.trim() !== '');
  const mandatoryFieldErrors = Object.keys(errors).filter((field) => mandatoryFields.includes(field));

  const isFormValid = mandatoryFieldErrors.every((field) => errors[field] === null);
  if (isFieldsFilled && isFormValid) {
      setIsNextButtonDisabled(false);
  } else {
      setIsNextButtonDisabled(true);
  }

}, [formValues, errors]);

  return (
    <div>
            {visible ? 
        <>{loadingComponent()}</> :
        <> 
    <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
    <Grid container spacing={2} css={gridContainerStyle}>
        
    {[
              { label: 'Business Name', name: 'businessName', type: 'text', placeholder: 'Enter Business Name', mandatory: true },
              { label: 'Date Of Incorporation', name: 'dateOfIncorporation', type: 'date',placeholder:'Enter Date', mandatory: true,max:getTodaysDate() },
              { label: 'Business PAN', name: 'businessPanNo', type: 'text', placeholder: 'Enter Business PAN', mandatory: true ,valid:validatePAN},
              { label: 'Constitution Type', name: 'constitutionType', type: 'select', options: constituteType, mandatory: true },
              { label: 'Monthly/Annual Volumes', name: 'monthlyVolumes', type: 'text', placeholder: 'Enter details', mandatory: true ,valid: createValidator('Invalid monthlyVolumes')},
              { label: 'Official Email ID', name: 'emailId', type: 'email', placeholder: 'Enter Official Email', mandatory: true,valid: validEmail},
              { label: 'GST Number', name: 'gstNumber', type: 'text', placeholder: 'Enter GST Number', mandatory: true ,valid:validateGst},
              { label: 'Address Line 1', name: 'addressLineOne', type: 'text', placeholder: 'Enter Address Line 1', mandatory: true ,valid: createValidator('Invalid address')},
              { label: 'Address Line 2', name: 'addressLineTwo', type: 'text', placeholder: 'Enter Address Line 2', mandatory: false ,valid: createValidator('Invalid address')},
              { label: 'Address Line 3', name: 'addressLineThree', type: 'text', placeholder: 'Enter Address Line 3', mandatory: false ,valid: createValidator('Invalid address')},
              { label: 'Landmark', name: 'landmark', type: 'text', placeholder: 'Enter Landmark', mandatory: false ,valid: createValidator('Invalid landmark')},
              { label: 'Postal Code', name: 'postalCode', type: 'text', placeholder: 'Enter postal code', mandatory: true ,valid:validatePostalCode},
              { label: 'City', name: 'city', type: 'text', placeholder: 'Enter city', mandatory: true ,valid: createValidator('Invalid city')},
              { label: 'State', name: 'state', type: 'select', options: stateList , mandatory: true},
              { label: 'Industry Type', name: 'industryType', type: 'select', options: industryType },
              { label: 'Sub - Industry Type', name: 'subIndustryType', type: 'select',options: subIndustryType}
            ].map((field, index) => (
              <>
                {field.type?.includes("select") ?

                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <CustomSelect
                      options={field.options}
                      placeholder="Please Select"
                      value={formValues[field.name]}
                      onChange={(event) => handleSelectChange(field.name, event.target.value)}
                      inputLabel={field.label}
                      css={formControlStyle}
                      mandatory={field.mandatory}
                    />
                  </Grid> :
                  <Grid item xs={12} sm={6} md={3} key={index}>
                    <CustomInput
                      value={formValues[field.name]}
                      onChange={(e) => handleInputChange(field.name ,(field.name ==='businessPanNo' || field.name ==='gstNumber')? e.target.value.toUpperCase(): e.target.value)}
                      type={field.type}
                      css={formControlStyle}
                      required
                      placeholder={field.placeholder}
                      name={field.name}
                      inputLabel={field.label}
                      mandatory={field.mandatory}
                      max={field.max}
                      validate={field.valid}
                    />
                      {/* {errors[field.name] && <div css={{ color: colors.crimson, fontSize: '12px', textAlign: "left", marginTop: "-10px" }}>{errors[field.name]}</div>} */}
                  </Grid>

                }

              
              </>
            ))}
            
       
   </Grid>
   </Box>
      <div css={registrationButtonContainer}>
          <CustomButton
            label={BACK_LABEL}
            onClick={() => setValue((pre:any) => pre - 1)}
            variant="outlined"
            css={regBackButton}
          />
          <CustomButton
            label={NEXT_LABEL}
            onClick={handleNext}
            css={!isNextButtonDisabled  ? regNextButton : regNextButtonDisabled}
          />
        </div>
        <ToastContainer />
        </>}
      </div>
  );
}

export default CompanyDetails;