import React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import { styled } from '@mui/material/styles';
import { icon } from '../../assets/Images';
import { colors } from '../../scss/colors';

const CustomBreadcrumbs = styled(Breadcrumbs)(({ theme }) => ({
  marginTop:5,
  '& .MuiBreadcrumbs-separator': {
    color: theme.palette.text.primary,
    backgroundImage: `url(${icon.navigationSeparatorRed})`,
    backgroundSize: 'contain',
    backgroundRepeat: 'no-repeat',
    width: 16,
    height: 16,
    display: 'inline-block',
    content: '""',
  },
  [theme.breakpoints.down('sm')]: {
    display: 'none', 
  },
}));

const CustomLink = styled(Link)(({ theme }) => ({
  color: colors.crimson,
  textDecoration: 'underline',
  fontWeight:'700',
  fontSize:'12px',
  '&:hover': {
    textDecoration: 'none',
  },
}));

const SubCustomLink = styled(Link)(({ theme }) => ({
  color: colors.lightBlack,
  textDecoration: 'underline',
  fontWeight:'700',
  fontSize:'12px',
  '&:hover': {
    textDecoration: 'none',
  },
}));

interface BreadcrumbProps {
  items: Array<{ label: string; href?: string ;subHref?:string}>;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ items }) => {
  return (
    <CustomBreadcrumbs separator=" ">
      {items.map((item, index) => (
        item.href ? (
          <CustomLink key={item.label} href={item.href}>
            {item.label}
          </CustomLink>
        ) : item.subHref ? (
          <SubCustomLink key={item.label} href={item.subHref} >
            {item.label}
          </SubCustomLink>
        ): (
          <Typography key={item.label} color={colors.charcoal} fontWeight="500" fontSize="12px" >
            {item.label}
          </Typography>
        )
      ))}
    </CustomBreadcrumbs>
  );
};

export default Breadcrumb;
