
import { css } from '@emotion/react';
import { colors, typography } from '../../scss/colors';


export const OtpContainer = css`
    display: flex;

    @media (max-width:768px){
    flex-direction: column;
    }
    `
export const sendOtpLabel = css`
cursor:pointer;
margin:30px;
border-bottom:1px solid ${colors.crimson};
color: ${colors.crimson};
${typography('medium', '14px')};
@media (max-width:768px){
    margin:0px;
        width: fit-content;
    }
`

export const disabledSendOptLabel = css`
margin:30px;
color: ${colors.lightred};
${typography('medium', '14px')};
cursor:none;
pointer-events: none;
border-bottom:1px solid ${colors.lightred};
@media (max-width:768px){
   margin:0px;
        width: fit-content;
    }`

export const timerText = css`
margin-left:10px;
color: ${colors.black};
`
export const disabledResendOptLabel = css`
color: ${colors.lightGray};
margin:30px;
${typography('medium', '14px')};
cursor:none;
pointer-events: none;

@media (max-width:768px){
margin:0px;
}
`
export const errorStyle = css`
color: ${colors.crimson};
float:left;
${typography('medium', '14px')};

}`
export const resendsendOtpLabel = css`
cursor:pointer;
margin:30px;
color: ${colors.crimson};
${typography('medium', '14px')};`

export const verifyImgsection = css`
    margin-top: 27px;
    margin-left: -20px;
    @media (max-width : 768px){
    margin-top:0px;
    margin-left:0px;
    }
    `

export const successIcon = css`
color:${colors.benchmark}
`
export const errorIcon = css`
color:${colors.buttonOrange}
`