/** @jsxImportSource @emotion/react */
import React from 'react';
import { Box, Grid, Typography, Card, CardContent, AvatarGroup, Avatar } from '@mui/material';
import DashboardLayout from '../../components/DashbaordLayout/DashbaordLayout';
import { icon } from '../../assets/Images';
import {
    headerStyles,
    sectionTitleStyles,
    sectionContainerStyles,
    cardStyles,
    itemTitleStyles,
    itemDateStyles,
    itemUpdatedStyles,
    avatarGroupStyles,
    itemInfoStyles,
    itemInfoDivStyles,
    overviewStyles,
    gridItemStyles,
    gridContainerStyles,
    boxMarginBottomStyles,
    itemInfoDivContainerStyles,
  } from './Overview.style';
const sections = [
  {
    title: "Application Collection",
    color: "#FF8D23",
    items: [
      { title: "Details Verification", date: "21/08/2023", updated: "30 mins ago", avatars: ["/avatar1.jpg", "/avatar2.jpg"], comments: 1, tasks: 0 },
      { title: "ID Verification, Penny Drop", date: "21/08/2023", updated: "30 mins ago", avatars: ["/avatar3.jpg"], comments: 2, tasks: 1 },
      { title: "CBL Score Fetch", date: "21/08/2023", updated: "30 mins ago", avatars: [], comments: 1, tasks: 1 }
    ]
  },
  {
    title: "Evidence Review",
    color: "#0079EA",
    items: [
      { title: "FCU Verification", date: "21/08/2023", updated: "30 mins ago", avatars: ["/avatar4.jpg", "/avatar5.jpg"], comments: 1, tasks: 2 },
      { title: "CPU Verification", date: "21/08/2023", updated: "30 mins ago", avatars: ["/avatar6.jpg"], comments: 1, tasks: 2 }
    ]
  },
  {
    title: "Progress",
    color: "#3BB727",
    items: [
      { title: "Payout Structure", date: "21/08/2023", updated: "30 mins ago", avatars: ["/avatar7.jpg"], comments: 1, tasks: 2 },
      { title: "Sanction", date: "21/08/2023", updated: "30 mins ago", avatars: [], comments: 1, tasks: 1 },
      { title: "E-Sign", date: "21/08/2023", updated: "30 mins ago", avatars: ["/avatar8.jpg"], comments: 0, tasks: 1 }
    ]
  }
];



const Overview = () => {
  return (
    <DashboardLayout>
      <Box css={overviewStyles}>
        <Typography variant="h4" gutterBottom css={headerStyles}>Overview</Typography>
        <Grid container spacing={3} css={gridContainerStyles}>
          {sections.map((section, index) => (
            <Grid item xs={12} md={4} key={index} css={gridItemStyles}>
              <Box css={boxMarginBottomStyles}>
                <Typography variant="h6" css={sectionTitleStyles(section.color)}>
                  {section.title}
                  <span>{section.items.length}</span>
                </Typography>
              </Box>
              <div css={sectionContainerStyles}>
                {section.items.map((item, idx) => (
                  <Card css={cardStyles} key={idx}>
                    <CardContent>
                      <Typography variant="h6" css={itemTitleStyles}>{item.title}</Typography>
                      <Typography variant="body2" css={itemDateStyles}>Due Date: {item.date}</Typography>
                      <Typography variant="body2" css={itemUpdatedStyles}>Last Updated: {item.updated}</Typography>
                      <Box css={itemInfoStyles}>
                        <Typography variant="body2" css={itemInfoDivContainerStyles}>
                          <div css={itemInfoDivStyles}><img src={icon.pin} height="12" alt="no_pin"/> {item.comments}</div>
                          <div css={itemInfoDivStyles}><img src={icon.comments} height="12" alt="no_comments"/> {item.tasks}</div>
                        </Typography>
                        <AvatarGroup max={4} css={avatarGroupStyles}>
                          {item.avatars.map((avatar, i) => (
                            <Avatar alt={`Avatar ${i + 1}`} src={avatar} key={i} />
                          ))}
                        </AvatarGroup>
                      </Box>
                    </CardContent>
                  </Card>
                ))}
              </div>
            </Grid>
          ))}
        </Grid>
      </Box>
    </DashboardLayout>
  );
};

export default Overview;
