/** @jsxImportSource @emotion/react */
import { useEffect, useState } from "react";
import { css } from "@emotion/react";
import CustomButton from "../../../components/Button/Button";
import CustomInput from "../../../components/Input/Input";
import { regBackButton, regNextButton, registrationButtonContainer } from "../../Registration/Registration.style";
import { colors, typography } from "../../../scss/colors";
import { StringConstants } from "../../../constants/Strings";
import { getLocalStorage } from '../../../utils/browserHandlers/browserStorageHandler';
import { get, post } from '../../../apiManger/apiMethods';
import { getSanction2GETApiUrl, getSanctionGETApiUrl, getSanctionPostApiUrl, getSanctionTwoPostApiUrl, getUploadPayoutDocsGETApiUrl } from '../../../apiManger/apiConstants';
import Loder from "../../../components/Loader/Loder";
import { useToastHook } from "../../../components/Toast/useToastHook";
import { ToastContainer } from "react-toastify";
import { Navigate, useNavigate } from "react-router-dom";

const sanctionSection = css`
text-align:left;
`;

const SanctionLabel = css`
color: ${colors.lightBlack};
${typography('600', '18px')};
margin-bottom:10px;
`;

const sanctionApproved = css`
color: ${colors.crimson};
${typography('600', '18px')};
`;

const commentLabel = css`
color: ${colors.tableLabel};
${typography('500', '16px')};
width:9%;
`;

const commentValue = css`
color: ${colors.tableLabel};
${typography('400', '16px')};
width:91%;
`;

const commentSection = css`
display:flex;
width:100%;
margin-bottom:20px;
`;

const Sanction = (props: any) => {
    const navigate = useNavigate()
    const { showToast } = useToastHook()
    const { isSanction2Path, handleSanctionNext } = props;
    const { COMMENTVALUE, COMMENT, SANCTION_APPROVED } = StringConstants;
    const [approveBy,setApprovedBy] = useState("")

    const [comment, setComment] = useState("");
    const [prevComment, setPrevComment] = useState<any>();
    const [idval, setIdVal] = useState<any>("");
    const {
        showLoadingIndicator,
        hideLoadingIndicator,
        loadingComponent,
        visible,
    } = Loder();
    const registrationId = getLocalStorage('Application_registrationId');

    useEffect(() => {
        if (isSanction2Path) {
            getSaction2APICall()
        }
        getAPICall();
    }, [])

    const getAPICall = async () => {
        showLoadingIndicator();
        const response = await get({
            url: getSanctionGETApiUrl(registrationId)
        });
        if (response.result) {
            hideLoadingIndicator()
            setIdVal(response.result.id)
            setPrevComment(response.result.comment)
            setApprovedBy(response.result?.actionBy)
            if (!isSanction2Path) {
                setComment(response.result.comment)
            }
        }
        else {
            hideLoadingIndicator()
            console.log('error')
        }
    }
    const getSaction2APICall = async () => {
        showLoadingIndicator();
        const response = await get({
            url: getSanction2GETApiUrl(registrationId)
        });
        if (response.result) {
            hideLoadingIndicator()
            setIdVal(response.result.id)
            setComment(response.result.comment)
        }
        else {
            hideLoadingIndicator()
            console.log('error')
        }
    }

    const handleApprove = async (approvalstatus: any) => {
        showLoadingIndicator();
        let payload: any
        if (idval !== "") {
            payload = {
                registrationId,
                comment,
                sanctionStatus: approvalstatus,
                status: 1,
                id: idval
            };
        }
        else {
            payload = {
                registrationId,
                comment,
                sanctionStatus: approvalstatus,
                status: 1,
            };
        }
        try {
            const response = await post({
                url: isSanction2Path ? getSanctionTwoPostApiUrl() : getSanctionPostApiUrl(),
                obj: payload,
            });

            if (response.result?.screenName) {
                showToast(approvalstatus == "Rejected" ? "Rejected successfully" : "Approved successfully", "success")
                isSanction2Path ? navigate("/e-sign") : navigate("/sanction-2")
                hideLoadingIndicator();
            } else if (response?.error?.message) {
                showToast(response?.error?.message, "error")
                hideLoadingIndicator();
            }
        } catch (error) {
            showToast("something went wrong", "error")
            hideLoadingIndicator();
        }
    };

    return (
        <div>
            {visible ?
                <>{loadingComponent()}</> :
                <>
                    {isSanction2Path && (
                        <div css={sanctionSection}>
                            <div css={SanctionLabel}>{SANCTION_APPROVED}  <span css={sanctionApproved}> {approveBy}</span></div>
                            <div css={commentSection}>
                                <div css={commentLabel}>{COMMENT} :</div>
                                <div css={commentValue}>{prevComment}</div>
                            </div>
                        </div>
                    )}
                    <CustomInput
                        inputLabel={isSanction2Path ? "Action" : "Comment"}
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                        type="text"
                        required
                        placeholder="Enter your comment"
                    />
                    <div css={registrationButtonContainer}>
                        <CustomButton
                            label="Reject"
                            variant="outlined"
                            css={regBackButton}
                            onClick={() => handleApprove('Rejected')}

                        />
                        <CustomButton
                            label="Approve"
                            css={regNextButton}
                            onClick={() => handleApprove('Approved')}
                        />
                    </div>
                </>}
            <ToastContainer />
        </div>
    );
};

export default Sanction;
