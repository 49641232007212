/** @jsxImportSource @emotion/react */
import { useState } from 'react';
import CustomInput from '../../../components/Input/Input';
import CustomButton from '../../../components/Button/Button';
import { css } from '@emotion/react';
import { Box, Grid } from '@mui/material';
import { ToastContainer } from 'react-toastify';
import { useToastHook } from '../../../components/Toast/useToastHook';
import Loder from '../../../components/Loader/Loder';
import { StringConstants } from '../../../constants/Strings';
import { whiteSpaceValid } from '../../../constants/Validations';
import { regBackButton, regNextButton, regNextButtonDisabled, registrationButtonContainer } from '../../Registration/Registration.style';
import { icon } from '../../../assets/Images';

const IdVerification = (props: any) => {
    const { showToast } = useToastHook();
    const { setValue, value } = props;

    // Separate state variables for each field's value
    const [individualPAN, setIndividualPAN] = useState('ABCDE1234E');
    const [aadhar, setAadhar] = useState('123456789123');
    const [businessPAN, setBusinessPAN] = useState('ABCDE1234E');
    const [emailVerification, setEmailVerification] = useState('pooja@gmail.com');
    const [bankAccount, setBankAccount] = useState('532763278381289');

    // Separate state variables for each field's verification status
    const [individualPANVerified, setIndividualPANVerified] = useState(true);
    const [aadharVerified, setAadharVerified] = useState(true);
    const [businessPANVerified, setBusinessPANVerified] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [bankAccountVerified, setBankAccountVerified] = useState(false);

    const { BACK_LABEL, NEXT_LABEL } = StringConstants;

    const {
        showLoadingIndicator,
        hideLoadingIndicator,
        loadingComponent,
        visible,
    } = Loder();

    const handleNext = async () => {
        showLoadingIndicator();
        try {
            hideLoadingIndicator();
            setValue(value + 1);
        } catch (error) {
            hideLoadingIndicator();
            showToast('Something went wrong', 'error');
        }
    };

    const handleBack = () => {
        setValue((prev: any) => prev - 1);
    };

    // Update value and verification status
    const handleInputChange = (field: string, value: string) => {
        switch (field) {
            case 'individualPAN':
                setIndividualPAN(value);
                setIndividualPANVerified(value.length > 0); // Assume verified if the field has a value
                break;
            case 'aadhar':
                setAadhar(value);
                setAadharVerified(value.length > 0);
                break;
            case 'businessPAN':
                setBusinessPAN(value);
                setBusinessPANVerified(value.length > 0);
                break;
            case 'emailVerification':
                setEmailVerification(value);
                setEmailVerified(value.length > 0);
                break;
            case 'bankAccount':
                setBankAccount(value);
                setBankAccountVerified(value.length > 0);
                break;
            default:
                break;
        }
    };

    const gridContainerStyle = css`
        width: calc(100% + 30px);
        @media (max-width: 768px) {
            width: calc(100% + 20px);
        }
    `;
    const formControlStyle = css`
        margin-bottom: 16px;
        width: 100%;
    `;

    const renderVerificationStatus = (verified: boolean) => {
        return verified ? (
            <span style={{ color: 'green', marginLeft: '-12%' ,zIndex:'99',marginTop:'6px'}}><img src={icon.GreenCheckIcon}/></span>
        ) : (
            <span style={{ color: 'red', marginLeft: '-12%',zIndex:'99',marginTop:'6px' }}><img src={icon.ErrorIcon}/></span>
        );
    };

    return (
        <div>
            {visible ? (
                <>{loadingComponent()}</>
            ) : (
                <>
                    <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
                        <Grid container spacing={2} css={gridContainerStyle}>
                            <Grid item xs={12} sm={6} md={3} style={{ display: 'flex', alignItems: 'center' }}>
                                <CustomInput
                                    inputLabel="Individual PAN"
                                    value={individualPAN}
                                    onChange={(e) => handleInputChange('individualPAN', e.target.value)}
                                    type="text"
                                    css={formControlStyle}
                                    required
                                    placeholder="Individual PAN"
                                    disabled
                                />
                                {renderVerificationStatus(individualPANVerified)}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ display: 'flex', alignItems: 'center' }}>
                                <CustomInput
                                    inputLabel="Aadhar"
                                    value={aadhar}
                                    onChange={(e) => handleInputChange('aadhar', e.target.value)}
                                    type="text"
                                    css={formControlStyle}
                                    required
                                    placeholder="Aadhar"
                                    disabled
                                />
                                {renderVerificationStatus(aadharVerified)}
                            </Grid>
                            <Grid item xs={12} sm={6} md={3} style={{ display: 'flex', alignItems: 'center' }}>
                                <CustomInput
                                    inputLabel="Business PAN"
                                    value={businessPAN}
                                    onChange={(e) => handleInputChange('businessPAN', e.target.value)}
                                    type="text"
                                    css={formControlStyle}
                                    required
                                    placeholder="Business PAN"
                                    disabled
                                />
                                {renderVerificationStatus(businessPANVerified)}
                            </Grid>
                            {/* <Grid item xs={12} sm={6} md={3} style={{ display: 'flex', alignItems: 'center' }}>
                                <CustomInput
                                    inputLabel="E-mail Verification"
                                    value={emailVerification}
                                    onChange={(e) => handleInputChange('emailVerification', e.target.value)}
                                    type="text"
                                    css={formControlStyle}
                                    required
                                    placeholder="Enter E-mail"
                                    disabled
                                />
                                {renderVerificationStatus(emailVerified)}
                            </Grid> */}
                            <Grid item xs={12} sm={6} md={3} style={{ display: 'flex', alignItems: 'center' }}>
                                <CustomInput
                                    inputLabel="Bank Account"
                                    value={bankAccount}
                                    onChange={(e) => handleInputChange('bankAccount', e.target.value)}
                                    type="text"
                                    css={formControlStyle}
                                    required
                                    placeholder="Bank Account"
                                    disabled
                                />
                                {renderVerificationStatus(bankAccountVerified)}
                            </Grid>
                        </Grid>
                    </Box>

                    <div css={registrationButtonContainer}>
                        <CustomButton
                            label={BACK_LABEL}
                            onClick={handleBack}
                            variant="outlined"
                            css={regBackButton}
                        />
                        <CustomButton
                            label={NEXT_LABEL}
                            onClick={handleNext}
                            css={regNextButton}
                        />
                    </div>
                    <ToastContainer />
                </>
            )}
        </div>
    );
};

export default IdVerification;
