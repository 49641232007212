/** @jsxImportSource @emotion/react */
import  { useState, ChangeEvent, useEffect } from 'react';
import CustomInput from '../../../components/Input/Input';
import CustomButton from '../../../components/Button/Button';
import { colors, typography } from '../../../scss/colors';
import { css } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import { Box, Grid } from '@mui/material';
import { regBackButton, regNextButton, regNextButtonDisabled, registrationButtonContainer, regiterTypeSection } from '../../Registration/Registration.style';
import { StringConstants } from '../../../constants/Strings';
import CustomSelect from '../../../components/Select/Select';
import { icon } from '../../../assets/Images';
import { utilsConstants } from '../../../utils/utilsConstants';
import { getLocalStorage, setLocalStorage } from '../../../utils/browserHandlers/browserStorageHandler';
import { get, post } from '../../../apiManger/apiMethods';
import { getBankGETApiUrl, getBankPostApiUrl, getBankVerifyPOSTApiUrl } from '../../../apiManger/apiConstants';
import Loder from '../../../components/Loader/Loder';
import { useToastHook } from '../../../components/Toast/useToastHook';
import { ToastContainer } from 'react-toastify';


const bankSaveStyle = css`
  margin-top: 22px;
  text-align: left;
  display: flex;
  align-items: center;
  @media (max-width:768px){
  margin-top:0px;
  }
`;



const gridContainerStyle = css`
width: calc(100% + 30px);
@media(max-width:768px){
width: calc(100% + 20px);
}
`;
const formControlStyle = css`
margin-bottom: 16px;
width: 100%;
`;
const saveValidateButtonStyle = (props: { disabled: boolean }) => css`
  background-color: ${colors.white};
  color: ${colors.crimson};
  padding: 10px 20px;
  border: 1px solid ${colors.crimson};
  border-radius: 4px;
  cursor: pointer;
  opacity: ${props.disabled ? 0.5 : 1}; 
  ${typography('bold', '16px')};
   @media(max-width:768px){
width: 100%;
}
  &:disabled {
    background-color: white;
    cursor: not-allowed;
  }
  &:hover:enabled {
    background-color: ${colors.crimson};
    color: ${colors.white};
  }
`;

const successMessageStyle = css`
  color: ${colors.lightGray};
  margin-left: 15px;
  ${typography('bold', '14px')};
  padding-right:10px;
`;

const errorMessageStyle = css`
  color: ${colors.lightGray};
  margin-left: 15px;
  ${typography('bold', '14px')};
  padding-right:10px;
`;

const messageContainerStyle = css`
  display: flex;
  align-items: center;
  margin-left: 15px;
`;
const registrationOtpContainer = css`
  padding:10px;
  @media (max-width:768px){
 padding:0px;
  }
`
const BankDetails = (props:any) => {
    const entityType = getLocalStorage('Application_EntityType');
    const partnershipTypeLS =  getLocalStorage('Application_PartnershipType')
    const{setValue,details,registrationId} = props;
    const { state } = useLocation();
    const bank_id = getLocalStorage('bank_id');
    const [status, setStatus] = useState<any>()
    const {
        showLoadingIndicator,
        hideLoadingIndicator,
        loadingComponent,
        visible,
      } = Loder();
    useEffect(() => {
        if(details?.id){
            setIsAlreadySubmitted(true)
        }
            getAPICall();
     
    }, [])
    const { BACK_LABEL, NEXT_LABEL } = StringConstants;
    const { STEPPER,STEPPER_INDIVIDUAL,STEPPER_INDIVIDUAL_DIRECTOR ,BUSINESS_ACCOUNT_TYPE,ACCOUNT_TYPE} = utilsConstants;
    const [stepper, setStepper] = useState(STEPPER)
    const [formValues, setFormValues] = useState<any>({
        accountNo: details.accountNo,
        ifscCode: details.ifscCode,
        accountHolderName: details.accountHolderName,
        accountType: details.accountType,
        bankName: details.bankName,
        branchName: details.branchName,
        status:details.status
    });

    const [validationMessage, setValidationMessage] = useState<string | null>(details.status == 0 ?"Bank Details Verification Failed":details.status == 1 ? "Bank Details Verification Successful!" :null);
    const [isValidationSuccessful, setIsValidationSuccessful] = useState<any>(details.status == 0 ? false:details.status == 1 ?true :'');
    const [isFieldModified, setIsFieldModified] = useState(false);
    const [isAlreadySubmitted, setIsAlreadySubmitted] = useState<any>('')
    const {showToast} = useToastHook()
    const mandatoryFields = [
        'accountNo',
        'ifscCode',
        'accountHolderName',
        'accountType',
        'bankName'
    ];

  const isFormComplete = () => {
    return mandatoryFields.every((field) => {
        const value = formValues[field];
        return value !== undefined && value.trim() !== '';
    });
};

const getAPICall = async () => {
    showLoadingIndicator();
    const response = await get({
        url: getBankGETApiUrl(registrationId, bank_id ?? details?.id)
    });
    if(response?.result){
        hideLoadingIndicator();
    const { accountNo, ifscCode, accountHolderName, accountType, bankName, branchName ,status} = response.result
    setFormValues({
        accountNo: accountNo,
        ifscCode: ifscCode,
        accountHolderName: accountHolderName,
        accountType: accountType,
        bankName: bankName,
        branchName: branchName,
        status:status 
    });
    setIsAlreadySubmitted(true)
    if(status == 0 ){
        setValidationMessage("Bank Details Verification Failed")
        setIsValidationSuccessful(false)
    }else if(status == 1){
        setValidationMessage("Bank Details Verification Successful!")
        setIsValidationSuccessful(true)
    }
}
else{
    hideLoadingIndicator();
}
}
 

    const handleInputChange = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setFormValues((prevValues: any) => ({
            ...prevValues,
            [name]: value
        }));
        setIsFieldModified(true);
        setValidationMessage("");
    };

    const handleSelectChange = (name: string, value: string) => {
        setFormValues((prevValues: any) => ({
            ...prevValues,
            [name]: value
        }));
        setIsFieldModified(true);
        setValidationMessage("");
    };

    const handleSaveValidate = async () => {
        showLoadingIndicator();
        try {
            const verifyPayload = {
                context_id: getLocalStorage('application_number'),
                type: "BANK_ACCOUNT_NUMBER",
                uid: formValues.accountNo,
                ifsc_code: formValues.ifscCode,
                created_for: "SKILL MINE"
            };

            const response = await post({
                url: getBankVerifyPOSTApiUrl(),
                obj: verifyPayload
            });

            if (response?.result?.status === 'VALID') {
                hideLoadingIndicator()
                setValidationMessage("Bank Details Verification Successful!");
                setIsValidationSuccessful(true);
                setStatus(1)
                setFormValues((prevValues: any) => ({
                    ...prevValues,
                    ['status']: 1
                }));
            } else {
                hideLoadingIndicator()
                setValidationMessage("Bank Details Verification Failed");
                setIsValidationSuccessful(false);
                setStatus(0)
                setFormValues((prevValues: any) => ({
                    ...prevValues,
                    ['status']: 0
                }));
            }
        } catch (error) {
            hideLoadingIndicator()
            console.error("Bank details validation failed:", error);
            setValidationMessage("Bank Details Verification Failed");
            setIsValidationSuccessful(false);
            setStatus(0)
            setFormValues((prevValues: any) => ({
                ...prevValues,
                ['status']: 0
            }));
        }
    };

    const buttonDisable =isFieldModified && isFormComplete() 

    useEffect(() => {
        if (state) {
            const { registerType, enityType ,PartnershipType} = state;
            if (registerType === "Partner"  && enityType === "Individual" ) {
                setStepper(STEPPER_INDIVIDUAL)
            }else if(registerType === "Partner"  && (PartnershipType === "Partnership" || PartnershipType === "Private Ltd")){
                setStepper(STEPPER_INDIVIDUAL_DIRECTOR)
            }
            
        }
    }, [STEPPER_INDIVIDUAL, STEPPER_INDIVIDUAL_DIRECTOR, state, stepper])
    const handleNext = async () => {
        showLoadingIndicator();
            const reqObj = {
                registrationId: registrationId,
                accountNo: formValues.accountNo,
                ifscCode: formValues.ifscCode,
                accountHolderName: formValues.accountHolderName,
                accountType: formValues.accountType,
                bankName: formValues.bankName,
                branchName: formValues.branchName,
                status: status ?? formValues.status

            }
            try {
                let response;
                if (isAlreadySubmitted === "") {
                    response = await post({
                        url: getBankPostApiUrl(registrationId),
                        obj: reqObj
                    });
                }
                else if (isAlreadySubmitted === true) {
                    response = await post({
                        url: getBankPostApiUrl(registrationId),
                        obj: { ...reqObj, "id": bank_id ? bank_id : details?.id ? details?.id :""}
                    });
                }


                if (response.result) {
                    hideLoadingIndicator();
                    setLocalStorage('bank_id', response.result.id)
                 
                }
                if(isFieldModified && response?.result){
                    hideLoadingIndicator();

                    if(getLocalStorage("Application_EntityType")?.includes("Business")){
                        setValue(4)
                    }else{
                    setValue(3)
                    }
                  }else if(!isFieldModified){
                    hideLoadingIndicator()
                    if(getLocalStorage("Application_EntityType")?.includes("Business")){
                        setValue(4)
                    }else{
                    setValue(3)
                    }
                  }else{
                    hideLoadingIndicator()
                    showToast("something went wrong","error")
                  }
            }
            catch (error) {
                showToast("something went wrong","error")
               
            }
    }
    
    return (
        <div css={registrationOtpContainer}>
                {visible ? 
        <>{loadingComponent()}</> :
        <>   <Box sx={{ flexGrow: 1, paddingBottom: '40px' }}>
                    <Grid container spacing={2} css={gridContainerStyle}>
                        {[
                            { label: 'Account No', name: 'accountNo', type: 'text', placeholder: 'Enter Account No' },
                            { label: 'IFSC Code', name: 'ifscCode', type: 'text', placeholder: 'Enter IFSC Code' },
                            { label: 'Account Holder Name', name: 'accountHolderName', type: 'text', placeholder: 'Enter Account Holder Name' },
                            { label: 'Account Type', name: 'accountType', type: 'select', options: entityType.includes("Business") && !partnershipTypeLS.includes("Proprietorship") ? BUSINESS_ACCOUNT_TYPE : ACCOUNT_TYPE },
                            { label: 'Bank Name', name: 'bankName', type: 'text', placeholder: 'Enter Bank Name' },
                            { label: 'Branch Name', name: 'branchName', type: 'text', placeholder: 'Enter Branch Name' }
                        ].map((field, index) => (
                            <Grid item xs={12} sm={6} md={3} key={index} >
                                {field.type === 'select' ? (
                                        <CustomSelect
                                            options={field.options}
                                            placeholder="Please Select"
                                            value={formValues[field.name]}
                                            onChange={(event) => handleSelectChange(field.name, event.target.value)}
                                            inputLabel={field.label}
                                            mandatory
                                            css={formControlStyle}
                                        />
                                ) : (
                                    <>
                                        <CustomInput
                                            value={formValues[field.name]}
                                            onChange={handleInputChange}
                                            type={field.type}
                                            css={formControlStyle}
                                            required={mandatoryFields.includes(field.name)}
                                            placeholder={field.placeholder}
                                            name={field.name}
                                            inputLabel={field.label}
                                            mandatory={!field.name.includes("branchName")}
                                        />
                                    </>
                                )}
                            </Grid>
                        ))}
                        <Grid item xs={12} sm={6} md={6}  sx={{
    paddingLeft: '15px !important',
  }}>
                            <div css={bankSaveStyle}>
                                <button
                                    css={saveValidateButtonStyle({ disabled: !buttonDisable })}
                                    onClick={handleSaveValidate}
                                    disabled={!buttonDisable}
                                >
                                    Save & Validate
                                </button>
                                {validationMessage && (
                                    <div css={messageContainerStyle}>
                                        <span css={isValidationSuccessful ? successMessageStyle : errorMessageStyle}>
                                            {validationMessage}
                                        </span>
                                        {isValidationSuccessful ? <img src={icon.GreenCheckIcon} alt="no_green_img" /> : <img src={icon.ErrorIcon} alt="no_error_img"/>}

                                    </div>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Box>

                <div css={registrationButtonContainer}>
                    <CustomButton
                        label={BACK_LABEL}
                        onClick={() => setValue((pre:any) => pre - 1)}
                        variant="outlined"
                        css={regBackButton}
                    />
                    <CustomButton
                        label={NEXT_LABEL}
                        onClick={() => handleNext()}
                        css={((isValidationSuccessful === true || isValidationSuccessful === false)) ? regNextButton : regNextButtonDisabled}
                    />
                </div>
                </>}
                <ToastContainer />
            </div>
    );
};

export default BankDetails;
