import { css } from '@emotion/react';
import { colors, typography, typographyInter } from '../../scss/colors';
import { icon } from '../../assets/Images';

export const containerStyle = css`
  display: flex;
  height: 100vh;
  @media(max-width:768px){
 
  }
`;

export const welcomeSectionStyle = css`
  background: url(${icon.loginbg}) no-repeat center center;
  background-size: cover;
  flex: 1.8;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 20px 40px;
  text-align:left;
  @media(max-width:768px){
  display:none;
  }
`;

export const welcomeTitleStyle = css`
  margin-bottom: 20px;
  ${typographyInter('bold', '60px')};
  color: ${colors.white};
  
`;

export const welcomeTextStyle = css`
  ${typographyInter('medium', '18px')};
  color: ${colors.bluereflection};
  text-align: justify;

  
`;

export const loginSectionStyle = css`
  flex: 3;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: ${colors.washme}; 
  padding-left:60px;
  overflow-y: auto;
  @media(max-width:768px){
  padding: 0px 15px;
  justify-content:center;
  align-items: center;
    background-color: ${colors.white}; 
  }
`;

export const loginFormStyle = css`
  width: 60%;
  text-align: left;
  padding-left: 50px;
  @media(max-width:768px){
  width:100%;
  padding-left:0px;
  text-align:center
  }
`;

export const titleStyle = css`
  margin-bottom: 10px;
  ${typographyInter('600', '52px')};
  color: ${colors.narwhalgrey};
   @media(max-width:768px){
  ${typographyInter ('600', '24px')};
  }
`;

export const descriptionStyle = css`
  margin-bottom: 40px;
  ${typographyInter('regular', '18px')};
  color: ${colors.placeholder};
   @media(max-width:768px){
  ${typographyInter ('400', '14px')};
  margin-bottom: 20%;
  color : ${colors.placeholder}
  }
`;

export const inputStyle = css`
  margin-bottom: 30px;
  width: 100%;
  .MuiInputBase-root {
    background: none;
    height: 50px;
    width: 100%;
    border: none;
    border-radius: 0px;
  }
  .MuiOutlinedInput-root {
    &.Mui-focused .MuiOutlinedInput-notchedOutline {
      border-color: ${colors.bloodburst};
    }
    fieldset {
      border-bottom: 1px solid ${colors.bluereflection}; 
      border-top: none;
      border-left: none;
      border-right: none;
    }
  }
  .MuiInputLabel-root.Mui-focused {
    color: ${colors.bloodburst};
  }
`;

export const optionsStyle = css`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
  ${typographyInter('regular', '14px')};

   @media(max-width:768px){
    margin-bottom: 30px;
  }
`;

export const signInButtonStyle = css`
  background-color: ${colors.poppypower};
  color: white;
  border: none;
  padding: 10px;
  width: 20%;
  cursor: pointer;
  ${typographyInter('bold', '16px')};
  height: auto;
  border-radius: 8px;
   @media(max-width:768px){
   width:100%;
   height: 48px;
  }
  &:hover {
    background-color: ${colors.redpower};
  }
`;

export const signInDisabledButtonStyle = css`
  background-color: ${colors.lightred};
  color: white;
  border: none;
  padding: 10px;
  width: 20%;
  pointer-events:none;
  ${typographyInter('bold', '16px')};
  height: auto;
  border-radius: 8px;
   @media(max-width:768px){
   width:100%;
   height: 48px;
  }
  &:hover {
    background-color: ${colors.lightred};
  }
`;

export const footerStyle = css`
  display: flex;
  justify-content: center;
  margin-top: 10px;
  ${typographyInter('regular', '14px')};
  color: ${colors.saltboxblue};
   @media(max-width:768px){
    margin-top:60%;
  }
`;

export const linkStyle = css`
  color: ${colors.crimson};
  text-decoration: none;
`;

export const ResendOtpText = css`
cursor:pointer;
 ${typographyInter('regular', '14px')};
  color: ${colors.crimson};
`
export const ResendOtpTextDisable = css`
cursor:none;
 ${typographyInter('regular', '14px')};
  color: ${colors.placeholder};
  pointer-events:none;
`

export const verifySection = css`
  
   @media(max-width:768px){
display: flex;
    align-items: center;
    gap: 10%;
    justify-content: flex-start;
    margin-bottom: 20px;
}
`
export const NavigateImg = css`
  display:none;
   @media(max-width:768px){
display: flex;
}
`
