/** @jsxImportSource @emotion/react */
import {  useEffect, useState } from "react";
import TabsComponent from "../../../components/TabsComponent/TabsComponent";
import RegistrationDetails from "../../ApplicationReview/InitiateKyc/RegistrationDetails";
import DirectorDetails from "../../ApplicationReview/InitiateKyc/DirectorDetails";
import CompanyDetails from "../../ApplicationReview/InitiateKyc/CompanyDetails";
import BankDetails from "../../ApplicationReview/InitiateKyc/BankDetails";
import KycDocumentes from "../../ApplicationReview/InitiateKyc/KycDetails";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import { InitiateKycDetailsSection } from "../../ApplicationReview/InitiateKyc/InitiateKyc.style";
import PayoutStructure from "./PayoutStructure";
import Sanction from "./Sanction";
import UploadCpv from "../../ApplicationReview/InitiateCpv/UploadCpv";
import { getLocalStorage } from "../../../utils/browserHandlers/browserStorageHandler";
import { backCss, mobileNavigation } from "../../ApplicationReview/InitiateKyc/InitiateKycDetails";
import { icon } from "../../../assets/Images";
import { regBackButton, registrationButtonContainer, regNextButton } from "../../Registration/Registration.style";
import CustomButton from "../../../components/Button/Button";
import IndividualDetails from "../../ApplicationReview/InitiateKyc/IndividualDetails";
import { useNavigate } from "react-router-dom";
import { post } from "../../../apiManger/apiMethods";
import { getCompanyKYCPostApiUrl, getPartnerKYCPostApiUrl } from "../../../apiManger/apiConstants";
import Loder from "../../../components/Loader/Loder";
import { useToastHook } from "../../../components/Toast/useToastHook";
import { ToastContainer } from "react-toastify";
import IdVerification from "../../ApplicationReview/InitiateKyc/IdVerification";
import Cibil from "../../ApplicationReview/InitiateKyc/Cibil";
const Sanction1Details = (props: any) => {
  const {showToast} = useToastHook()
  const navigate = useNavigate()
  const { showDetails,detailsData ,setShowDetails} = props;
  const [value, setValue] = useState(0);
  const {registrationDetails,bankDetails,individualDetails,companyDetails,directorDetails,companyKycDetails,partnerKYCDetails}=detailsData.data
  const [uploadedDocuments, setUploadedDocuments] = useState<any>([]);
  const [companyUploadDocuments, setCompanyUploadDocuments] = useState<any>([]);
  const [entityType, setEntityType] = useState('');
  const [partner, setPartner] = useState('');
  const breadcrumbItems = [
    { label: 'Sanction', href: '/sanction' },
    { label: showDetails.ApplicationNo }
  ];
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
} = Loder();
  

const handleNext = async() => {
  showLoadingIndicator();
  try {
    const validDocuments = uploadedDocuments.filter((doc: any) => doc !== null);
    const payloadItems = validDocuments.map((item: any) => 
    ({
    fileTypeId: item.fileTypeId,
    fileId: item.fileId,
    fileSize: item?.file_size,
    fileType:  item?.file_type,
    fileName: item?.file_name,
    status: 1,
    id: item?.id
  }));
    let obj={
      "registrationId":detailsData?.data?.registrationId,
      "kyc":payloadItems
    }
 
    const response = await post({
      url: getPartnerKYCPostApiUrl(detailsData?.data?.registrationId),
      obj: obj
    });
   
    if (response.result) {
      hideLoadingIndicator();
    }
    else if(response?.error?.message){
      hideLoadingIndicator();
      showToast(response?.error?.message,"error")
    }
  } catch (error) {
    hideLoadingIndicator();
    console.error('Error occurred while submitting KYC details:', error);
  }

 if( getLocalStorage('Application_EntityType') === "Business Entity"){
  showLoadingIndicator();
  try {
  const companyloadItems = companyUploadDocuments.map((item: any) => 
    ({
    fileTypeId: item.fileTypeId,
    fileId: item.fileId,
    fileSize: item?.file_size,
    fileType:  item?.file_type,
    fileName: item?.file_name,
    status: 1,
    id: item?.id
  }));
    let obj2={
      "registrationId":detailsData?.data?.registrationId,
      "kyc":companyloadItems
    }
  
      const response2 = await post({
        url: getCompanyKYCPostApiUrl(detailsData?.data?.registrationId),
        obj: obj2
      });
    if ( response2?.result) {
      hideLoadingIndicator();
    }
    else if(response2?.error?.message){
      hideLoadingIndicator();
      showToast(response2?.error?.message,"error")
    }
  } catch (error) {
    hideLoadingIndicator();
    console.error('Error occurred while submitting KYC details:', error);
  }
}
setValue(value + 1)
}

  const handleSanctionNext = () => {
    navigate('/sanction-2')
  }
  const handleUploadNext = () => {
    setValue(value + 1)
  }
  const Individualtabs = [
    {
      label: 'Registration Details',
      content: (
        <div>
          <RegistrationDetails
          setValue={setValue} 
            details={registrationDetails}
            setShowDetails={setShowDetails}
          />

        </div>
      ),
    },
    {
      label: ' Individual Details', content:
        <div>
          <IndividualDetails setValue={setValue} details={individualDetails} registrationId={detailsData?.data?.registrationId} />
        </div>
    },
    {
      label: 'Bank Details', content:
        <div>
          <BankDetails setValue={setValue} details={bankDetails} registrationId={detailsData?.data?.registrationId}/>
        </div>
    },
    {
      label: 'CIBIL', content:
        <div>
          <Cibil setValue={setValue} value={value} />
        </div>
    },
    {
      label: 'ID Verification', content:
        <div>
          <IdVerification setValue={setValue}  value={value} />
        </div>
    },
    { label: 'KYC Details', content: <div><KycDocumentes setValue={setValue} handleNext={handleNext} setUploadedDocuments={setUploadedDocuments} setCompanyUploadDocuments={setCompanyUploadDocuments}/></div> },
    { label: 'FCU/CPV', content: <div><UploadCpv setValue={setValue} cpvDetails={"sanction"} handleUploadNext={handleUploadNext}/> </div>},
    { label: 'Payout Structure', content: <div><PayoutStructure setValue={setValue} pagename="sanction1" value={value} /></div>},
    { label: 'Sanction', content: <div><Sanction isSanction2Path={false} handleSanctionNext={handleSanctionNext}/></div>}

  ];

  const Proprietortabs = [
    {
      label: 'Registration Details',
      content: (
        <div>
          <RegistrationDetails   setValue={setValue} 
            details={registrationDetails}
            setShowDetails={setShowDetails}/>
        </div>
      ),
    },
    {
      label: 'Company Details', content:
        <div>
          <CompanyDetails setValue={setValue}  details={companyDetails} registrationId={detailsData?.data?.registrationId}/>
        </div>
    },
    {
      label: ' Individual Details', content:
        <div>
          <IndividualDetails setValue={setValue} details={individualDetails} registrationId={detailsData?.data?.registrationId}/>
        </div>
    },
    {
      label: 'Bank Details', content:
        <div>
          <BankDetails setValue={setValue} details={bankDetails} registrationId={detailsData?.data?.registrationId}/>
        </div>
    },
    {
      label: 'CIBIL', content:
        <div>
          <Cibil setValue={setValue} value={value} />
        </div>
    },
    {
      label: 'ID Verification', content:
        <div>
          <IdVerification setValue={setValue}  value={value} />
        </div>
    },
    { label: 'KYC Details', content: <div><KycDocumentes setValue={setValue} handleNext={handleNext} setUploadedDocuments={setUploadedDocuments} setCompanyUploadDocuments={setCompanyUploadDocuments}/></div> },
    { label: 'FCU/CPV', content: <div><UploadCpv setValue={setValue} cpvDetails={"sanction"} handleUploadNext={handleUploadNext}/> </div>},
    { label: 'Payout Structure', content: <div><PayoutStructure setValue={setValue} pagename="sanction1" value={value}/></div>},
    { label: 'Sanction', content: <div><Sanction  isSanction2Path={false} handleSanctionNext={handleSanctionNext}/></div>}

  ];
  const Businesstabs = [
    {
      label: 'Registration Details',
      content: (
        <div>
          <RegistrationDetails    setValue={setValue} 
            details={registrationDetails}
            setShowDetails={setShowDetails}
            />
        </div>
      ),
    },
    {
      label: 'Director Details',
      content:
        <div>
          <DirectorDetails setValue={setValue} details={directorDetails} registrationId={detailsData?.data?.registrationId}/>
        </div>
    },
    {
      label: 'Company Details', content:
        <div>
          <CompanyDetails setValue={setValue} details={companyDetails} registrationId={detailsData?.data?.registrationId}/>
        </div>
    },
    {
      label: 'Bank Details', content:
        <div>
          <BankDetails setValue={setValue} details={bankDetails} registrationId={detailsData?.data?.registrationId}/>
        </div>
    },
    {
      label: 'CIBIL', content:
        <div>
          <Cibil setValue={setValue} value={value} />
        </div>
    },
    {
      label: 'ID Verification', content:
        <div>
          <IdVerification setValue={setValue}  value={value} />
        </div>
    },
    { label: 'KYC Details', content: <div><KycDocumentes setValue={setValue} handleNext={handleNext} setUploadedDocuments={setUploadedDocuments} setCompanyUploadDocuments={setCompanyUploadDocuments}/></div> },
    { label: 'FCU/CPV', content: <div><UploadCpv setValue={setValue} cpvDetails={"sanction"} handleUploadNext={handleUploadNext}/> </div>},
    { label: 'Payout Structure', content: <div><PayoutStructure setValue={setValue} pagename="sanction1" value={value}/></div>},
    { label: 'Sanction', content: <div><Sanction isSanction2Path={false} handleSanctionNext={handleSanctionNext}/></div>}

  ];
  useEffect(() => {
    const fetchValues = () => {
      const latestEntityType = getLocalStorage('Application_EntityType');
      const latestPartner = getLocalStorage('Application_PartnershipType');
      setEntityType(latestEntityType);
      setPartner(latestPartner);
    };

    fetchValues();
  }, []);
  
  return (
    <div>
      <Breadcrumb items={breadcrumbItems} />
      {visible ? 
        <>{loadingComponent()}</> :
        <>     <div css={mobileNavigation}>
          <div css={backCss} onClick={() => setShowDetails(false)}>
            <img src={icon.NavigateBack} alt="no_navigate" />
          </div>
          <div>
          Sanction - {showDetails.ApplicationNo}
          </div>
      </div>
      <div css={InitiateKycDetailsSection}>
        <TabsComponent tabs={entityType?.includes("Individual")
            ? Individualtabs
            : (entityType?.includes("Business") && partner?.includes("Proprietorship"))
              ? Proprietortabs
              : Businesstabs} value={value} setValue={setValue}/>
      </div>
    </>}
    <ToastContainer />
    </div>
  );
};

export default Sanction1Details;
