/** @jsxImportSource @emotion/react */
import {  useState } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import CustomInput from "../../components/Input/Input";
import {  regBackButton, registrationButtonContainer, regPanInput } from "../Registration/Registration.style";
import { css } from "@emotion/react";
import CustomButton from "../../components/Button/Button";
import { StringConstants } from "../../constants/Strings";
import { backCss, mobileNavigation } from "../ApplicationReview/InitiateKyc/InitiateKycDetails";
import { icon } from "../../assets/Images";

 const registrationOtpContainer = css`
display: flex;
align-items: flex-start;
flex-wrap:wrap;
justify-content:space-between;
margin-top:20px;

@media(max-width:768px){
align-items: center;
justify-content:center;
}
            `

const LoanDetails = (props: any) => {
  const { showDetails ,setShowDetails} = props;
  const {BACK_LABEL}= StringConstants;
  const [individualDetails, setIndividialDetail] = useState<any>({
    firstName: "",
    lastName: "",
    location: "",
    stage:"",
    product:"",
    dateOfDisbursement:"",
    disbursedAmt:"",
    repaymentAmt:"",
    BalanceAmt:""
    
})
  const breadcrumbItems = [
    { label: 'Loan Details', href: '/loan-application' },
    { label: showDetails.ApplicationNo }
  ]
  const handleChange = (fieldName: any, value: any) => {
    setIndividialDetail({
        ...individualDetails,
        [fieldName]: value
    });

};
  return (
    <div>
      <Breadcrumb items={breadcrumbItems} />
      <div css={mobileNavigation}>
          <div css={backCss} onClick={() => setShowDetails(false)}>
            <img src={icon.NavigateBack} alt="no_navigate" />
          </div>
          <div>
          Loan Application - {showDetails.ApplicationNo}
          </div>
      </div>
      <div css={registrationOtpContainer}>
      {[
          { label: 'First Name', name: 'firstName', type: 'text', placeholder: 'Enter name' },
          { label: 'Last Name', name: 'lastName', type: 'text', placeholder: 'Enter name' },
          { label: 'Location', name: 'location', type: 'text', placeholder: 'Enter Location' },
          { label: 'Stage', name: 'stage', type: 'text', placeholder: 'Enter Stage' },
          { label: 'Product', name: 'product', type: 'text', placeholder: 'Enter Product' },
          { label: 'Date Of Disbursement', name: 'dateOfDisbursement', type: 'date', placeholder: 'dd/mm/yyyy' },
          { label: 'Disbursed Amount', name: 'disbursedAmt', type: 'text', placeholder: 'Enter amount' },
          { label: 'Repayment Amount', name: 'repaymentAmt', type: 'text', placeholder: 'Enter amount' },
          { label: 'Balance Amount', name: 'BalanceAmt', type: 'text', placeholder: 'Enter amount' },
        ].map((field) => (
          <CustomInput
            type={field.type}
            css={regPanInput}
            value={individualDetails[field.name]}
            onChange={(e) => handleChange(field.name, e.target.value)}
            placeholder={field.placeholder}
            inputLabel={field.label}
          />
        ))}
      </div>
      <div css={registrationButtonContainer}>
                    <CustomButton
                        label={BACK_LABEL}
                        onClick={() => setShowDetails(false)}
                        variant="outlined"
                        css={regBackButton}
                    />
                    
                </div>
    </div>
  );
};

export default LoanDetails;
