import CheckIcon from "./CheckIcon.png";
import accordian_remove from "./delete_icon.png";
import GreenCheckIcon from "./GreenCheckIcon.png";
import ErrorIcon from "./Error.png";
import SuccessGiff from "./success.gif";
import validation_Error from "./Info.png";
import dashboard_home from "./overview_inactive.png";
import verify_kyc from "./verify_active.png";
import ashv_logo from "./ashv_logo.png";
import overview_active from "./overview_active.png";
import veirfy_inactive from "./verify_inactive.png";
import search_icon from "./search.png";
import notification_icon from "./notification.png";
import profile_icon from "./profile.png";
import settings from "./settings.png";
import loginbg from "./loginbg.png";
import navigationSeparatorRed from "./navigationSeparatorRed.png";
import pin from "./pin.png";
import comments from "./comments.png";
import saction_active from "./sanction_active.png";
import saction_inactive from "./santion_inactive.png";
import esign_active from "./esign_active.png";
import esign_inactive from "./esign_inactive.png";
import sidebararrow from "./sidedropdown_arrow.png";
import inactiveSidebarArrow from "./inactive_dropdown_arrow.png";
import employeeInactive from "./employee_inactive.png";
import employeeActive from "./employee_active.png";
import ExportIcon from "./export_icon.png";
import ArrowUp from "./arrow-up.png";
import LoanActive from "./loan_active.png";
import LoanInactive from "./loan_inactive.png";
import PayoutActive from "./payout_active.png";
import PayoutInactive from "./payout_inactive.png";
import HamburgerIcon from "./Content.png";
import Account from "./account.png";
import FilterIcon from "./filter.png";
import NavigateBack from "./navigate_back.png";
import logout_icon from "./logout-icon.svg";

export const icon: any = {
  CheckIcon: CheckIcon,
  AccordianRemove:accordian_remove,
  GreenCheckIcon:GreenCheckIcon,
  ErrorIcon:ErrorIcon,
  SuccessGiff:SuccessGiff,
  validation_Error:validation_Error,
  loginbg:loginbg,
  navigationSeparatorRed:navigationSeparatorRed,
  overviewInactive:dashboard_home,
  verifyActive:verify_kyc,
  ashvLogo:ashv_logo,
  overviewActive:overview_active,
  veirfyInactive:veirfy_inactive,
  searchIcon:search_icon,
  notificationIcon:notification_icon,
  profileIcon:profile_icon,
  settingsIcon:settings,
  pin:pin,
  comments:comments,
  sactionActive:saction_active,
  sactionInactive:saction_inactive,
  esignActive:esign_active,
  esignInactive:esign_inactive,
  sidebararrow:sidebararrow,
  inactiveSidebarArrow:inactiveSidebarArrow,
  employeeInactive:employeeInactive,
  employeeActive:employeeActive,
  exportIcon:ExportIcon,
  ArrowUp:ArrowUp,
  LoanActive:LoanActive,
  LoanInactive:LoanInactive,
  PayoutActive:PayoutActive,
  PayoutInactive:PayoutInactive,
  HamburgerIcon:HamburgerIcon,
  Account:Account,
  FilterIcon:FilterIcon,
  NavigateBack:NavigateBack,
  logout_icon:logout_icon
}
