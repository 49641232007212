import React, { ChangeEvent, useState } from "react";
import CustomInput from "../../components/Input/Input";
import CustomButton from "../../components/Button/Button";
import './Example.scss';
import Layout from "../../components/Layout/Layout";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import TabsComponent from "../../components/TabsComponent/TabsComponent";
import packageInfo from '../../../package.json'

// Validation functions
const validateEmail = (value: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(value) ? null : 'Invalid email address';
};

const validatePassword = (value: string) => {
    return value.length >= 6 ? null : 'Password must be at least 6 characters long';
};

const Example = (props: any) => {
    const [textValue, setTextValue] = useState('');
    const [passwordValue, setPasswordValue] = useState('');
    const [emailValue, setEmailValue] = useState('');
    const [numberValue, setNumberValue] = useState('');
    const [value, setValue] = useState(0);

    const handleInputChange = (setter: React.Dispatch<React.SetStateAction<string>>) => (event: ChangeEvent<HTMLInputElement>) => {
        setter(event.target.value);
    };
    const breadcrumbItems = [
        { label: 'Verify KYC Docs', href: '#' },
        { label: '12345' }
      ];
     
      const tabs = [
        {
          label: 'Registration Details',
          content: (
           <div>Registration content</div>
          ),
        },
        { label: 'Director Details', content: <div>Director Details Content</div> },
        { label: 'Company Details / Individual Details', content: <div>Company Details / Individual Details Content</div> },
        { label: 'Bank Details', content: <div>Bank Details Content</div> },
        { label: 'KYC Details', content: <div>KYC Details Content</div> },
        { label: 'Status', content: <div>Status Content</div> },
      ];
    return (
        <Layout>
        <div className="example-container">
        <Breadcrumb items={breadcrumbItems} />
        <TabsComponent tabs={tabs} value={value} setValue={setValue}/>

            Example Page {packageInfo.version}
            {/* <CustomStepper steps={utilsConstants.STEPPER} stepCount={0}/> */}
            <CustomButton
                label="Default Button"
                onClick={() => alert('Button Clicked!')}
            />
            <CustomButton
                label="Outlined Button"
                onClick={() => alert('Button Clicked!')}
                variant="outlined"
                customStyles={{ backgroundColor: '#ffffff', color: '#1976d2', borderColor: '#1976d2' }}
            />
            <CustomButton
                label="Custom Styled Button"
                onClick={() => alert('Button Clicked!')}
                customStyles={{ backgroundColor: '#ff5722', color: '#ffffff', borderRadius: '16px' }}
            />
            <CustomInput
                label="Text"
                value={textValue}
                onChange={handleInputChange(setTextValue)}
                type="text"
                customStyles={{ marginBottom: '1rem', backgroundColor: '#e0f7fa' }}
                required
                validate={(value) => value ? null : 'Text is required'}
            />
            <CustomInput
                label="Password"
                value={passwordValue}
                onChange={handleInputChange(setPasswordValue)}
                type="password"
                customStyles={{ marginBottom: '1rem', backgroundColor: '#fce4ec' }}
                required
                validate={validatePassword}
            />
            <CustomInput
                label="Email"
                value={emailValue}
                onChange={handleInputChange(setEmailValue)}
                type="email"
                customStyles={{ marginBottom: '1rem', backgroundColor: '#e8f5e9' }}
                required
                validate={validateEmail}
            />
            <CustomInput
                label="Number"
                value={numberValue}
                onChange={handleInputChange(setNumberValue)}
                type="number"
                customStyles={{ marginBottom: '1rem', backgroundColor: '#fff3e0' }}
            />
            <div>
                <p>Text: {textValue}</p>
                <p>Password: {passwordValue}</p>
                <p>Email: {emailValue}</p>
                <p>Number: {numberValue}</p>
            </div>
        </div>
        </Layout>
    );
};

export default Example;
