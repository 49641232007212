/** @jsxImportSource @emotion/react */
import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { css } from '@emotion/react';
import { colors, typography } from '../../scss/colors';
import { icon } from '../../assets/Images';
import { Checkbox, ListItemText } from '@mui/material';
import { getLocalStorage } from '../../utils/browserHandlers/browserStorageHandler';
import CustomizedTooltips from '../ToolTip/ToolTip';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,

    },
  },
};

const StyledSelect = styled(Select)(({ theme,disabled }) => ({
  marginBottom: theme.spacing(2),
 backgroundColor:disabled?'#f5f5f5' :'#FFFFFF',
  height: '40px',
  textAlign: 'left',
  '& .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B8B8B8',
  },
  '&:hover .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B8B8B8',
  },
  '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
    borderColor: '#B8B8B8',
  },
}));

interface CustomSelectProps {
  options: any;
  placeholder?: string;
  value?: any;
  onChange?: (event: SelectChangeEvent<string>) => void;
  inputLabel?: string;
  mandatory?: boolean;
  className?: any;
  checkBox?:boolean;
  disabled?:boolean
}

const inputLabelCss = css`
  text-align: left;
  margin-bottom: 5px;
  color: ${colors.charcoal};
  ${typography('bold', '14px')};
`;

const labelMandatory = css`
  color: ${colors.crimson};
`;
const imgCss = css`
padding-left: 4px;
margin-bottom: -1px;
`
function getStyles(name: string, selectedValue: any, theme: Theme) {
  return {
    fontWeight:
      selectedValue === name
        ? theme.typography.fontWeightMedium
        : theme.typography.fontWeightRegular,
  };
}

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  placeholder = 'Please Select',
  value,
  onChange,
  inputLabel,
  mandatory = false,
  className,
  checkBox = false,
  disabled = false
}) => {
  const theme = useTheme();
  const [selectedValue, setSelectedValue] = React.useState<string>(value || '');
  const [selectedValues, setSelectedValues] = React.useState<string[]>(value || []);

  const handleChange = (event: any) => {
    if(checkBox){
      const { value: newValues } = event.target as { value: string[] };
      setSelectedValues(newValues);
      if (onChange) {
        onChange(event as SelectChangeEvent<string>);
      }
    }else{
    setSelectedValue(event.target.value as string);
    if (onChange) {
      onChange(event);
    }
  }
  };
  const disabledSelect =  getLocalStorage("final") || disabled;
  return (
    <div>
      {inputLabel && (
        <div css={inputLabelCss}>
          {inputLabel}
          {mandatory && <span css={labelMandatory}>*</span>}
          <CustomizedTooltips title={placeholder ?? "Please select"} placement="top-end">
          <img src={icon.validation_Error} alt="validation_error" css={imgCss} />
          </CustomizedTooltips>
        </div>
      )}
      {checkBox ? 
        <StyledSelect
        displayEmpty
        value={selectedValues}
        onChange={handleChange}
        variant="outlined"
        className={className}
        MenuProps={MenuProps}
        data-testid="name-select"
        multiple={checkBox}
        disabled={disabledSelect}
        renderValue={(selected:any) =>
          (Array.isArray(selected) ? selected.join(', ') : selected) || placeholder
        }
      >
        {/* <MenuItem value="" disabled>
          {placeholder}
        </MenuItem> */}
        
        {options.map((option: any) => (
          <MenuItem key={option.value} value={option.value} style={getStyles(option.value, selectedValues, theme)}>
          <Checkbox checked={selectedValues.includes(option.value)}  sx={{
          color: colors.crimson,
          '&.Mui-checked': {
            color: colors.crimson,
          }}} />
          <ListItemText primary={option.label} />
          </MenuItem>
        ))}

      </StyledSelect>
:
      <StyledSelect
        displayEmpty
        value={value ?? ''}
        onChange={handleChange}
        variant="outlined"
        className={className}
        MenuProps={MenuProps}
        data-testid="name-select"
        disabled={disabledSelect}
      >
        <MenuItem value="" disabled>
          {placeholder}
        </MenuItem>
        
        {options.map((option: any) => (
          <MenuItem key={option.value} value={option.value} style={getStyles(option.value, selectedValue, theme)}>
            {option.label}
          </MenuItem>
        ))}

      </StyledSelect>
}
    </div>
  );
};

export default CustomSelect;
