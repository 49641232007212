/** @jsxImportSource @emotion/react */

import React, { FC, ChangeEvent, useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { styled } from '@mui/material/styles';
import { colors, typography, typographyInter } from '../../scss/colors'; 
import { css } from '@emotion/react';
import { icon } from '../../assets/Images';
import InputAdornment from '@mui/material/InputAdornment';
import { getLocalStorage } from '../../utils/browserHandlers/browserStorageHandler';
import CustomizedTooltips from '../ToolTip/ToolTip';

interface CustomInputProps {
  label?: string;
  value?: string;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  type?: string;
  fullWidth?: boolean;
  customStyles?: React.CSSProperties;
  required?: boolean;
  validate?: (value: string) => string | null;
  className?: string; 
  placeholder?: string;
  name?:string;
  mandatory?: boolean;
  inputLabel? :string;
  disabled?:boolean;
  min?:string;
  max?:any;
  maskValue?:boolean;
  inputProps?: React.InputHTMLAttributes<HTMLInputElement>;
  info?:boolean;
}

const CustomTextField = styled(TextField)(({ theme ,disabled}) => ({
  marginBottom: theme.spacing(3),
  '& .MuiInputBase-root': {
    backgroundColor:disabled?'#f5f5f5' :'#FFFFFF',
    height: '40px',
  },
  '& .MuiOutlinedInput-input': {
    padding: '10px 14px',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#B8B8B8',
    },
    '&:hover fieldset': {
      borderColor: '#B8B8B8',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#B8B8B8',
    },
   
  },
  '& .MuiFormHelperText-root':{
    marginLeft:'0px',
  }
}));


const inputLabelCss = css`
text-align: left;
margin-bottom: 5px;
color: ${colors.charcoal};
 ${typographyInter('bold', '14px')};`

const labelMandatory = css`
color: ${colors.crimson};`

const imgCss = css`
padding-left: 4px;
margin-bottom: -1px;
`

const mobilePlaceholder = css`
color: ${colors.charcoal} !important;
 ${typography('medium', '16px')};
 '&.MuiTypography-root': {
  color: ${colors.charcoal} !important;
    },
 `


const CustomInput: FC<CustomInputProps> = ({ label,name, value = '', onChange, type = 'text', fullWidth = true, customStyles, required = false, validate,className, placeholder, mandatory = false  ,inputLabel,disabled=false,min ,max,inputProps,info=false}) => {
  const [error, setError] = useState<string | null>(null);
  const [touched, setTouched] = useState(false);

  useEffect(() => {
    if (validate && touched) {
      const validationError = validate(value);
      setError(validationError);
    }
  }, [value, validate, touched]);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    if (onChange) {
      onChange(event);
    }
    setTouched(true);
  };
  const textFieldProps :any= {
    label,
    value:value,
    onChange: handleChange,
    type,
    variant: 'outlined',
    fullWidth,
    style: customStyles,
    error: !!error,
    helperText: touched ? error : '',
    required,
    onBlur: () => setTouched(true),
    className,
    placeholder,
    name,
    disabled :  getLocalStorage("final") || disabled,
    inputProps,
    InputProps: { ...inputProps }
  };

  if (min && (type === 'number' || type === 'date' || type === 'datetime-local' || type === 'month' || type === 'time' || type === 'week')) {
    textFieldProps['inputProps'] = { ...inputProps,min };
  }
  if (type === 'text' && max) {
    textFieldProps['inputProps'] = { ...inputProps, maxLength: max };
  } else if (['date', 'number', 'datetime-local', 'month', 'time', 'week'].includes(type) && max) {
    textFieldProps['inputProps'] = { ...inputProps, max };
  }
  
  if (inputLabel === 'Mobile No.') {
    textFieldProps.InputProps.startAdornment = (
      <InputAdornment position="start" >
        <div css={mobilePlaceholder}>+91-</div>
      </InputAdornment>
    );
  }

  return (
    <div>
    {inputLabel && (
      <div css={inputLabelCss}>
        {inputLabel}
        {mandatory && <span css={labelMandatory}>*</span>}
        
       {!info &&
          <CustomizedTooltips title={placeholder ?? "Enter Details" } placement="top-end">
        <img src={icon.validation_Error} alt="validation_error" css={imgCss}/>
        </CustomizedTooltips>
        }
      </div>
    )}
      <CustomTextField {...textFieldProps} />
    </div>
  );
};

export default CustomInput;
