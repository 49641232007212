/** @jsxImportSource @emotion/react */
import { useState, useEffect } from "react";
import DashboardLoyout from "../../../components/DashbaordLayout/DashbaordLayout";
import { StringConstants } from "../../../constants/Strings";
import { VeirfyMainHeadding } from "../../ApplicationReview/InitiateKyc/InitiateKyc.style";
import CustomTable from "../../../components/Table/Table";
import SanctionDetails from "./SanctionDetails";
import { get, post } from "../../../apiManger/apiMethods";
import { getApplicationDetailsGETApiUrl, getApplicationListPOSTApiUrl } from "../../../apiManger/apiConstants";
import { setLocalStorage } from "../../../utils/browserHandlers/browserStorageHandler";
import { formatDate } from "../../ApplicationReview/InitiateKyc/InitiateKyc";
import Loder from "../../../components/Loader/Loder";
const columns = [
  { id: 'ApplicationNo', label: 'Application No', minWidth: 100 },
  { id: 'PartnerType', label: 'Partner Type', minWidth: 100 },
  { id: 'EntityType', label: 'Entity Type', minWidth: 100 },
  { id: 'PartnershipType', label: 'Partnership Type', minWidth: 100 },
  { id: 'Name', label: 'Name', minWidth: 100 },
  { id: 'MobileNo', label: 'Mobile No', minWidth: 100 },
  { id: 'Details', label: 'Details', minWidth: 100 },
];

const data = [
  { ApplicationNo: '12345', PartnerType: "DSA / E-DSA", EntityType: 'Individual', PartnershipType: 'Sole Proprietorship', Name: 'Terry Ekstrom Bothman', MobileNo: '9876543210' },
  { ApplicationNo: '12346', PartnerType: "OEM", EntityType: 'Individual', PartnershipType: 'LLP', Name: 'Terry Ekstrom Bothman', MobileNo: '9876543210' },
  { ApplicationNo: '12347', PartnerType: "DSA / E-DSA", EntityType: 'Pvt ltd', PartnershipType: 'Partnership', Name: 'Terry Ekstrom Bothman', MobileNo: '9876543210' },
  { ApplicationNo: '12348', PartnerType: "EPC", EntityType: 'Individual', PartnershipType: 'Sole Proprietorship', Name: 'Terry Ekstrom Bothman', MobileNo: '9876543210' },
  { ApplicationNo: '12349', PartnerType: "DSA / E-DSA", EntityType: 'Individual', PartnershipType: 'private Ltd', Name: 'Terry Ekstrom Bothman', MobileNo: '9876543210' },
  { ApplicationNo: '12350', PartnerType: "DSA / E-DSA", EntityType: 'Individual', PartnershipType: 'Sole Proprietorship', Name: 'Terry Ekstrom Bothman', MobileNo: '9876543210' },
];


const Sanction2 = () => {
  const { SANCTION } = StringConstants
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [filterByStatus, setFilterByStatus] = useState("Pending");
  const [showDetails, setShowDetails] = useState(false);
  const [filters, setFilters] = useState(true);
  const [search, setSearch] = useState("");
  const [data, setData] = useState<any>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [detailsData, setDetailsData] = useState();
  const {
    showLoadingIndicator,
    hideLoadingIndicator,
    loadingComponent,
    visible,
  } = Loder();
  const [filterByReg, setFilterByReg] = useState({
    startDate: "",
    endDate: "",
    partnerType: [],
    entityType: [],
    partnerShipType: []
  });

  useEffect(() => {
    getAPICall();
  }, [page, rowsPerPage, filterByStatus, search, filterByReg]);

  const getAPICall = async () => {
    showLoadingIndicator()
    const reqObj = {
      startDate: filterByReg.startDate ? formatDate(filterByReg.startDate) : "",
      endDate: filterByReg.endDate ? formatDate(filterByReg.endDate) : "",
      pageNo: page,
      pageSize: rowsPerPage,
      search: search,
      partnerTypeValue: filterByReg.partnerType,
      entityTypeValue: filterByReg.entityType,
      partnershipTypeValue: filterByReg.partnerShipType,
      status:filterByStatus,
      stage:'sanction_two'
    };
    const response = await post({
      url: getApplicationListPOSTApiUrl(),
      obj: reqObj
    });

    if (response?.result && response?.result?.data?.length) {
      hideLoadingIndicator()
      const array = response.result.data.map((item: any) => ({
       ApplicationNo: item.applicationNumber|| '',
        PartnerType: item.partnerSelection,
        EntityType: item.entityType,
        PartnershipType: item.partnershipType,
        Name: item.name || '',
        MobileNo: item.mobile,
        registrationId: item.id
      }));

      setData(array);
      setTotalRecords(response?.result?.totalRecords);
    } else {
      hideLoadingIndicator();
      setData([]);
      setTotalRecords(0);
    }
  };
  const handleviewDetails = (rowData: any) => {
    fetchDetails(rowData)

  }
  const fetchDetails = async (rowData: any) => {
    showLoadingIndicator();
    const response = await get({
      url: getApplicationDetailsGETApiUrl(rowData.registrationId),

    });
    if (response?.result) {
      hideLoadingIndicator();
      setLocalStorage('Application_registrationId', rowData.registrationId)

      setDetailsData(response.result)
      setShowDetails(rowData);
    }
    else{
      hideLoadingIndicator();
    }
  }
  const isMobile = window.innerWidth < 768;
  return (
    <div>

      {!showDetails ?
        <DashboardLoyout>
          <div css={VeirfyMainHeadding}>{SANCTION} 2</div>
          <CustomTable
            columns={columns}
            data={data}
            handleviewDetails={handleviewDetails}
            page={page}
            setPage={setPage}
            rowsPerPage={rowsPerPage}
            setFilterByStatus={setFilterByStatus}
            filterByStatus={filterByStatus}
            filters={filters}
            filterByReg={filterByReg}
            setFilterByReg={setFilterByReg}
            setSearch={setSearch}
            search={search}
            setRowsPerPage={setRowsPerPage}
            totalRecords={totalRecords}
            visible={visible}
            loadingComponent={loadingComponent}
          />
        </DashboardLoyout>
        : (
          <>
            {!isMobile && (
              <DashboardLoyout>
                          <div css={VeirfyMainHeadding}>{SANCTION} 2</div>
                <SanctionDetails showDetails={showDetails} detailsData={detailsData} setShowDetails={setShowDetails}/>
              </DashboardLoyout>
            )}
            {isMobile && (
              <SanctionDetails showDetails={showDetails} detailsData={detailsData} setShowDetails={setShowDetails} />
            )}
          </>
        )

      }
    </div>
  );
};
export default Sanction2;
