/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { colors, typography } from "../../scss/colors";
import { icon } from "../../assets/Images";
import SideMenu from "../Sidebar/SideMenu";
import { SideBarData, UserSideBarData } from "../Sidebar/SideBarData";
import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getLocalStorage } from "../../utils/browserHandlers/browserStorageHandler";
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import CustomButton from "../Button/Button";

const MobileSideBar = (props: any) => {
  const { onClick, showSideBar } = props;
  const role = getLocalStorage("role");
  const [showModal, setShowModal] = useState(false);
  const sidebarMenu = role?.includes("user") ? UserSideBarData : SideBarData;
  const ref = useRef<any>();
  const navigate = useNavigate();

  const SidebarWrapper = css`
    display: none;
    @media (max-width: 768px) {
      background-color: ${colors.lightBlue};
      width: 208px;
      overflow-y: auto;
      position: fixed;
      top: 0px;
      transition: all 0.5s ease-in-out;
      z-index: 10;
      height: 100vh;
      display: flex;
      flex-direction: column;
    }
  `;

  const SidebarLogoWrapper = css`
    text-align: left;
    padding: 16px 0px 30px 15px;
  `;

  const SidebarSection = css`
    padding: 16px;
    flex: 1 0 auto;
    overflow-y: auto;
    @media (max-width: 768px) {
      padding: 0px;
    }
  `;

  const AccountSection = css`
    display: none;
    @media (max-width: 768px) {
      padding: 10px 25px;
      margin-bottom: 10px;
      display: flex;
      gap: 15px;
      cursor: pointer;
      flex-shrink: 0;
    }
  `;

  const LogoutSection = css`
    display: none;
    @media (max-width: 768px) {
      padding: 10px 25px;
      margin-bottom: 50px;
      display: flex;
      gap: 15px;
      cursor: pointer;
      flex-shrink: 0;
    }
  `;

  const modalMain = css`
    padding: 0px !important;
    .logout-model {
      padding: 0px 0px 40px 0px;
      text-align: center;
      .logout-model-header {
        font-weight: normal;
        font-size: 20px;
        line-height: 23px;
        color: ${colors.crimson};
        margin-bottom: 45px;
      }
    }
  `;

  const modalBoxStyle = css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 400px;
    background-color: ${colors.white};
    box-shadow: 24px;
    border-radius: 5px;
    padding: 30px;
    max-height: 90vh;
    overflow-y: auto;
    text-align: center;
  `;

  const regBackButton = css`
    background-color: ${colors.white};
    color: ${colors.buttonOrange};
    border-color: ${colors.buttonOrange};
    width: 100px;
    height: 40px;
    border-radius: 4px;
    ${typography("700", "16px")};
    &:hover {
      background-color: ${colors.white};
      color: ${colors.buttonOrange};
      border-color: ${colors.buttonOrange};
    }
  `;

  const regNextButton = css`
    background-color: ${colors.buttonOrange};
    color: ${colors.white};
    width: 100px;
    height: 40px;
    border-radius: 4px;
    ${typography("700", "16px")};
    padding: 0px;
    &:hover {
      background-color: ${colors.buttonOrange}; 
      color: ${colors.white};
    }
  `;

  const buttonSection = css`
    display: flex;
    justify-content: center;
    gap: 20px;
  `;

  function handleClickOutside(event: any) {
    if (ref.current && !ref.current.contains(event.target) && !event.target.closest('.MuiModal-root')) {
      onClick();
    }
  }

  useEffect(() => {
    if (!showModal) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, showModal]); 

  const logoutApi = () => {
    localStorage.clear();
    navigate('/');
  };

  const handleLogout = () => {
    setShowModal(true);
  };

  const handleModalClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div css={SidebarWrapper} ref={ref}>
        <div css={SidebarLogoWrapper}>
          <img src={icon.ashvLogo} alt="no_side_icon" />
        </div>
        <div css={SidebarSection}>
          {sidebarMenu.map((item: any, index: any) => (
            <div key={index}>
              <SideMenu item={item} index={index} />
            </div>
          ))}
        </div>
        <div css={AccountSection} onClick={() => navigate('/account')}>
          <img src={icon.Account} alt="no_account" />
          <div>Account</div>
        </div>
        <div css={LogoutSection} onClick={handleLogout}>
          <img src={icon.logout_icon} alt="no_account" />
          <div>Logout</div>
        </div>
      </div>
      <Modal open={showModal} onClose={handleModalClose}>
        <Box css={modalBoxStyle}>
          <div css={modalMain}>
            <p className="logout-model-header">
              Are you sure you want to logout?
            </p>
            <div css={buttonSection}>
              <CustomButton
                label="Cancel"
                onClick={handleModalClose}
                variant="outlined"
                css={regBackButton}
              />
              <CustomButton
                label="Logout"
                onClick={logoutApi}
                css={regNextButton}
              />
            </div>
          </div>
        </Box>
      </Modal>
    </>
  );
}

export default MobileSideBar;
