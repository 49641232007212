import React, { useState, useCallback } from 'react';
import './Loder.scss';
import { icon } from '../../assets/Images';

const Loder = () => {
  const [visible, setVisible] = useState(false);

  const showLoadingIndicator = useCallback(() => {
    setVisible(true);
  }, []);

  const hideLoadingIndicator = useCallback(() => {
    setVisible(false);
  }, []);

  const toggleLoadingIndicator = useCallback(() => {
    setVisible(prev => !prev);
  }, []);

  const loadingComponent = () => {
    return (
      visible && (
        <div className='loader-container'>
        <div className="login-loader-container">
          <div className="circle-loader">
            <div className="circle"></div>
            <div className="circle"></div>
            <div className="circle"></div>
          </div>
          {/* <img src={icon.Loader}/> */}
        </div>
        </div>
      )
    );
  };

  return {
    showLoadingIndicator,
    hideLoadingIndicator,
    toggleLoadingIndicator,
    loadingComponent,
    visible
  };
};

export default Loder;
