import React, { useEffect, useRef } from 'react';
import ApexCharts from 'apexcharts';


const CustomBarChart = ({ data = [], colors = [] , currency = false  }:any) => {
  const chartRef = useRef(null);

  useEffect(() => {
    const defaultColors = ['#3359DC'];
    const chartColors = colors.length > 0 ? colors : defaultColors;
   
    const options = {
      series: [{
        name: 'sales',
        data: data
      }],
      chart: {
        type: 'bar',
        height: 380,
        toolbar: {
          show: false
        }
      },
      plotOptions: {
        bar: {
          columnWidth: '96px',
          borderRadius: 4,
          borderRadiusApplication: 'end',
          borderRadiusWhenStacked: 'all',
          distributed: true,
           barGap: '10%'
        }
      },
      yaxis: {
        labels: {
          formatter: function (value:any) {
            return currency && value !== 0 ? `₹${value}` : value;
          },
          style: {
            colors: '#84919A',
            fontSize: '11px',
            fontFamily:'Inter',
            fontWeight:'500'
          }
        }
      },
      xaxis: {
        labels: {
          style: {
            colors: '#84919A',
            fontSize: '11px',
            fontFamily:'Inter',
            fontWeight:'500'
          }
        }
      },
      colors: chartColors,
      grid: {
        show: false
      },
      dataLabels: {
        enabled: false 
      } ,
      legend: {
        show: false
      },
      responsive: [{
        breakpoint: 768, 
        options: {
          plotOptions: {
            bar: {
              barGap: '30%' ,
            }
          }
        }
      }]
    };

    const chart = new ApexCharts(chartRef.current, options);
    chart.render();

    return () => {
      chart.destroy();
    };
  }, [data]);

  return <div id="chart" ref={chartRef} style={{ width: '100%' }}></div>;
};

export default CustomBarChart;
